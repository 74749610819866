import React from 'react'
import {
  Typography,
  Box,
  Stack,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  CircularProgress,
} from '@mui/material'
import { useDropzone } from 'react-dropzone'
import { FilePresent } from '@mui/icons-material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment/moment'
import SelectHubUser from '../SelectHubUser'

const FileUploadStep = ({
  eventStartDate,
  setEventStartDate,
  eventEndDate,
  setEventEndDate,
  file,
  setFile,
  hasGpxFile,
  handleGpxOptionChange,
  loading,
  uploadError,
  setUploadError,
  hubUsers,
  assignedTo,
  handleAssignedToChange,
}) => {
  // Configuración de dropzone para subir archivos GPX
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'application/octet-stream': ['.gpx'],
      'application/gpx': ['.gpx'],
      'application/gpx+xml': ['.gpx'],
    },
    onDrop: async (acceptedFiles, fileRejections) => {
      try {
        if (acceptedFiles.length) {
          setUploadError(null)
          setFile(acceptedFiles[0])
        }
        if (fileRejections.length) {
          setUploadError(new Error('Tipo de archivo no permitido. Usar extensión GPX'))
        }
      } catch (error) {
        setUploadError(error)
      }
    },
  })

  if (!hubUsers || hubUsers.length === 0) {
    return <Typography>Cargando usuarios...</Typography>
  }

  return (
    <Grid container alignContent="flex-start" height="100%" spacing={2}>
      <Grid item xs={12}>
        <Stack alignItems="center" mt={4} width="100%">
          <Typography variant="h6">Responsable del evento</Typography>
          <Typography variant="caption">
            La persona responsable deberá completar el evento en la aplicación móvil (MDC)
          </Typography>

          <SelectHubUser
            required
            disabled={false}
            field={{ value: assignedTo }}
            handleChange={handleAssignedToChange}
            hubUsers={hubUsers.map(hubUser => hubUser.email)}
            size="medium"
            style={{ width: '300px', pb: 2, marginTop: '20px' }}
          />

          <Typography variant="h6">Fechas del evento</Typography>

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              disableFuture
              defaultValue={eventStartDate ? moment(eventStartDate) : null}
              format="DD-MM-YYYY"
              label="Fecha de inicio"
              sx={{ marginTop: '20px', width: '300px' }}
              onChange={value => {
                setEventStartDate(value.format('YYYY-MM-DD HH:mm:ssZ'))
              }}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              disableFuture
              defaultValue={eventEndDate ? moment(eventEndDate) : null}
              format="DD-MM-YYYY"
              label="Fecha de finalización"
              sx={{ marginTop: '20px', width: '300px' }}
              onChange={value => {
                setEventEndDate(value.format('YYYY-MM-DD HH:mm:ssZ'))
              }}
            />
          </LocalizationProvider>

          {/* Opciones de archivo GPX */}
          <Typography sx={{ mt: 4 }} variant="h6">
            Archivo GPX
          </Typography>
          <FormControl sx={{ mt: 2 }}>
            <RadioGroup row value={hasGpxFile} onChange={handleGpxOptionChange}>
              <FormControlLabel
                value
                control={<Radio checked={hasGpxFile} />}
                label="Tengo archivo GPX"
              />
              <FormControlLabel
                control={<Radio checked={!hasGpxFile} />}
                label="No tengo archivo GPX"
                value={false}
              />
            </RadioGroup>
          </FormControl>
        </Stack>
      </Grid>

      <Grid item xs={12}>
        {/* Área de subida de archivo GPX */}
        {eventStartDate && eventEndDate && hasGpxFile === true && (
          <Stack alignItems="center" mt={2} width="100%">
            <Box
              {...getRootProps()}
              sx={{
                border: '2px dashed #aaa',
                borderRadius: '10px',
                padding: '20px',
                textAlign: 'center',
                cursor: 'pointer',
                backgroundColor: isDragActive ? '#f0f0f0' : 'transparent',
                mt: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '200px',
                width: '300px',
                position: 'relative',
                textWrap: 'wrap',
                whiteSpace: 'word-wrap',
              }}
            >
              <input {...getInputProps()} />
              {loading ? (
                <Stack alignItems="center" spacing={2}>
                  <CircularProgress />
                  <Typography color="text.secondary">Validando archivo...</Typography>
                </Stack>
              ) : file && !uploadError ? (
                <Box>
                  <Stack alignItems="center">
                    <FilePresent sx={{ fontSize: 36 }} />
                    <strong>{file.name}</strong>
                  </Stack>
                </Box>
              ) : uploadError ? (
                <Typography color="error">
                  <strong>{uploadError.message}</strong>
                </Typography>
              ) : isDragActive ? (
                <Typography>Arrastrar el archivo aquí...</Typography>
              ) : (
                <Typography>Arrastrar el archivo aquí o hacer click y seleccionar uno</Typography>
              )}
            </Box>
            {file && !loading && (
              <Typography mt={2}>
                Archivo: <strong>{file.name}</strong>
              </Typography>
            )}
          </Stack>
        )}

        {/* Mensaje cuando no se usa archivo GPX */}
        {!hasGpxFile && eventStartDate && eventEndDate && (
          <Stack alignItems="center" mt={4}>
            <Typography color="text.secondary">
              Podrás continuar sin archivo GPX. Los sitios de monitoreo se cargarán desde la base de
              datos y deberás asignar las coordenadas y actividades manualmente.
            </Typography>
          </Stack>
        )}
      </Grid>
    </Grid>
  )
}

export default FileUploadStep
