/* eslint-disable react/no-array-index-key */
import DeleteIcon from '@mui/icons-material/Delete'
import DownloadIcon from '@mui/icons-material/Download'
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle'
import { Skeleton } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useUserRole } from '../../hooks/useUserRole'
import ViewEventModal from './ViewEventModal'

const EventsList = ({ farm, events, handleDeleteEvent, monitoringWorkflows }) => {
  const [viewEvent, setViewEvent] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const { isAdmin } = useUserRole()

  return (
    <Box sx={{ padding: '20px' }}>
      {farm && (
        <ViewEventModal
          event={viewEvent}
          farm={farm}
          open={openModal}
          setOpenModal={setOpenModal}
        />
      )}
      <Box sx={{ width: '100%' }}>
        <Stack spacing={2}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table" sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <Typography variant="p">
                      <b>Fecha</b>
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="p">
                      <b>Hora</b>
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="p">
                      <b>Técnico asignado</b>
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="p">
                      <b>Tipo de monitoreo</b>
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="p">
                      <b>Estratos</b>
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="p">
                      <b>Sitios de monitoreo</b>
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="p">
                      <b>Estado</b>
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="p">
                      <b>Actividades completas</b>
                    </Typography>
                  </TableCell>
                  <TableCell align="center" />
                </TableRow>
              </TableHead>
              <TableBody>
                {events ? (
                  events.map(event => (
                    <TableRow
                      key={event.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="center">{dayjs(event.date).format('DD/MM/YYYY')}</TableCell>
                      <TableCell align="center">{dayjs(event.date).format('HH:mm')}</TableCell>
                      <TableCell align="center">{event.assignedTo}</TableCell>
                      <TableCell align="center">
                        <Typography variant="body2">
                          {event.monitoringWorkflowIds.map((id, index) => {
                            const workflow = monitoringWorkflows.find(w => w.id === id)
                            if (index === event.monitoringWorkflowIds.length - 1) {
                              return `${workflow?.name} (${workflow?.description})`
                            }
                            return `${workflow?.name} (${workflow?.description}) -`
                          })}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2">
                          {event.samplingAreas?.map(s => s.name).join(', ')}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2">
                          {event.monitoringSites?.map(s => s.name).join(', ')}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {event.date < new Date().toISOString().split('T')[0] ? (
                          event.completedActivities / event.totalActivities === 1 ? (
                            <Typography color="success" variant="body2">
                              Completado
                            </Typography>
                          ) : (
                            <Typography color="error" variant="body2">
                              Incompleto
                            </Typography>
                          )
                        ) : (
                          <Typography color="warning" variant="body2">
                            Pendiente
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2">
                          {event.completedActivities}/{event.totalActivities}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip arrow title="Ver información">
                          <Button
                            sx={{ margin: '5px', minWidth: 0 }}
                            variant="contained"
                            onClick={() => {
                              setViewEvent(event)
                              setOpenModal(true)
                            }}
                          >
                            <PersonPinCircleIcon sx={{ fontSize: 20 }} />
                          </Button>
                        </Tooltip>

                        <Tooltip arrow title="Eliminar evento">
                          <span>
                            <Button
                              color="error"
                              disabled={isAdmin ? false : new Date(event.date) < new Date()}
                              sx={{ margin: '5px', minWidth: 0 }}
                              variant="outlined"
                              onClick={() => {
                                handleDeleteEvent(event.id)
                              }}
                            >
                              <DeleteIcon sx={{ fontSize: 20 }} />
                            </Button>
                          </span>
                        </Tooltip>

                        {event.completedActivities > 0 && (
                          <Tooltip arrow title="Descargar reporte">
                            <Button variant="outlined">
                              <DownloadIcon sx={{ fontSize: 20 }} />
                            </Button>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    {Array(9)
                      .fill(0)
                      .map((e, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <TableCell key={i} align="center">
                          <Skeleton width="100%" />
                        </TableCell>
                      ))}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Box>
    </Box>
  )
}

export default EventsList
