import Tooltip from '@mui/material/Tooltip'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import LoadingButtonMui from '@mui/lab/LoadingButton'

const LoadingButtonBase = ({ disableActionReason, children, ...buttonProps }) => {
  return (
    <LoadingButtonMui
      {...buttonProps}
      color="primary"
      disabled={disableActionReason !== null}
      endIcon={<CheckCircleIcon />}
      loadingPosition="end"
      variant="contained"
    >
      {children}
    </LoadingButtonMui>
  )
}

export const LoadingButton = ({ disableActionReason, loading, children, ...buttonProps }) => {
  return disableActionReason != null ? (
    <Tooltip title={disableActionReason}>
      <div>
        <LoadingButtonBase
          disableActionReason={disableActionReason}
          loading={loading}
          {...buttonProps}
        >
          {children}
        </LoadingButtonBase>
      </div>
    </Tooltip>
  ) : (
    <LoadingButtonBase disableActionReason={disableActionReason} loading={loading} {...buttonProps}>
      {children}
    </LoadingButtonBase>
  )
}
