import { useAuth0 } from '@auth0/auth0-react'
import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import { useContext, useEffect, useState } from 'react'
import AppContext from '../../context/appContext'
import endpoints from '../../services/ruutsApi/endpoints'
import { ruutsApi } from '../../services/ruutsApi/index'
import AllotmentMap from './AllotmentMap'
import AllotmentPanel from './AllotmentPanel'

const AllotmentHome = () => {
  const { currentFarm, programConfig } = useContext(AppContext)
  const { getAccessTokenSilently } = useAuth0()

  const [farmSubdivisions, setFarmSubdivisions] = useState([])
  const [paddocks, setPaddocks] = useState([])
  const [paddocksFeatures, setPaddocksFeatures] = useState([])
  const [mapAction, setMapAction] = useState(null)
  const [selectedArea, setSelectedArea] = useState()
  const [selectedAreaType, setSelectedAreaType] = useState()
  const [farm, setFarm] = useState()
  const mapWidth = (window.innerWidth / 3) * 2
  const mapHeight = window.innerHeight
  const [wasSaved, setWasSaved] = useState(false)
  const [drawActivated, setDrawActivated] = useState(false)
  const [perimeter, setPerimeter] = useState()
  const [baseFarmSubdivisionYear, setBaseFarmSubdivisionYear] = useState()
  const [formDefinitions, setFormDefinitions] = useState([])
  const [yearsAllowed, setYearsAllowed] = useState()

  async function getFarm() {
    const token = await getAccessTokenSilently()
    const fDB = await ruutsApi.getApiData(`${endpoints.farms}/${currentFarm.id}`, null, token)
    const fSD = await ruutsApi.getApiData(
      endpoints.farmSubdivisions,
      { farmId: currentFarm.id },
      token,
    )
    const farmPaddocks = await ruutsApi.paddocks.getPaddocks({ farmId: currentFarm.id, token })
    farmPaddocks.sort((a, b) => b.totalHectares - a.totalHectares)
    setPerimeter(fDB[0].toGeoJSON)
    setFarmSubdivisions(fSD)
    setPaddocks(farmPaddocks)
    setFormDefinitions(programConfig.formDefinitions)
    setYearsAllowed(programConfig.farmSubdivisionsYearsAllowed)
    setFarm(fDB[0])
  }

  useEffect(() => {
    if (currentFarm) {
      getFarm()
    }
  }, [currentFarm])

  return (
    <Box>
      <Grid container>
        <Grid item xs={4}>
          <AllotmentPanel
            baseFarmSubdivisionYear={baseFarmSubdivisionYear}
            drawActivated={drawActivated}
            farm={farm}
            farmSubdivisions={farmSubdivisions}
            formDefinitions={formDefinitions}
            mapAction={mapAction}
            paddocks={paddocks}
            paddocksFeatures={paddocksFeatures}
            selectedArea={selectedArea}
            setBaseFarmSubdivisionYear={setBaseFarmSubdivisionYear}
            setDrawActivated={setDrawActivated}
            setFarmSubdivisions={setFarmSubdivisions}
            setMapAction={setMapAction}
            setPaddocks={setPaddocks}
            setPaddocksFeatures={setPaddocksFeatures}
            setSelectedArea={setSelectedArea}
            setSelectedAreaType={setSelectedAreaType}
            setWasSaved={setWasSaved}
            wasSaved={wasSaved}
            yearsAllowed={yearsAllowed}
          />
        </Grid>
        <Grid item xs={8}>
          <AllotmentMap
            farm={farm}
            mapAction={mapAction}
            mapHeight={mapHeight}
            mapWidth={mapWidth}
            paddocksFeatures={paddocksFeatures}
            perimeter={perimeter}
            selectedArea={selectedArea}
            selectedAreaType={selectedAreaType}
            setMapAction={setMapAction}
            setPaddocksFeatures={setPaddocksFeatures}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default AllotmentHome
