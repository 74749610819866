/* eslint-disable no-case-declarations */
/* eslint-disable no-loop-func */
// Leaflet
import L from 'leaflet'

// Turf
import * as turf from '@turf/turf'

// Omnivore
import omnivore from '@mapbox/leaflet-omnivore'

// Custom
import { XMLParser } from 'fast-xml-parser'
import { getPosibleFolderId } from '../mapHandle'
import { dataLayersAreas, dataLayersPoints } from '../FakeDataBase/data_fake'
import { sortKMLFeaturesByArea } from '../FarmLayers/exportMapeo'
import { getRandomColor } from '../mapDrawHandle'
import { plantillaParsing } from './plantillaHandle'
import { GeoJSON } from '../GeoJSON/index'
import { errors } from '../../services/ruutsApi/errors'

const getParsingFromOmnivore = data => {
  const rawData = omnivore.kml.parse(data)
  const geojsonData = rawData.toGeoJSON()

  /* Se crea objeto folders con key values para facilmente catalogar las features por key */
  const folders = {}

  dataLayersAreas.forEach(layer => {
    folders[layer.farmLayerType] = {
      features: [],
    }
  })

  dataLayersPoints.forEach(layer => {
    folders[layer.farmLayerType] = {
      features: [],
    }
  })

  const featuresToAssign = geojsonData.features.map((feature, i) => {
    let returnFeature = { ...feature }
    const { type } = feature.geometry

    switch (type) {
      case 'Point':
        returnFeature.properties.featureGroup = 'unassignedPoints'
        returnFeature.properties.id = i
        break
      case 'Polygon':
        returnFeature.properties.area = GeoJSON.readableArea(GeoJSON.area(returnFeature))
        returnFeature.properties.featureGroup = getPosibleFolderId(returnFeature.properties)
        returnFeature.properties.id = i
        break
      case 'MultiPolygon':
        returnFeature.properties.area = GeoJSON.readableArea(GeoJSON.area(returnFeature))
        returnFeature.properties.featureGroup = getPosibleFolderId(returnFeature.properties)
        returnFeature.properties.id = i
        break
      case 'GeometryCollection':
        const polygons = returnFeature.geometry.geometries
        const turfUnion = polygons.reduce((a, b) => turf.union(a, b), polygons[0])

        turfUnion.properties.name = returnFeature.properties.name
        turfUnion.properties.area = GeoJSON.readableArea(GeoJSON.area(returnFeature))
        turfUnion.properties.featureGroup = getPosibleFolderId(returnFeature.properties)
        turfUnion.properties.id = i
        returnFeature = turfUnion
        break
      default:
        return null
    }
    return returnFeature
  })

  /* Cataloga las featues */
  featuresToAssign.forEach(fta => folders[fta.properties.featureGroup].features.push(fta))

  /* mapea el objeto Folders a un array */
  const foldersArray = Object.keys(folders).map(folderName => {
    const obj = {
      name: folderName,
      features: folders[folderName].features,
    }
    return obj
  })
  return foldersArray
}

export const getFeaturesFromKML = (
  data,
  currentFarm,
  handleCenter,
  handlePerimeter,
  handlePaddocks,
  handleSamplingAreas,
  handleMonitoringSites,
  handleUnassigned,
  handleUnassignedPoints,
  handleExclusionAreas,
) => {
  try {
    const parser = new XMLParser()
    const json = parser?.parse(data)

    let folders = null

    // --- Parseo con plantilla ---
    folders = plantillaParsing(json)

    if (!folders) {
      // --- Parseo con omnivore
      try {
        folders = getParsingFromOmnivore(data)
      } catch (error) {
        throw new errors.UnexpectedEntityError({
          message:
            'El archivo KML/KMZ utilizado puede tener errores de carga, verificar que el archivo no posea capas vacías.',
        })
      }

      folders.forEach(folder => {
        if (folder?.features?.length > 0) {
          // eslint-disable-next-line no-param-reassign
          folder.features = folder.features.filter(feature => {
            if (feature.geometry.type === 'Polygon') {
              /* Verificar que sea valido el polígono? , buscar alguna lib que lo haga */
              return feature.geometry.coordinates.every(e => Array.isArray(e) && e.length > 3)
            }
            return true
          })
        }
      })
    }

    folders.forEach(folder => {
      if (folder.features?.length > 0) {
        switch (folder.name) {
          case 'perimeter':
            handlePerimeter(folder.features)
            break
          case 'paddocks':
            // Ordena paddocks por tamaño de área
            const newPaddocks = sortKMLFeaturesByArea(folder.features)
            handlePaddocks(newPaddocks)
            break
          case 'estratas':
            // Ordena estratos por tamaño de área
            const newSamplingAreas = sortKMLFeaturesByArea(folder.features)

            // Agrega color inicial en HUE de GREEN
            const coloredSamplingAreas = newSamplingAreas.map(feature => {
              const color = getRandomColor()
              const newFeature = structuredClone(feature)
              newFeature.properties.color = color
              return newFeature
            })
            handleSamplingAreas(coloredSamplingAreas)
            break
          case 'monitoringSites':
            handleMonitoringSites(folder.features)
            break
          case 'exclusion':
            const newExclusionAreas = sortKMLFeaturesByArea(folder.features)
            handleExclusionAreas(newExclusionAreas)
            break
          case 'unassigned':
            const newUnassigned = sortKMLFeaturesByArea(folder.features)
            handleUnassigned(newUnassigned)
            break
          case 'unassignedPoints':
            handleUnassignedPoints(folder.features)
            break
          default:
            // default case
            break
        }
      }
    })
  } catch (error) {
    if (error instanceof errors.RuutsError) {
      throw error
    }

    throw new errors.UnexpectedEntityError({ message: error.message })
  }
}
