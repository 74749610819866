import { Box, FormControlLabel, FormHelperText } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useFormContext, Controller } from 'react-hook-form'
import { useEffect, useState, useContext } from 'react'
import dayjs from 'dayjs'
import ReactMarkdown from 'react-markdown'
import { dateBoundaries } from '../../../utils/dataCollection/dateBoundaries'
import lfStore from '../../../lfstore/lfStore'
import { dateValidations } from '../../../utils/dataCollection/dateValidations'
import AppContext from '../../../context/appContext'

const DateFieldWrapper = ({ control, formField, year, readOnly, fieldArrayIndex }) => {
  const { watch, getValues } = useFormContext()
  const { setPartialChanges } = useContext(AppContext)
  const lastFarmSubdivisionYear = getValues('lastFarmSubdivisionYear')
  const [currentDateBoundaries, setCurrentDateBoundaries] = useState(null)
  const boundariesValidation = formField?.validations?.find(
    validation => validation?.type === 'boundaries',
  )
  const boundaryValidationStartDateRange =
    boundariesValidation?.name === 'formFieldsRange'
      ? watch(boundariesValidation.formFields.startDate.value)
      : null
  const boundaryValidationEndDateRange =
    boundariesValidation?.name === 'formFieldsRange'
      ? watch(boundariesValidation.formFields.endDate.value)
      : null

  useEffect(() => {
    if (boundariesValidation) {
      const newDateBoundaries = dateBoundaries[boundariesValidation.name]({
        year,
        startDate: boundaryValidationStartDateRange,
        endDate: boundaryValidationEndDateRange,
        lastFarmSubdivisionYear,
      })
      setCurrentDateBoundaries(newDateBoundaries)
    }
  }, [
    boundariesValidation,
    setCurrentDateBoundaries,
    year,
    boundaryValidationStartDateRange,
    boundaryValidationEndDateRange,
    lastFarmSubdivisionYear,
  ])

  return (
    <FormControlLabel
      control={
        <Controller
          control={control}
          defaultValue={formField.defaultValue}
          name={`${formField.name}`}
          render={({ field, fieldState }) => (
            <Box sx={{ marginLeft: 1.5, width: '100%' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disabled={readOnly}
                  format="DD/MM/YYYY"
                  label={formField.placeholder}
                  maxDate={currentDateBoundaries?.maxDate}
                  minDate={currentDateBoundaries?.minDate}
                  slotProps={{
                    textField: {
                      sx: { width: '100%' },
                      error: fieldState.error,
                    },
                  }}
                  value={field.value ? dayjs(field.value, 'YYYY-MM-DD') : null}
                  onChange={value => {
                    setPartialChanges(true)
                    field.onChange(value.format('YYYY-MM-DD'))
                    if (!readOnly) lfStore.setItem('dirtyForm', true)
                  }}
                />
              </LocalizationProvider>
              <FormHelperText error={fieldState.error} sx={{ px: 1.5 }}>
                <ReactMarkdown>
                  {fieldState.error ? fieldState.error.message : formField.helperText}
                </ReactMarkdown>
              </FormHelperText>
            </Box>
          )}
          rules={{
            required: formField.required ? ' Campo requerido' : false,
            validate: value => {
              if (!formField?.validations) return true

              return !formField.validations.some(formFieldValidation => {
                const validation = dateValidations[formFieldValidation.name]
                if (!validation) return false

                let formFieldsValues = {}
                if (formFieldValidation?.formFields) {
                  formFieldsValues = Object.entries(formFieldValidation.formFields).reduce(
                    (acc, [formFieldValueKey, fieldInfo]) => ({
                      ...acc,
                      [formFieldValueKey]: {
                        ...fieldInfo,
                        value: getValues(fieldInfo.value.replace('[groupIndex]', fieldArrayIndex)),
                      },
                    }),
                    {},
                  )
                }

                const validationResult = validation(value, formFieldsValues)
                return validationResult !== true
              })
            },
          }}
        />
      }
      sx={{ width: '100%' }}
    />
  )
}

export default DateFieldWrapper
