import findClosestPointOnPerimeter from './findClosestPointOnPerimeter'
import getDistanceUtm from './getDistanceUtm'
import distanceOnPerimeter from './getDistanceOnPerimeter'
import validateInputStringCoordinates from './validateInputStringCoordinates'

export const geoLocations = {
  findClosestPointOnPerimeter,
  getDistanceUtm,
  distanceOnPerimeter,
  validateInputStringCoordinates,
}
