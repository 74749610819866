/* eslint-disable react/no-array-index-key */
import CheckIcon from '@mui/icons-material/Check'
import {
  Chip,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { ReactNode } from 'react'
import { formModes } from '../../utils/constants'
import refDataById from '../../services/localRefData/refDataById'
import {
  FarmSubdivision,
  FieldDefinition,
  FormDefinition,
  MetricEvent,
} from '../../types/apiModels'
import { formatNumber } from '../../utils/formatNumber'
import { BooleanRowData } from './BooleanRowData'
import { FindingsRowData } from './FindingsRowData'
import { MetricEventStatusRowData } from './MetricEventStatusRowData'

export type FormMode = (typeof formModes)[keyof typeof formModes]

export type ColumnOption = {
  name: string
  label: string
  options: {
    display?: boolean
    filter?: boolean
    sort?: boolean
    customBodyRender?: (_value: unknown) => ReactNode
  }
}

const dataFieldTypes = [
  'TextField',
  'DateField',
  'Select',
  'RadioGroup',
  'CheckBox',
  'CheckBoxGroup',
  'FieldArray',
  'FileInput',
]

type CellValue =
  | {
      value: string | number
      unit?: string
    }
  | string
  | number
  | boolean

const processFieldArray = async (
  fieldArrayData: unknown[],
  fieldDefinition: FieldDefinition,
): Promise<ReactNode> => {
  const columnHeaders = fieldDefinition?.fields?.map(field => field.label) || []

  const processedData = await Promise.all(
    fieldArrayData.map(async nestedRecord => {
      const rowData = await Promise.all(
        fieldDefinition?.fields?.map(async (field: FieldDefinition) => {
          const value = nestedRecord[field.name]

          if (field.refDataKey) {
            const refValue = await refDataById(field.refDataKey, parseInt(value as string, 10))
            return refValue?.es_AR || ''
          }

          if (typeof value === 'boolean') {
            return value ? 'Sí' : 'No'
          }
          if (value === 'true' || value === 'false') {
            return value === 'true' ? 'Sí' : 'No'
          }

          if (value && field.unit) {
            return { value: formatNumber(value), unit: field.unit }
          }

          return formatNumber(value) || ''
        }) || [],
      )

      return rowData
    }),
  )
  return (
    <TableContainer component={Paper} sx={{ marginTop: '8px' }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {columnHeaders.map((header: string) => (
              <TableCell key={header}>
                <Typography fontWeight="bold" variant="caption">
                  {header}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {processedData.map((row: CellValue[], rowIndex: number) => (
            <TableRow key={rowIndex}>
              {row.map((cell: CellValue, cellIndex: number) => {
                const cellValue = typeof cell === 'object' && 'value' in cell ? cell.value : cell
                const cellUnit = typeof cell === 'object' && 'unit' in cell ? cell.unit : ''
                return (
                  <TableCell key={cellIndex} align={Number(cellValue) ? 'right' : 'left'}>
                    <Typography component="span" variant="body2">
                      {cellValue}
                      {cellUnit && ` ${cellUnit}`}
                    </Typography>
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const processFileInput = (fileInputData: string[]): ReactNode => {
  return (
    <Stack gap="8px">
      {fileInputData.map((fileUrl: string) => {
        const fileName = fileUrl.split('/').pop()
        return (
          <Link
            key={fileUrl}
            href={fileUrl}
            sx={{ textOverflow: 'ellipsis', display: 'block', overflow: 'hidden' }}
            target="_blank"
          >
            {decodeURIComponent(fileName)}
          </Link>
        )
      })}
    </Stack>
  )
}

const formatDate = (date: string): string => {
  return date ? date.split('-').reverse().join('/') : ''
}

const processRefDataField = async (
  value: unknown,
  fieldDefinition: FieldDefinition | undefined,
  refDataKeys: string[],
): Promise<string | ReactNode> => {
  if (
    fieldDefinition &&
    fieldDefinition.refDataKey &&
    refDataKeys.includes(fieldDefinition.refDataKey)
  ) {
    if (Array.isArray(value)) {
      const values = await Promise.all(
        value.map((id: string | number) =>
          refDataById(fieldDefinition.refDataKey as string, parseInt(id.toString(), 10)),
        ),
      )
      return values.map(v => v?.es_AR || '').join(' - ')
    }
    const refValue = await refDataById(fieldDefinition.refDataKey, parseInt(value as string, 10))
    return refValue?.es_AR || ''
  }
  if (typeof value === 'boolean') {
    return value ? 'Sí' : 'No'
  }
  if (value === 'true' || value === 'false') {
    return value === 'true' ? 'Sí' : 'No'
  }

  if (value && fieldDefinition?.unit) {
    return `${formatNumber(value)} ${fieldDefinition.unit}`
  }

  return formatNumber(value) || ''
}

type ProcessRecordDataParams = {
  record: MetricEvent
  formDefinition: FormDefinition
  refDataKeys: string[]
  farmSubdivision?: FarmSubdivision
  formMode?: FormMode
  handleMetricReset?: () => void
}

export type MetricEventRow = Partial<
  Omit<MetricEvent, 'Findings' | 'isVerified' | 'metricStatusId'> & {
    findings: ReactNode
    isVerified: ReactNode
    metricStatusId: ReactNode
  }
>

export const processRecordData = async ({
  record,
  formDefinition,
  refDataKeys,
  farmSubdivision,
  formMode,
  handleMetricReset,
}: ProcessRecordDataParams): Promise<MetricEventRow> => {
  const data = record.isDraft ? record : record.data

  const row: MetricEventRow = {
    id: record.id,
    isDraft: record.isDraft,
    metricYear: data.metricYear,
    notApplicable: data.notApplicable,
    paddocks:
      data.selectedPaddocks
        ?.map((id: string) => {
          const feature = farmSubdivision?.features?.find(f => f.properties.id === id)
          return feature?.properties?.name || ''
        })
        .join(' - ') || 'General',
    findings: (
      <FindingsRowData
        findings={record?.Findings || []}
        handleMetricReset={handleMetricReset}
        readOnly={formMode !== formModes.Review}
      />
    ),
    isVerified: <BooleanRowData value={record?.isVerified} />,
    metricStatusId: (
      <MetricEventStatusRowData
        handleMetricReset={handleMetricReset}
        metricId={record?.id || ''}
        statusId={record?.metricStatusId || null}
      />
    ),
  }

  const componentProcessors: Record<
    string,
    (_value: unknown, _definition?: FieldDefinition) => Promise<ReactNode> | ReactNode
  > = {
    FieldArray: (value, definition) =>
      processFieldArray(value as unknown[], definition as FieldDefinition),
    FileInput: value => processFileInput(value as string[]),
    DateField: value => formatDate(value as string),
    default: (value, definition) => processRefDataField(value, definition, refDataKeys),
  }

  const fieldDefinitionMap = formDefinition.fields.reduce<Record<string, FieldDefinition>>(
    (acc, field) => {
      return { ...acc, [field.name]: field }
    },
    {},
  )

  const fieldsToProcess = Object.keys(data).filter(
    key =>
      ![
        'id',
        'paddocks',
        'findings',
        'isVerified',
        'metricStatusId',
        'isDraft',
        'metricYear',
        'notApplicable',
      ].includes(key) && fieldDefinitionMap[key],
  )

  await Promise.all(
    fieldsToProcess.map(async key => {
      const fieldDefinition = fieldDefinitionMap[key]
      const processor =
        componentProcessors[fieldDefinition?.component_type] || componentProcessors.default
      const value = await processor(data[key], fieldDefinition)
      if (value !== null) {
        row[key] = value
      }
    }),
  )

  return row
}

type SpecificColumns = (keyof MetricEvent)[]

export const createColumns = async (
  formDefinition: FormDefinition,
  specificColumns: SpecificColumns,
  columnOptions: Partial<ColumnOption>[] = [],
): Promise<ColumnOption[]> => {
  const baseColumns: Record<string, ColumnOption> = {
    id: { name: 'id', label: 'id', options: { display: false } },
    isDraft: {
      name: 'isDraft',
      label: 'Borrador',
      options: {
        customBodyRender: (value): ReactNode => {
          if (value) {
            return <Chip color="warning" label="Borrador" />
          }
          return null
        },
        filter: true,
        sort: true,
      },
    },
    metricYear: { name: 'metricYear', label: 'Año', options: {} },
    paddocks: { name: 'paddocks', label: 'Lote/s', options: {} },
    isVerified: {
      name: 'isVerified',
      label: 'Verificada',
      options: {
        filter: true,
        sort: true,
      },
    },
    findings: {
      name: 'findings',
      label: 'Observaciones',
      options: {
        filter: false,
        sort: true,
      },
    },
    notApplicable: {
      name: 'notApplicable',
      label: 'No aplica',
      options: {
        customBodyRender: (value: unknown): ReactNode =>
          (value as boolean) ? <CheckIcon color="success" /> : null,
      },
    },
    metricStatusId: {
      name: 'metricStatusId',
      label: 'Estado',
      options: { filter: false, sort: true },
    },
  }

  const fieldDefinitionMap = formDefinition.fields.reduce<Record<string, FieldDefinition>>(
    (acc, field) => {
      if (field.label && field.name && dataFieldTypes.includes(field.component_type)) {
        return { ...acc, [field.name]: field }
      }
      return acc
    },
    {},
  )

  const formFieldColumns = Object.keys(fieldDefinitionMap).map(fieldName => {
    const field = fieldDefinitionMap[fieldName]
    return {
      name: field.name,
      label: field.label,
      options: {
        customBodyRender: (value: unknown): ReactNode => {
          if (field.component_type === 'DateField') return formatDate(value as string)
          if (typeof value === 'boolean') return value ? <CheckIcon color="success" /> : null
          if (Array.isArray(value)) return value.join(' - ')
          return value as ReactNode
        },
      },
    }
  })

  const allColumns = [...Object.values(baseColumns), ...formFieldColumns]

  const filteredColumns = allColumns.filter(col =>
    specificColumns.includes(col.name as keyof MetricEvent),
  )

  return filteredColumns.map(col => {
    const columnOption = columnOptions.find(co => co.name === col.name)
    if (columnOption) {
      return {
        ...col,
        ...columnOption,
        options: {
          ...col.options,
          ...columnOption.options,
        },
      }
    }
    return col
  })
}
