import DoneAllIcon from '@mui/icons-material/DoneAll'
import ErrorIcon from '@mui/icons-material/Error'
import { Badge, Box, Tooltip, Typography } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { FC, PropsWithChildren, useEffect, useState } from 'react'
import { type Finding } from '../../types/apiModels'
import { findingsTypes } from '../../utils/constants'
import FindingModalVisualization from '../Finding/FindingModalVisualization'

const indicatorTheme = createTheme({
  palette: {
    success: {
      main: '#008000',
    },
    warning: {
      main: '#DDDD00',
    },
    error: {
      main: '#FF8C00',
      '900': '#FF0000',
    },
  },
})

const FindingIndicator: FC<PropsWithChildren<{ total?: number; caption: string }>> = ({
  total,
  caption,
  children,
}) => {
  return (
    <Box sx={{ display: 'inline-flex', mr: 1 }}>
      <Tooltip title={total === undefined ? `${caption}` : `${caption} (${total})`}>
        <Badge
          badgeContent={total}
          color="primary"
          sx={{
            '& .MuiBadge-badge': {
              right: 2,
              top: 5,
              border: '2px solid white',
              padding: '0 4px',
            },
          }}
        >
          {children}
        </Badge>
      </Tooltip>
    </Box>
  )
}

const NonFindingsIndicator: FC = () => {
  return (
    <FindingIndicator key="non-observations" caption="No hay observaciones">
      <Typography sx={{ color: '#6e6e6e' }}>No hay observaciones</Typography>
    </FindingIndicator>
  )
}

const AllFindingResolvedIndicator: FC<{ totalFindingsResolved: number }> = ({
  totalFindingsResolved,
}) => {
  return (
    <FindingIndicator
      key="non-observations"
      caption="Todas las observaciones resueltas"
      total={totalFindingsResolved}
    >
      <DoneAllIcon style={{ color: indicatorTheme.palette.success.main }} />
    </FindingIndicator>
  )
}

const FindingsIndicators: FC<{
  totalFindingsByType: {
    observations: number
    nonComplaintsToFix: number
    nonComplaintsSevere: number
  }
}> = ({ totalFindingsByType }) => {
  return (
    <>
      {totalFindingsByType.observations > 0 && (
        <FindingIndicator
          key={findingsTypes.Observation}
          caption="Observaciones"
          total={totalFindingsByType.observations}
        >
          <ErrorIcon style={{ color: indicatorTheme.palette.warning.main }} sx={{ fontSize: 32 }} />
        </FindingIndicator>
      )}
      {totalFindingsByType.nonComplaintsToFix > 0 && (
        <FindingIndicator
          key={findingsTypes.NonComplaintToFix}
          caption="No conformes a corregir"
          total={totalFindingsByType.nonComplaintsToFix}
        >
          <ErrorIcon style={{ color: indicatorTheme.palette.error.main }} sx={{ fontSize: 32 }} />
        </FindingIndicator>
      )}
      {totalFindingsByType.nonComplaintsSevere > 0 && (
        <FindingIndicator
          key={findingsTypes.NonComplaintSevere}
          caption="No conformes graves"
          total={totalFindingsByType.nonComplaintsSevere}
        >
          <ErrorIcon style={{ color: indicatorTheme.palette.error['900'] }} sx={{ fontSize: 32 }} />
        </FindingIndicator>
      )}
    </>
  )
}

export const FindingsRowData: FC<{
  findings: Finding[] | null | undefined
  readOnly: boolean
  handleMetricReset: () => void
}> = ({ findings, readOnly, handleMetricReset }) => {
  const [totalFindings, setTotalFindings] = useState(0)
  const [totalFindingsResolved, setTotalFindingResolved] = useState(0)
  const [totalFindingsByType, setTotalFindingsByType] = useState({
    observations: 0,
    nonComplaintsToFix: 0,
    nonComplaintsSevere: 0,
  })

  const [openModal, setOpenModal] = useState(false)

  const handleCloseModal = (): void => {
    setOpenModal(false)
  }

  useEffect(() => {
    const safeFindings = Array.isArray(findings) ? findings : []

    const totalNonResolvedFindingsByType = ({
      type,
      fs,
    }: {
      type: number
      fs: Finding[]
    }): number => {
      return fs.filter(finding => !finding.resolved && finding.type === type).length
    }

    const observations = totalNonResolvedFindingsByType({
      type: findingsTypes.Observation,
      fs: safeFindings,
    })
    const nonComplaintsToFix = totalNonResolvedFindingsByType({
      type: findingsTypes.NonComplaintToFix,
      fs: safeFindings,
    })
    const nonComplaintsSevere = totalNonResolvedFindingsByType({
      type: findingsTypes.NonComplaintSevere,
      fs: safeFindings,
    })
    const newTotalFindings = safeFindings.length
    const newTotalFindingsResolved = safeFindings.filter(finding => finding.resolved).length

    setTotalFindings(newTotalFindings)
    setTotalFindingResolved(newTotalFindingsResolved)
    setTotalFindingsByType({ observations, nonComplaintsToFix, nonComplaintsSevere })
  }, [findings])

  const content =
    totalFindings === 0 ? (
      <NonFindingsIndicator />
    ) : totalFindingsResolved === totalFindings ? (
      <AllFindingResolvedIndicator totalFindingsResolved={totalFindingsResolved} />
    ) : (
      <FindingsIndicators totalFindingsByType={totalFindingsByType} />
    )

  const safeFindings = Array.isArray(findings) ? findings : []

  return (
    <>
      <Box
        sx={{
          minWidth: '160px',
          display: 'flex',
          justifyContent: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
        }}
        onClick={() => {
          setOpenModal(true)
        }}
      >
        {content}
      </Box>
      <FindingModalVisualization
        closeModal={handleCloseModal}
        findings={safeFindings}
        handleMetricReset={handleMetricReset}
        openModal={openModal}
        readOnly={readOnly}
      />
    </>
  )
}
