import CachedIcon from '@mui/icons-material/Cached'
import { Box, LinearProgress, Modal, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { displayMessageFromError } from '../../utils/Errors/errors'
import { customSnackbarError, customSnackbarSuccessful } from '../../utils/Snackbar/Snackbar'
import GlobalConfirmationModal, {
  ModalTypes,
} from '../GlobalConfirmationModal/GlobalConfirmationModal'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  bgcolor: 'background.paper',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
}

export const displayErrorMessageModes = {
  snackbar: 0,
  dialog: 1,
}

export const contactSupportReasons = {
  Generic: {
    title: 'Inconveniente en la plataforma',
  },
  PerimeterValidationsCouldNotValidate: {
    title: 'Inconveniente al validar perímetro',
  },
  PerimeterValidationsPreconditionFailed: {
    title: 'Inconveniente al ejecutar las validaciones de perímetro',
  },
}

const GlobalLoadingModal = ({
  open,
  title,
  dialogMessage,
  successfulMessage,
  error,
  errorMessage,
  displayErrorMessageMode = displayErrorMessageModes.snackbar,
  dryRun,
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [displayErrorMessageModal, setDisplayErrorMessageModal] = useState(false)

  useEffect(() => {
    if (successfulMessage) {
      if (!dryRun) customSnackbarSuccessful(successfulMessage, enqueueSnackbar, closeSnackbar)
      return
    }

    if (errorMessage) {
      switch (displayErrorMessageMode) {
        case displayErrorMessageModes.dialog:
          setDisplayErrorMessageModal(true)
          break
        case displayErrorMessageModes.snackbar:
        default:
          customSnackbarError(errorMessage, error, enqueueSnackbar, closeSnackbar)
      }
    }
  }, [open])

  const messageError = error && displayMessageFromError(errorMessage, error).displayMessage

  return (
    <>
      {error && (
        <GlobalConfirmationModal
          detail={dialogMessage}
          error={error}
          handleClose={() => {
            setDisplayErrorMessageModal(false)
          }}
          message={`${messageError}`}
          modalType={
            error.needSupport ? ModalTypes.ContactSupportModalType : ModalTypes.ErrorModelType
          }
          open={displayErrorMessageModal}
          title={title}
        />
      )}
      <Modal
        open={open}
        onClose={(event, _reason) => {
          event.stopPropagation()
        }}
      >
        <Box sx={modalStyle}>
          <CachedIcon
            sx={{
              position: 'absolute',
              top: -30,
              left: '50%',
              transform: 'translateX(-50%)',
              fontSize: 60,
              backgroundColor: 'white',
              color: '#ff9800',
              padding: 1,
              borderRadius: '50%',
            }}
          />
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: 600,
              margin: 2,
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontSize: 16,
              margin: 2,
            }}
          >
            {dialogMessage}
          </Typography>

          <LinearProgress
            sx={{
              display: 'block',
            }}
          />
        </Box>
      </Modal>
    </>
  )
}

export default GlobalLoadingModal
