import { useAuth0 } from '@auth0/auth0-react'
import { Cancel, Delete, Edit, Save } from '@mui/icons-material'
import { Badge, Box, Grid, IconButton, Input, Tooltip, Typography } from '@mui/material'
import { useCallback, useContext, useEffect, useState } from 'react'
import AppContext from '../../context/appContext'
import lfStore from '../../lfstore/lfStore'
import { deleteMonitoringSite } from '../../services/farmMapping/monitoringSites/deleteMonitoringSite'
import { getFarm } from '../../services/farmMapping/getFarms'
import { updateFarmValidation } from '../../services/farmMapping/validateFarm'
import { geoLocations } from '../../services/geoLocations'
import { errors } from '../../services/ruutsApi/errors'
import { useProcessLoading } from '../../utils/Loading/useProcessLoading'
import { useProcessLoadingWithConfirmation } from '../../utils/Loading/useProcessLoadingWithConfirmation'
import SupportAlert from '../Alert/SupportAlert'
import { Button } from '../Button/Button'
import {
  contactSupportReasons,
  displayErrorMessageModes,
} from '../GlobalLoadingModal/GlobalLoadingModal'
import { mapActionsTypes } from './PerimeterMap'
import { updateMonitoringSite } from '../../services/farmMapping/monitoringSites/updateMonitoringSite'
import { useCustomSnackbarError } from '../../utils/Snackbar/useCustomSnackbarError'

const requiredMinimumValidationPoints = 4
const maximumValidDistanceFromPerimeter = 10
const validationPointNamePrefix = 'FIELD-BOUNDARIES-CHECK'

const perimeterValidationStatuses = {
  Unvalidated: 1,
  Valid: 2,
  Validated: 3,
}

const VALID_GEOMETRY_TYPES = ['Polygon', 'MultiPolygon']

const PerimeterToValidate = ({
  overrideEdition,
  canValidate,
  isPerimeterValidated,
  setMapActions,
  setFeatures,
  featuresUpdated,
  setHighlightedPoint,
  mapReady,
}) => {
  const { user, getAccessTokenSilently } = useAuth0()
  const { setCurrentFarm, setLoadingModalConfig, currentFarm } = useContext(AppContext)
  const { setConfirmationModalConfig, setPartialChanges } = useContext(AppContext)
  const [validationPointsProcessed, setValidationPointsProcessed] = useState([])
  const [perimeterValidationStatus, setPerimeterValidationStatus] = useState(undefined)
  const { processLoading } = useProcessLoading()
  const { processLoadingWithConfirmation } = useProcessLoadingWithConfirmation()
  const { handleError } = useCustomSnackbarError()

  const validatePerimeter = (perimeter, validationPoints) => {
    return validationPoints.map(point => {
      const distanceFromPerimeter = Math.round(
        geoLocations.distanceOnPerimeter(point, perimeter).distance,
      )
      return {
        ...point,
        distanceFromPerimeter,
        isValidated: distanceFromPerimeter <= maximumValidDistanceFromPerimeter,
      }
    })
  }

  const handleValidatePerimeter = useCallback(
    features => {
      const perimeters = features.filter(feature =>
        VALID_GEOMETRY_TYPES.includes(feature.geometry.type),
      )
      if (perimeters?.length !== 1)
        throw new errors.BusinessEntityError({
          message:
            'No se puede validar el perímetro, cantidad capas en el perímetro no soportadas. Contactar soporte',
        })
      const perimeter = perimeters[0]

      const validationPoints = features.filter(feature => feature.geometry.type === 'Point')
      const processedValidationPoints = validatePerimeter(perimeter, validationPoints)
      const allPointsAreValidated =
        processedValidationPoints.filter(point => point.isValidated).length ===
        validationPoints.length
      const hasRequiredMinimumValidations =
        validationPoints.length >= requiredMinimumValidationPoints

      const newPerimeterValidationStatus = overrideEdition
        ? perimeterValidationStatuses.Valid
        : isPerimeterValidated
          ? perimeterValidationStatus.Validated
          : allPointsAreValidated && hasRequiredMinimumValidations
            ? perimeterValidationStatuses.Valid
            : perimeterValidationStatuses.Unvalidated

      perimeter.style = {
        color: [perimeterValidationStatuses.Valid, perimeterValidationStatuses.Validated].includes(
          newPerimeterValidationStatus,
        )
          ? 'green'
          : 'red',
      }
      const newValidationPointsProcessed = processedValidationPoints.map(point => {
        return {
          ...point,
          style: { color: point.isValidated ? 'green' : 'red' },
          isEdit: false,
        }
      })

      setMapActions([
        {
          id:
            newPerimeterValidationStatus === perimeterValidationStatuses.Validated ||
            !canValidate.value
              ? mapActionsTypes.editEnd
              : mapActionsTypes.editStart,
          propertiesFeature: [{ id: perimeter.properties.id }],
        },
      ])
      setFeatures([perimeter, ...newValidationPointsProcessed])
      setValidationPointsProcessed(newValidationPointsProcessed)
      setPerimeterValidationStatus(newPerimeterValidationStatus)
      setPartialChanges(
        canValidate.status &&
          newPerimeterValidationStatus !== perimeterValidationStatuses.Validated,
      )

      return { status: newPerimeterValidationStatus, perimeter }
    },
    [
      canValidate.status,
      canValidate.value,
      isPerimeterValidated,
      perimeterValidationStatus,
      setFeatures,
      setMapActions,
      setPartialChanges,
      overrideEdition,
    ],
  )

  useEffect(() => {
    if (!mapReady || !featuresUpdated) return

    async function validate() {
      await processLoading({
        loadingMessage: 'Validando perímetro...',
        errorMessage: 'Error al validar el perímetro.',
        doAction: async () => {
          handleValidatePerimeter(featuresUpdated)
        },
      })
    }

    validate()
  }, [mapReady, featuresUpdated, canValidate, processLoading, handleValidatePerimeter])

  const reloadFarm = async () => {
    const { id } = currentFarm
    const token = await getAccessTokenSilently()
    const farm = await getFarm({ id, user, token })

    lfStore.setItem('currentFarm', farm)
    setCurrentFarm(farm)
    return farm
  }

  const saveValidatePerimeter = async ({ features, dryRun, token }) => {
    const { status, perimeter } = handleValidatePerimeter(features)
    if (status !== perimeterValidationStatuses.Valid) return

    const farmId = perimeter.properties.id
    await updateFarmValidation({
      id: farmId,
      geometry: perimeter.geometry,
      originalGeometry: perimeter.properties.originalGeometry,
      dryRun,
      overrideEdition,
      token,
    })

    const farm = reloadFarm()

    if (farm.isPerimeterValidated) {
      setMapActions([
        {
          id: mapActionsTypes.editEnd,
          propertiesFeature: [{ id: perimeter.properties.id }],
        },
      ])
    }
  }

  const handleSaveValidatePerimeter = async features => {
    await processLoadingWithConfirmation({
      getToken: getAccessTokenSilently,
      setPartialChanges,
      loadingModalConfig: {
        setLoadingModalConfig,
        loadingMessage: 'Validando perímetro...',
        contactReasonSupportType: contactSupportReasons.PerimeterValidationsCouldNotValidate,
        errorMessage: 'Error al validar el perímetro, por favor intente nuevamente',
        displayErrorMessageMode: displayErrorMessageModes.dialog,
        successfulMessage: '',
      },
      confirmationModalConfig: {
        setConfirmationModalConfig,
        title: 'Confirmar validación de perímetro',
        message:
          'Una vez hecha la validación no se podrá modificar el perímetro. ¿Desea continuar?',
      },
      doAction: async ({ token, dryRun }) => {
        await saveValidatePerimeter({ dryRun, features, token })
      },
    })
  }

  const getPointShortName = point => {
    const { name } = point?.properties || {}
    return name
      ?.split('-')
      .map(word => (Number.isInteger(Number(word)) ? word : word[0]))
      .join('')
  }

  const getPointNumber = point => {
    const { name } = point?.properties || {}
    return name?.split('-').map(word => (Number.isInteger(Number(word)) ? word : word[0]))[3] ?? ''
  }

  const handleDeletePoint = async siteId => {
    await processLoadingWithConfirmation({
      confirmationTitle: 'Confirmar eliminación de sitio',
      confirmationMessage: '¿Estás seguro que deseas eliminar el sitio?',
      loadingMessage: 'Eliminando sitio...',
      errorMessage: 'Error al eliminar el sitio',
      successfulMessage: 'Sitio eliminado',
      doAction: async ({ token, dryRun }) => {
        if (dryRun) return
        await deleteMonitoringSite(siteId, token)
        setValidationPointsProcessed(points =>
          points.filter(point => point.properties.id !== siteId),
        )
      },
    })
  }

  const handleEditPoint = async (siteId, value) => {
    if (value === undefined) {
      setValidationPointsProcessed(sites => {
        return sites.map(point => {
          if (point.properties.id === siteId) {
            return {
              ...point,
              isEdit: !point.isEdit,
            }
          }
          return point
        })
      })
    } else if (value === '' || (value >= 1 && value <= 99)) {
      setValidationPointsProcessed(sites => {
        return sites.map(point => {
          if (point.properties.id === siteId) {
            return {
              ...point,
              properties: {
                ...point.properties,
                name: `${validationPointNamePrefix}-${value}`,
              },
            }
          }
          return point
        })
      })
    }
  }

  const validatePointName = value => {
    const regex = new RegExp(`^${validationPointNamePrefix}-[1-9]\\d*$`)
    if (!regex.test(value)) return false
    return validationPointsProcessed.filter(point => point.properties.name === value).length === 1
  }

  const handleSavePointName = async site => {
    if (!validatePointName(site.properties.name)) {
      handleError(undefined, 'El nombre del punto de validación no es válido.', false)
      return
    }

    await processLoadingWithConfirmation({
      confirmationTitle: 'Confirmar edición de sitio',
      confirmationMessage: '¿Estás seguro que deseas editar el sitio?',
      loadingMessage: 'Editando sitio...',
      errorMessage: 'Error al editar el sitio',
      successfulMessage: 'Sitio editado',
      doAction: async ({ token, dryRun }) => {
        if (dryRun) return
        const updatedSite = { id: site.properties.id, name: site.properties.name }
        await updateMonitoringSite(updatedSite, token)
        handleEditPoint(site.properties.id)
        setValidationPointsProcessed(points =>
          points.sort((a, b) => a.properties.name.localeCompare(b.properties.name)),
        )
      },
    })
  }

  const handleCancelEditPoint = async siteId => {
    const originalFeature = featuresUpdated.find(feature => feature.properties.id === siteId)
    setValidationPointsProcessed(points =>
      points.map(point => {
        if (point.properties.id === siteId) {
          return {
            ...point,
            properties: {
              ...originalFeature.properties,
            },
            isEdit: false,
          }
        }
        return point
      }),
    )
  }

  return (
    <>
      {canValidate && (
        <Grid container>
          {!canValidate.value && !overrideEdition ? (
            <Grid item sx={{ paddingTop: '20px', textAlign: 'center' }} xs={12}>
              <SupportAlert
                contactSupportReasonType={canValidate.contactSupportReasonType}
                error={canValidate.error}
                message={canValidate.displayError}
              />
            </Grid>
          ) : (
            <>
              <Grid item sx={{ padding: '20px', textAlign: 'center' }} xs={12}>
                <Typography variant="p">
                  Los puntos resaltados en rojo deben mantenerse a una distancia máxima de{' '}
                  {maximumValidDistanceFromPerimeter} metros del perímetro. Por favor, ajuste las
                  dimensiones del perímetro hasta que todos los puntos estén a una distancia de
                  hasta {maximumValidDistanceFromPerimeter} metros y se muestren en verde.
                </Typography>
              </Grid>
              <Grid container sx={{ mb: '10px', textAlign: 'center', alignItems: 'center' }}>
                <Grid item xs={3}>
                  <Typography typography="body2">
                    <b>Nombre</b>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography typography="body2">
                    <b>Coordenadas</b>
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography typography="body2">
                    <b>Distancia</b>
                  </Typography>
                </Grid>
                <Grid item xs={2} />
              </Grid>
              {validationPointsProcessed.length > 0 &&
                validationPointsProcessed.map(point => {
                  return (
                    <Grid
                      key={point.properties.id}
                      container
                      sx={{ alignItems: 'center' }}
                      onMouseEnter={() => setHighlightedPoint(point)}
                      onMouseLeave={() => setHighlightedPoint(null)}
                    >
                      <Grid item xs={3}>
                        {!point.isEdit ? (
                          <Tooltip title={point.properties.name}>
                            <Typography sx={{ fontSize: 16 }}>
                              {getPointShortName(point)}
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Input
                            required
                            max={99}
                            min={1}
                            name="pointName"
                            type="number"
                            value={getPointNumber(point)}
                            onChange={e => handleEditPoint(point.properties.id, e.target.value)}
                          />
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="p">
                          {`${
                            point.geometry.coordinates?.[1]?.toFixed(11) ?? 0
                          }, ${point.geometry.coordinates?.[0]?.toFixed(11) ?? 0}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Badge
                          badgeContent={`${point.distanceFromPerimeter.toFixed()} m`}
                          color={point.isValidated ? 'success' : 'error'}
                          sx={{
                            '& .MuiBadge-badge': {
                              backgroundColor: point.style.color,
                              color: 'white',
                              fontSize: '0.8rem',
                              fontWeight: 'bold',
                              minWidth: '70px',
                              minHeight: '20px',
                              padding: '0 0px',
                              right: '0px',
                            },
                          }}
                        />
                      </Grid>
                      {!point.isEdit ? (
                        <Grid item sx={{ flexDirection: 'row', display: 'flex' }} xs={2}>
                          <Tooltip title="Editar">
                            <IconButton
                              aria-label="edit"
                              color="primary"
                              onClick={() => handleEditPoint(point.properties.id)}
                            >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Eliminar">
                            <IconButton
                              aria-label="delete"
                              color="error"
                              onClick={() => handleDeletePoint(point.properties.id)}
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      ) : (
                        <Grid item sx={{ flexDirection: 'row', display: 'flex' }} xs={2}>
                          <Tooltip title="Guardar">
                            <IconButton
                              aria-label="save"
                              color="success"
                              onClick={() => handleSavePointName(point)}
                            >
                              <Save />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Cancelar">
                            <IconButton
                              aria-label="cancel"
                              color="error"
                              onClick={() => handleCancelEditPoint(point.properties.id)}
                            >
                              <Cancel />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                    </Grid>
                  )
                })}
              <Grid item xs={12}>
                <Box sx={{ padding: '20px', textAlign: 'center' }}>
                  <Button
                    disabled={
                      !canValidate.value ||
                      perimeterValidationStatus !== perimeterValidationStatuses.Valid ||
                      validationPointsProcessed.some(point => point.isEdit)
                    }
                    showTooltip={canValidate.reason}
                    tooltipTitle={canValidate.reason}
                    onClick={() => {
                      handleSaveValidatePerimeter(featuresUpdated)
                    }}
                  >
                    Validar perímetro
                  </Button>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  )
}

export default PerimeterToValidate
