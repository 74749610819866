/* eslint-disable no-shadow */
import { useAuth0 } from '@auth0/auth0-react'
import { useContext, useEffect, useState } from 'react'
import AppContext from '../../context/appContext'
import { getFarmManagementSupportiveDocumentationFormDefinition } from '../../services/ruutsApi/formDefinitions/getFormDefinitions'
import { ruutsApi } from '../../services/ruutsApi/index'
import { processLoading } from '../../utils/Loading/processLoading'
import { FormDefinitionDialog } from './FormDefinitionDialog'

const farmSubdivisionEntityTypeId = 0

const FarmSubdivisionDocumentsDialog = ({
  readOnly,
  farmSubdivisionId,
  year,
  open,
  handleClose,
}) => {
  const { getAccessTokenSilently } = useAuth0()
  const {
    setLoadingModalConfig,
    programConfig,
    partialChanges,
    setPartialChanges,
    setConfirmationModalConfig,
  } = useContext(AppContext)
  const [formData, setFormData] = useState(null)
  const [formDefinition, setFormDefinition] = useState(null)
  const [documentsFromEntity, setDocumentsFromEntity] = useState(null)
  const [fieldInputsFromFormDefinition, setFieldInputsFromFormDefinition] = useState(null)

  useEffect(() => {
    async function getFormDefinitions() {
      await processLoading({
        getToken: getAccessTokenSilently,
        setLoadingModalConfig,
        loadingMessage: 'Cargando documentación por año...',
        successfulMessage: '',
        doAction: async ({ token }) => {
          const documentsFormDefinition =
            await getFarmManagementSupportiveDocumentationFormDefinition(programConfig, token)

          // Get documents types from file input types
          const fieldInputsFromFormDefinition = documentsFormDefinition.fields.filter(
            field => field.component_type === 'FileInput',
          )
          const documentsTypesFromFormDefinition = fieldInputsFromFormDefinition.map(
            field => field.fileType,
          )

          // Get documents from entity
          const documentsFromEntity = await ruutsApi.documents.getByEntityAndDocumentsTypes({
            entityId: farmSubdivisionId,
            entityTypeId: farmSubdivisionEntityTypeId,
            documentTypes: documentsTypesFromFormDefinition,
            token,
          })

          // Generate form data from documents entities
          const formDataFromDocumentsEntity = {}
          fieldInputsFromFormDefinition.forEach(fieldInputFromFormDefinition => {
            const documentFromEntityInFieldDefinition = documentsFromEntity
              .filter(documentFromEntity => {
                return fieldInputFromFormDefinition.fileType === documentFromEntity.documentTypeId
              })
              .map(documentFromEntity => documentFromEntity.url)
            formDataFromDocumentsEntity[fieldInputFromFormDefinition.name] =
              documentFromEntityInFieldDefinition
          })

          setDocumentsFromEntity(documentsFromEntity)
          setFormData(formDataFromDocumentsEntity)
          setFormDefinition(documentsFormDefinition)
          setFieldInputsFromFormDefinition(fieldInputsFromFormDefinition)
        },
      })
    }

    if (open && farmSubdivisionId) {
      getFormDefinitions()
    }
  }, [open, programConfig, getAccessTokenSilently, farmSubdivisionId, setLoadingModalConfig])

  const handlePartialChangesClose = () => {
    handleClose()
    setPartialChanges(false)
  }

  const handleSaveFormData = async data => {
    await processLoading({
      getToken: getAccessTokenSilently,
      setLoadingModalConfig,
      loadingMessage: 'Guardando documentos...',
      successfulMessage: 'Documentos guardados',
      doAction: async ({ token }) => {
        // Convert file inputs data to documents entities
        const documentsArraysFromFileInput = []
        Object.keys(data).forEach(fileInputType => {
          const fileInputURLByType = data[fileInputType]
          if (fileInputURLByType && fileInputURLByType.length) {
            documentsArraysFromFileInput.push(
              fileInputURLByType.map(documentURL => {
                const fileInputFileByName = fieldInputsFromFormDefinition.find(
                  fieldInputFromFormDefinition => {
                    return fieldInputFromFormDefinition.name === fileInputType
                  },
                )
                return {
                  documentTypeId: fileInputFileByName.fileType,
                  entityTypeId: farmSubdivisionEntityTypeId,
                  entityId: farmSubdivisionId,
                  url: documentURL,
                }
              }),
            )
          }
        })
        const documentsFromFileInput = [].concat(...documentsArraysFromFileInput)

        // Filter documents action to save in API
        const documentsToUpdate = []
        const documentsToCreate = []
        documentsFromFileInput.forEach(documentFromFileInput => {
          const documentOriginalLoaded = documentsFromEntity.find(documentFromEntity => {
            return documentFromEntity.url === documentFromFileInput.url
          })
          if (!documentOriginalLoaded) {
            documentsToCreate.push(documentFromFileInput)
          } else {
            documentsToUpdate.push({ ...documentOriginalLoaded })
          }
        })

        // Filter documents to deleted from file input and the original documents saved
        const documentsToDelete = documentsFromEntity.filter(documentFromEntity => {
          return documentsFromFileInput.every(documentFromFileInput => {
            return documentFromEntity.url !== documentFromFileInput.url
          })
        })

        if (documentsToCreate.length)
          await ruutsApi.documents.create({ document: documentsToCreate, token })
        if (documentsToUpdate.length)
          await ruutsApi.documents.update({ document: documentsToUpdate, token })
        if (documentsToDelete.length)
          await ruutsApi.documents.remove({ documents: documentsToDelete, token })

        handlePartialChangesClose()
      },
    })
  }

  const handleCloseAndReset = () => {
    if (partialChanges) {
      setConfirmationModalConfig({
        open: true,
        confirmAction: () => {
          handlePartialChangesClose()
        },
      })
    } else {
      handleClose()
    }
  }

  return (
    formDefinition &&
    formDefinition.fields &&
    formDefinition.fields.length > 0 &&
    formData &&
    documentsFromEntity &&
    fieldInputsFromFormDefinition && (
      <FormDefinitionDialog
        data={formData}
        formDefinition={formDefinition}
        handleClose={handleCloseAndReset}
        open={open}
        readOnly={readOnly}
        saveFormData={handleSaveFormData}
        title={`Año ${year}`}
        year={year}
      />
    )
  )
}

export default FarmSubdivisionDocumentsDialog
