import { useAuth0 } from '@auth0/auth0-react'
import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { helpers } from '@turf/turf'
import { useCallback, useContext, useEffect, useState } from 'react'
import AppContext from '../../context/appContext'
import lfstore from '../../lfstore/lfStore'
import endpoints from '../../services/ruutsApi/endpoints'
import { ruutsApi } from '../../services/ruutsApi/index'
import { processLoading } from '../../utils/Loading/processLoading'
import { DCNamespaces } from '../../utils/dataCollection/namespaces'
import DataCollectionMap from '../DataCollectionMap/DataCollectionMap'
import PaddocksList from '../DataCollectionMap/PaddocksList'
import { DataTable } from '../DataTable/DataTable'
import DynamicForm from '../DynamicForm/DynamicForm'
import { useDraftFormStorage } from '../DynamicForm/useDraftFormStorage'
import { ModalTypes } from '../GlobalConfirmationModal/GlobalConfirmationModal'

const FormContainer = ({
  formMode,
  selectedMetricEvent,
  setSelectedMetricEvent,
  dataCollectionStatementId,
  formKey,
  year,
  resetTab,
}) => {
  const { getAccessTokenSilently } = useAuth0()
  const { setConfirmationModalConfig, setLoadingModalConfig, userRoleSelected, currentFarm } =
    useContext(AppContext)

  const [formDefinition, setFormDefinition] = useState()
  const [paddockIds, setPaddockIds] = useState([])
  const [paddocksGeoJson, setPaddocksGeoJson] = useState([])
  const [selectedPaddockIds, setSelectedPaddockIds] = useState([])
  const [usedPaddockIds, setUsedPaddockIds] = useState([])
  const [allSelected, setAllSelected] = useState(false)
  const [metricEvents, setMetricEvents] = useState([])
  const [paddockProductionEvents, setPaddockProductionEvents] = useState()
  const [metricEventRefresher, setMetricEventRefresher] = useState(0)
  const [resetFormSignal, toggleResetFormSignal] = useState(false)
  const [disableMap, setDisableMap] = useState(false)
  const [showMetrics, setShowMetrics] = useState(false)

  const { getDrafts, removeFromDrafts } = useDraftFormStorage({
    namespace: formDefinition?.namespace,
    farmId: currentFarm.id,
    year,
  })

  const handleMetricReset = () => {
    setSelectedMetricEvent(null)
    setMetricEventRefresher(metricEventRefresher + 1)
    resetTab()
  }

  const getMetricEvents = useCallback(async () => {
    try {
      setLoadingModalConfig({
        open: true,
        dialogMessage: 'Cargando métricas',
      })
      const token = await getAccessTokenSilently()
      const events = await ruutsApi.getApiData(
        endpoints.metricEvents,
        { farmId: currentFarm.id, namespace: formDefinition?.namespace, year },
        token,
      )

      let newPaddockProductionEvents = []
      if (formDefinition?.namespace === DCNamespaces.grazing) {
        newPaddockProductionEvents = await ruutsApi.getApiData(
          endpoints.metricEvents,
          { farmId: currentFarm.id, namespace: DCNamespaces.paddocks, year },
          token,
        )
      }

      setPaddockProductionEvents(newPaddockProductionEvents)
      const eventsAndDrafts = Array.isArray(events) ? [...events, ...getDrafts()] : getDrafts()

      setMetricEvents(eventsAndDrafts)
      setSelectedMetricEvent(null)
      setLoadingModalConfig({
        open: false,
        successfulMessage: 'Métricas cargadas con éxito',
      })

      if (!showMetrics) {
        setShowMetrics(true)
      }
    } catch (e) {
      console.error(e)
    }
  }, [
    setLoadingModalConfig,
    getAccessTokenSilently,
    currentFarm.id,
    formDefinition,
    year,
    getDrafts,
    setSelectedMetricEvent,
    showMetrics,
  ])

  async function handleDeleteMetric(metric, deletedMetricReason) {
    await processLoading({
      getToken: getAccessTokenSilently,
      setLoadingModalConfig,
      loadingMessage: 'Borrando métrica...',
      successfulMessage: 'Datos eliminados correctamente',
      errorMessage: 'Error al eliminar la métrica, por favor intente nuevamente',
      doAction: async ({ token }) => {
        await ruutsApi.deleteMetric(
          metric.id,
          dataCollectionStatementId,
          deletedMetricReason,
          userRoleSelected,
          token,
        )
        await getMetricEvents()
        handleMetricReset()
      },
    })
  }

  function handleSelectedMetric(row) {
    const selected = metricEvents.find(metric => metric.id === row[0].id)
    selected.dataIndex = row[0].index + 1
    setSelectedMetricEvent(selected)
  }

  function handleDeleteConfirmation(row) {
    const metricEventToDelete = metricEvents.find(metric => metric.id === row[0].id)

    if (metricEventToDelete.isDraft) {
      setConfirmationModalConfig({
        open: true,
        title: 'Borrar borrador',
        message: 'Se borrará el borrador seleccionado. ¿Desea continuar?',
        confirmLabel: 'Borrar',
        modalType: ModalTypes.DeleteModalType,
        confirmAction: () => {
          removeFromDrafts(metricEventToDelete.id)
          handleMetricReset()
        },
      })
    } else {
      const hasFindingToResolve = metricEventToDelete.Findings?.some(
        finding => finding.resolved === false,
      )
      let requiredTextField = null
      let confirmationMessage = 'Se borrará la métrica seleccionada. ¿Desea continuar?'
      if (hasFindingToResolve) {
        confirmationMessage =
          'La métrica seleccionada posee una observación sin resolver por el auditor. Para poder eliminarla es necesario que detalle el motivo ¿Desea continuar?'
        requiredTextField = {
          placeHolder: 'Motivo',
        }
      }
      setConfirmationModalConfig({
        open: true,
        title: 'Borrar métrica',
        message: confirmationMessage,
        confirmLabel: 'Borrar',
        modalType: ModalTypes.DeleteModalType,
        confirmAction: input => handleDeleteMetric(metricEventToDelete, input),
        requiredTextField,
      })
    }
  }

  function handleResetForm() {
    toggleResetFormSignal(!resetFormSignal)
    setSelectedMetricEvent(null)
  }

  useEffect(() => {
    async function getFormDefinition(formDefinitionKey) {
      try {
        const form = await lfstore.getItem(formDefinitionKey)
        if (form) setFormDefinition(form)
      } catch (e) {
        console.error(e)
      }
    }
    if (formKey) getFormDefinition(formKey)
  }, [formKey, getAccessTokenSilently])

  useEffect(() => {
    async function getPaddocks() {
      try {
        const token = await getAccessTokenSilently()
        const [farmSubdivision] = await ruutsApi.getApiData(
          endpoints.farmSubdivisions,
          { farmId: currentFarm.id, year },
          token,
        )
        const paddockPromises = farmSubdivision.paddockIds.map(id =>
          ruutsApi.paddocks.getPaddockById({ id, token }).then(([paddock]) => paddock),
        )

        const paddocksList = await Promise.all(paddockPromises)

        const features = helpers.featureCollection(paddocksList.map(paddock => paddock.toGeoJSON))

        const ids = paddocksList.map(paddock => paddock.id)

        setPaddocksGeoJson(features)
        setPaddockIds(ids)
      } catch (e) {
        console.error(e)
      }
    }

    if (year) getPaddocks()
  }, [getAccessTokenSilently, year, currentFarm])

  /* Get metricEvents from DB and update state */
  useEffect(() => {
    if (formDefinition && year) {
      getMetricEvents()
    }
  }, [formDefinition, year, getAccessTokenSilently, metricEventRefresher, getMetricEvents])

  return (
    formMode &&
    formDefinition &&
    paddocksGeoJson?.features?.length > 0 &&
    metricEvents && (
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Stack flex direction="row" flexDirection="row" flexGrow={0} height="100%">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#eceff1',
              width: !formDefinition?.layout?.showMap
                ? '100%'
                : !disableMap
                  ? '50%'
                  : 'calc(100% - 30px)',
            }}
          >
            <DynamicForm
              allowMoreRecords={formDefinition.allowMultipleRecords || !metricEvents?.length}
              dataCollectionStatementId={dataCollectionStatementId}
              formDefinition={formDefinition}
              formMode={formMode}
              handleDisableMap={setDisableMap}
              handleMetricReset={handleMetricReset}
              handleResetForm={() => handleResetForm()}
              selectedMetricEvent={selectedMetricEvent}
              selectedPaddockIds={selectedPaddockIds}
              year={year}
            >
              {formDefinition.layout.showMap && (
                <PaddocksList
                  allSelected={allSelected}
                  formMode={formMode}
                  paddocks={paddocksGeoJson.features.map(feature => {
                    return { id: feature.properties.id, label: feature.properties.name }
                  })}
                  selectedPaddockIds={selectedPaddockIds}
                  setAllSelected={setAllSelected}
                  setSelectedPaddockIds={setSelectedPaddockIds}
                  usedPaddockIds={usedPaddockIds}
                />
              )}
            </DynamicForm>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
            {formDefinition.layout.showMap && (
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                sx={{
                  height: 'calc(100% - 46px)',
                  width: '30px',
                  border: 'solid',
                  borderWidth: 1,
                  borderRadius: 1,
                  borderColor: '#bdbdbd',
                  boxSizing: 'border-box',
                  backgroundColor: '#eceff1',
                  display: 'flex',
                  padding: '4px',
                  alignItems: 'center',
                }}
                onClick={() => {
                  setDisableMap(v => !v)
                }}
              >
                {disableMap ? (
                  <KeyboardDoubleArrowLeft fontSize="inherit" />
                ) : (
                  <KeyboardDoubleArrowRight fontSize="inherit" />
                )}
              </IconButton>
            )}
            {formDefinition.layout.showMap && !disableMap && (
              <Box
                sx={{
                  flexBasis: '50%',
                  height: '100%',
                  backgroundColor: '#eceff1',
                }}
              >
                <DataCollectionMap
                  allPaddockIds={paddockIds}
                  allSelected={allSelected}
                  center={[-34.5, -58.4]}
                  formMode={formMode}
                  metricEvents={metricEvents}
                  namespace={formDefinition.namespace}
                  paddockProductionEvents={paddockProductionEvents}
                  paddocksGeoJson={paddocksGeoJson}
                  resetFormSignal={resetFormSignal}
                  selectedMetricEvent={selectedMetricEvent}
                  selectedPaddockIds={selectedPaddockIds}
                  setAllSelected={setAllSelected}
                  setSelectedPaddockIds={setSelectedPaddockIds}
                  setUsedPaddockIds={setUsedPaddockIds}
                  usedPaddockIds={usedPaddockIds}
                />
              </Box>
            )}
          </Box>
        </Stack>

        {showMetrics && (
          <DataTable
            farmSubdivision={paddocksGeoJson}
            formDefinition={formDefinition}
            formMode={formMode}
            handleDelete={row => handleDeleteConfirmation(row)}
            handleMetricReset={handleMetricReset}
            handleSelect={row => handleSelectedMetric(row)}
            metricEvents={metricEvents}
          />
        )}
      </Box>
    )
  )
}

export default FormContainer
