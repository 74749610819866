import { Grid } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import MapLayered from '../map/MapLayered'
import CategorizationPanel from '../formPanel/CategorizationPanel'
import AppContext from '../../context/appContext'
import lfStore from '../../lfstore/lfStore'
import getMonitoringSites from '../../services/farmMapping/monitoringSites/getMonitoringSites'
import getExclusionAreas from '../../services/farmMapping/getExclusionAreas'
import getExclusionAreaTypes from '../../services/farmMapping/getExclusionAreaTypes'
import getOtherPolygons from '../../services/farmMapping/getOtherPolygons'
import getOtherSites from '../../services/farmMapping/getOtherSites'
import getFarmSubdivisions from '../../services/farmMapping/getFarmSubdivision'
import { ruutsApi } from '../../services/ruutsApi'

const FarmMapping = ({
  actualYearId,
  handleActualYearId,
  saved,
  handleSaved,
  fileType,
  handleFileType,
  includeSoilInfo,
  handleIncludeSoilInfo,
  includeProjectArea,
  handleIncludeProjectArea,
  handleDownload,
}) => {
  const [drawingType, setDrawingType] = useState(null)
  const [center, setCenter] = useState([-35.329970415341656, -65.12262446073629])
  const [etapa, setEtapa] = useState('inicial')

  const { getAccessTokenSilently } = useAuth0()

  const { currentFarm } = useContext(AppContext)

  const [perimeter, setPerimeter] = useState(null)
  const [paddocks, setPaddocks] = useState(null)
  const [samplingAreas, setSamplingAreas] = useState(null)
  const [monitoringSites, setMonitoringSites] = useState(null)
  const [unassigned, setUnassigned] = useState(null)
  const [unassignedPoints, setUnassignedPoints] = useState(null)
  const [exclusionAreas, setExclusionAreas] = useState(null)

  const [otherPolygons, setOtherPolygons] = useState(null)
  const [otherSites, setOtherSites] = useState(null)

  const [mapUpdate, setMapUpdate] = useState(0)

  const [mapWidth, setMapWidth] = useState((window.innerWidth / 3) * 2)
  const [mapHeight, setMapHeight] = useState(window.innerHeight - 100)

  const handleDrawingType = type => {
    setDrawingType(type)
    lfStore.setItem('storedDrawingType', type)
  }

  const handleCenter = newCenter => {
    setCenter(newCenter)
  }

  // current
  const handleEtapa = newEtapa => {
    setEtapa(newEtapa)
  }

  const handlePerimeter = newPerimeter => {
    setPerimeter(newPerimeter)
  }

  const handlePaddocks = newPaddocks => {
    setPaddocks(newPaddocks)
  }

  const handleSamplingAreas = newSamplingAreas => {
    setSamplingAreas(newSamplingAreas)
  }

  const handleMonitoringSites = newMonitoringSites => {
    setMonitoringSites(newMonitoringSites)
  }

  const handleUnassigned = newUnassigned => {
    setUnassigned(newUnassigned)
  }

  const handleUnassignedPoints = newUnassignedPoints => {
    setUnassignedPoints(newUnassignedPoints)
  }

  const handleExclusionAreas = newExclusionAreas => {
    setExclusionAreas(newExclusionAreas)
  }

  const handleOtherPolygons = newEOtherPolygons => {
    setOtherPolygons(newEOtherPolygons)
  }

  const handleOtherSites = newEOtherSites => {
    setOtherSites(newEOtherSites)
  }

  const handleMapUpdate = () => {
    setMapUpdate(prev => prev + 1)
  }

  // Data cacheada
  useEffect(() => {
    const getDataFromCache = async () => {
      const storedDrawingType = await lfStore.getItem('storedDrawingType')
      setDrawingType(storedDrawingType)
    }
    getDataFromCache()
  }, [])

  // Perimeter
  useEffect(() => {
    if (currentFarm?.geometry) {
      handlePerimeter([currentFarm.toGeoJSON])
      handleEtapa('showingFoldersApi')
      lfStore.setItem('storedMapeoDone', false)
    }
  }, [currentFarm])

  // Paddocks
  useEffect(() => {
    async function getApiPaddocks(farm) {
      if (currentFarm && actualYearId) {
        const token = await getAccessTokenSilently()

        // farmSubdivisions by year
        const farmSubdivisions = await getFarmSubdivisions(farm, token)
        const actualFS = farmSubdivisions.find(fs => fs.id === actualYearId)

        // paddocks by year
        const allPaddocks = await ruutsApi.paddocks.getPaddocks({ farmId: farm.id, token })
        const data = allPaddocks.filter(paddock => actualFS.paddockIds.includes(paddock.id))

        const apiPaddocks = data.map(paddock => {
          const newPaddock = paddock.toGeoJSON
          return newPaddock
        })

        handlePaddocks(apiPaddocks)
      }
    }
    if (currentFarm) {
      getApiPaddocks(currentFarm)
    }
  }, [currentFarm, getAccessTokenSilently, actualYearId])

  // SamplingAreas
  useEffect(() => {
    async function getApiSamplingAreas(farm) {
      const token = await getAccessTokenSilently()
      const data = await ruutsApi.samplingAreas.getUncroppedByFarm({ farmId: farm.id, token })

      const apiSamplingAreas = data.map(samplingArea => {
        const newSamplingArea = samplingArea.toGeoJSON
        return newSamplingArea
      })
      handleSamplingAreas(apiSamplingAreas)
    }
    if (currentFarm) {
      getApiSamplingAreas(currentFarm)
    }
  }, [currentFarm, getAccessTokenSilently])

  // ExclusionAreas
  useEffect(() => {
    async function getApiExclusionAreas(farm) {
      const token = await getAccessTokenSilently()
      const data = await getExclusionAreas(farm, token)

      const apiExclusionAreas = data.map(exclusionArea => {
        const newExclusionArea = exclusionArea.toGeoJSON
        return newExclusionArea
      })
      handleExclusionAreas(apiExclusionAreas)
    }
    if (currentFarm) {
      getApiExclusionAreas(currentFarm)
    }
  }, [currentFarm, getAccessTokenSilently])

  // OtherPolygons
  useEffect(() => {
    async function getApiOtherPolygons(farm) {
      const token = await getAccessTokenSilently()
      const data = await getOtherPolygons(farm, token)

      const apiOtherPolygons = data.map(otherPolygon => {
        const newOtherPolygons = otherPolygon.toGeoJSON
        return newOtherPolygons
      })
      handleOtherPolygons(apiOtherPolygons)
    }
    if (currentFarm) {
      getApiOtherPolygons(currentFarm)
    }
  }, [currentFarm, getAccessTokenSilently])

  // MonitoringSites
  useEffect(() => {
    async function getApiMonitoringSites(farm) {
      const token = await getAccessTokenSilently()
      const data = await getMonitoringSites(farm.id, token)

      const apiMonitoringSites = data.map(monitoringSite => {
        const newMonitoringSites = {
          type: 'Feature',
          properties: {
            name: monitoringSite.name,
            id: monitoringSite.id,
            type: 'monitoringSite',
            featureGroup: 'monitoringSites',
          },
          geometry: {
            type: 'Point',
            coordinates: [monitoringSite.location[1], monitoringSite.location[0]], // location está en formato Lat,lng, hay que darlo vuelta para geoJSON
          },
        }
        return newMonitoringSites
      })
      handleMonitoringSites(apiMonitoringSites)
    }
    if (currentFarm) {
      getApiMonitoringSites(currentFarm)
    }
  }, [currentFarm, getAccessTokenSilently])

  // OtherSites
  useEffect(() => {
    async function getApiOtherSites(farm) {
      const token = await getAccessTokenSilently()
      const data = await getOtherSites(farm, token)

      const apiOtherSites = data.map(otherSite => {
        const newOtherSites = {
          type: 'Feature',
          properties: {
            name: otherSite.name,
            id: otherSite.id,
            color: otherSite.color,
            type: 'otherSite',
            featureGroup: 'otherSites',
          },
          geometry: {
            type: 'Point',
            coordinates: [otherSite.location[1], otherSite.location[0]], // location está en formato Lat,lng, hay que darlo vuelta para geoJSON
          },
        }
        return newOtherSites
      })
      handleOtherSites(apiOtherSites)
    }
    if (currentFarm) {
      getApiOtherSites(currentFarm)
    }
  }, [currentFarm, getAccessTokenSilently])

  // Center
  useEffect(() => {
    if (currentFarm?.lng && currentFarm?.lat) {
      const newCenter = [currentFarm?.lat, currentFarm?.lng]
      handleCenter(newCenter)
    }
  }, [currentFarm])

  useEffect(() => {
    async function checkExclusionAreaTypes() {
      const exclusionAreaTypesCached = await lfStore.getItem('exclusionAreaTypes')
      if (!exclusionAreaTypesCached) {
        const token = await getAccessTokenSilently()
        const exclusionAreaTypes = await getExclusionAreaTypes(token)
        lfStore.setItem('exclusionAreaTypes', exclusionAreaTypes)
      }
    }

    checkExclusionAreaTypes()
  }, [currentFarm, getAccessTokenSilently])

  // Map dimensions on resize
  function reportWindowSize() {
    setMapWidth((window.innerWidth / 3) * 2)
    setMapHeight(window.innerHeight - 100)
  }
  window.onresize = reportWindowSize

  return (
    <Grid container>
      <Grid item xs={4}>
        <CategorizationPanel
          actualYearId={actualYearId}
          center={center}
          etapa={etapa}
          exclusionAreas={exclusionAreas}
          fileType={fileType}
          handleActualYearId={handleActualYearId}
          handleCenter={handleCenter}
          handleDownload={handleDownload}
          handleDrawingType={handleDrawingType}
          handleEtapa={handleEtapa}
          handleExclusionAreas={handleExclusionAreas}
          handleFileType={handleFileType}
          handleIncludeProjectArea={handleIncludeProjectArea}
          handleIncludeSoilInfo={handleIncludeSoilInfo}
          handleMapUpdate={handleMapUpdate}
          handleMonitoringSites={handleMonitoringSites}
          handleOtherPolygons={handleOtherPolygons}
          handleOtherSites={handleOtherSites}
          handlePaddocks={handlePaddocks}
          handlePerimeter={handlePerimeter}
          handleSamplingAreas={handleSamplingAreas}
          handleSaved={handleSaved}
          handleUnassigned={handleUnassigned}
          handleUnassignedPoints={handleUnassignedPoints}
          includeProjectArea={includeProjectArea}
          includeSoilInfo={includeSoilInfo}
          mapUpdate={mapUpdate}
          monitoringSites={monitoringSites}
          otherPolygons={otherPolygons}
          otherSites={otherSites}
          paddocks={paddocks}
          perimeter={perimeter}
          samplingAreas={samplingAreas}
          saved={saved}
          unassigned={unassigned}
          unassignedPoints={unassignedPoints}
        />
      </Grid>
      <Grid item xs={8}>
        <MapLayered
          center={center}
          drawingType={drawingType}
          etapa={etapa}
          exclusionAreas={exclusionAreas}
          handleEtapa={handleEtapa}
          handleExclusionAreas={handleExclusionAreas}
          handleMapUpdate={handleMapUpdate}
          handleMonitoringSites={handleMonitoringSites}
          handleOtherPolygons={handleOtherPolygons}
          handleOtherSites={handleOtherSites}
          handlePaddocks={handlePaddocks}
          handlePerimeter={handlePerimeter}
          handleSamplingAreas={handleSamplingAreas}
          handleUnassigned={handleUnassigned}
          handleUnassignedPoints={handleUnassignedPoints}
          mapHeight={mapHeight}
          mapUpdate={mapUpdate}
          mapWidth={mapWidth}
          monitoringSites={monitoringSites}
          otherPolygons={otherPolygons}
          otherSites={otherSites}
          paddocks={paddocks}
          perimeter={perimeter}
          samplingAreas={samplingAreas}
          unassigned={unassigned}
          unassignedPoints={unassignedPoints}
        />
      </Grid>
    </Grid>
  )
}

export default FarmMapping
