/* eslint-disable react/no-array-index-key */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Divider,
  Typography,
} from '@mui/material'
import { Fragment } from 'react'
import AccordionHeader from './AccordionHeader'
import AreaRow from './AreaRow'

export const LayerPanel = ({
  title,
  color,
  elements,
  validation,
  saved,
  expandedPanels,
  categoryPanel,
  year,
  handleChange,
  handleLayerTypeChange,
  handleName,
  handleAddDependencies,
  handleRemoveFeature,
  handleRemoveDependencies,
  handleOtherName,
  handleHasGrazingManagement,
  handleExclusionAreaTypeChange,
  handleFeatureChange,
  handleMapUpdate,
  children,
}) => {
  const handleSelectFeature = newFeature => {
    const newFeatures = elements.map(feature => ({
      ...feature,
      properties: {
        ...feature.properties,
        selected:
          feature.properties.id === newFeature.properties.id
            ? !feature.properties.selected
            : feature.properties.selected,
      },
    }))

    handleFeatureChange(newFeatures)
    handleMapUpdate()
  }

  return (
    <>
      {elements?.length > 0 && (
        <Accordion
          expanded={expandedPanels[categoryPanel.panelName] || validation !== null}
          onChange={handleChange(categoryPanel)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id={`${categoryPanel.type}-header`}
            sx={{
              '& .MuiAccordionSummary-content': {
                margin: 0,
              },
            }}
          >
            <AccordionHeader
              color={color}
              elements={elements}
              title={title}
              warning={validation?.message}
            />
          </AccordionSummary>
          <AccordionDetails sx={{ p: 1 }}>
            {validation?.message && (
              <Alert severity="error" variant="filled">
                <Typography
                  sx={{
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                >
                  {validation.message}
                </Typography>
              </Alert>
            )}
            {children}
            {elements.map((area, index) => {
              return (
                <Fragment key={index}>
                  <AreaRow
                    error={validation?.errorsIndexes?.includes(index)}
                    feature={area}
                    handleAddDependencies={handleAddDependencies}
                    handleExclusionAreaTypeChange={handleExclusionAreaTypeChange}
                    handleFeature={handleLayerTypeChange}
                    handleHasGrazingManagement={handleHasGrazingManagement}
                    handleName={handleName}
                    handleOtherName={handleOtherName}
                    handleRemoveDependencies={handleRemoveDependencies}
                    handleRemoveFeature={handleRemoveFeature}
                    handleSelectFeature={handleSelectFeature}
                    saved={saved}
                    type={categoryPanel.type}
                    year={year}
                  />
                  {index + 1 < elements.length && <Divider />}
                </Fragment>
              )
            })}
          </AccordionDetails>
        </Accordion>
      )}
    </>
  )
}
