import {
  Box,
  FormControl,
  FormHelperText,
  OutlinedInput,
  InputLabel,
  Typography,
  Grid,
  Stack,
} from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import validateInputStringCoordinates from '../../services/geoLocations/validateInputStringCoordinates'

const CoordinatesInput = ({ readOnly, selectedConfirmationSite }) => {
  const { control, setValue, getValues } = useFormContext()

  const validateLatitude = lat => {
    const numValue = parseFloat(lat)
    if (Number.isNaN(numValue)) {
      return false
    }
    if (numValue < -90 || numValue > 90) {
      return false
    }
    return true
  }

  const validateLongitude = lng => {
    if (!lng) {
      return false
    }
    const numValue = parseFloat(lng)
    if (Number.isNaN(numValue)) {
      return false
    }
    if (numValue < -180 || numValue > 180) {
      return false
    }
    return true
  }

  const validateDecimals = value => {
    if (!value || !value.toString().includes('.')) {
      return false
    }
    const decimals = value.toString().split('.')[1]
    return decimals && decimals.length >= 9
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.75 }}>
      <Grid container spacing={1}>
        <Grid item sm={6} xs={12}>
          <Controller
            valueAsNumber
            control={control}
            defaultValue={
              selectedConfirmationSite?.id === 'manual' ? '' : selectedConfirmationSite?.lat || ''
            }
            name="lat"
            render={({ field, fieldState }) => (
              <FormControl
                fullWidth
                color={fieldState.error ? 'error' : 'primary'}
                error={!!fieldState.error}
                size="small"
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-coords">Latitud</InputLabel>
                <OutlinedInput
                  {...field}
                  error={!!fieldState.error}
                  inputProps={{
                    pattern: '-?[0-9]*[.]?[0-9]*',
                  }}
                  label="Latitud"
                  placeholder="Latitud"
                  readOnly={readOnly}
                  type="text"
                  onBlur={event => {
                    if (event.target.value) {
                      const numValue = parseFloat(event.target.value)
                      field.onChange(event.target.value)
                      setValue('actualLocation', [numValue, getValues('actualLocation')[1]], {
                        shouldValidate: true,
                      })
                    } else {
                      field.onChange('')
                    }
                  }}
                />
                {fieldState.error && (
                  <FormHelperText color={fieldState.error ? 'error' : 'inherit'}>
                    {fieldState.error?.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
            rules={{
              required: 'Campo requerido',
              valueAsNumber: true,
              validate: {
                latitude: value => validateLatitude(value) || 'Latitud inválida',
                decimals: value => validateDecimals(value) || 'Al menos 9 decimales',
              },
            }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            defaultValue={
              selectedConfirmationSite?.id === 'manual' ? '' : selectedConfirmationSite?.lng || ''
            }
            name="lng"
            render={({ field, fieldState }) => (
              <FormControl fullWidth error={!!fieldState.error} size="small" variant="outlined">
                <InputLabel htmlFor="outlined-coords">Longitud</InputLabel>
                <OutlinedInput
                  {...field}
                  error={!!fieldState.error}
                  inputProps={{
                    pattern: '-?[0-9]*[.]?[0-9]*',
                  }}
                  label="Longitud"
                  placeholder="Longitud"
                  readOnly={readOnly}
                  type="text"
                  onBlur={event => {
                    if (event.target.value) {
                      const numValue = parseFloat(event.target.value)
                      field.onChange(event.target.value)
                      setValue('actualLocation', [getValues('actualLocation')[0], numValue], {
                        shouldValidate: true,
                      })
                    } else {
                      field.onChange('')
                    }
                  }}
                />
                {fieldState.error && <FormHelperText>{fieldState.error?.message}</FormHelperText>}
              </FormControl>
            )}
            rules={{
              required: 'Campo requerido',
              valueAsNumber: true,
              validate: {
                longitude: value => validateLongitude(value) || 'Longitud inválida',
                decimals: value => validateDecimals(value) || 'Al menos 9 decimales',
              },
            }}
          />
        </Grid>
      </Grid>
      <Controller
        control={control}
        name="actualLocation"
        render={({ field, fieldState }) => (
          <Stack direction="row" display="none" gap={0.75} justifyContent="center">
            <Typography color={fieldState.error ? 'error' : 'inherit'} variant="bodySmall">
              {Array.isArray(field.value) && field.value.length > 0 ? field.value.join(', ') : ''}
            </Typography>
          </Stack>
        )}
        rules={{
          required: 'Requerido',
          validate: value => validateInputStringCoordinates(value) || 'Coordenadas inválidas',
        }}
      />
    </Box>
  )
}

export default CoordinatesInput
