import refDataById from './refDataById'
import refDataByKey from './refDataByKey'

export const referenceDataKeys = {
  exclusionAreaTypes: 'exclusionAreaTypes',
  activities: 'activities',
  workflows: 'workflows',
  fieldUsage: 'RefFieldUsage',
  livestockRaisingType: 'RefLivestockRaisingType',
  forageSource: 'RefForageSource',
  pasturesGrowthType: 'RefPasturesGrowthType',
  pasturesType: 'RefPasturesType',
  pasturesFamily: 'RefPasturesFamily',
  grazingType: 'RefGrazingType',
  grazingPlanType: 'RefGrazingPlanType',
  fuelType: 'RefFuelType',
  findingType: 'RefFindingType',
  cattleClass: 'RefCattleClass',
  cattleSubClass: 'RefCattleSubClass',
  cattleType: 'RefCattleType',
  bovineCattle: 'RefBovineCattle',
  ovineCattle: 'RefOvineCattle',
  equineCattle: 'RefEquineCattle',
  bubalineCattle: 'RefBubalineCattle',
  caprineCattle: 'RefCaprineCattle',
  cervidCattle: 'RefCervidCattle',
  camelidCattle: 'RefCamelidCattle',
  organicFertilizerType: 'RefOrganicFertilizerType',
  mineralFertilizerType: 'RefMineralFertilizerType',
  ammendmendType: 'RefAmmendmendType',
  tillageType: 'RefTillageType',
  irrigationType: 'RefIrrigationType',
  cropType: 'RefCropType',
  dataCollectionStatementStatus: 'RefDataCollectionStatementStatus',
  grazingIntensityType: 'RefGrazingIntensityType',
  degreeDegradationSoil: 'RefDegreeDegradationSoil',
  documentType: 'RefDocumentType',
  metricEventStatus: 'RefMetricEventStatus',
  locationMovedReason: 'RefLocationMovedReason',
  locationConfirmationType: 'RefLocationConfirmationType',
  fieldRelocationMethod: 'RefFieldRelocationMethod',
  soilTexture: 'RefSoilTextures',
  dapMethod: 'RefDAPMethod',
  samplingNumber: 'RefSamplingNumber',
}

export const localRefData = {
  keys: referenceDataKeys,
  getDataById: refDataById,
  getDataByKey: refDataByKey,
}
