import React from 'react'
import { Box, Typography } from '@mui/material'
import ActivitySummaryTable from './ActivitySummaryTable'

const ConfirmationStep = ({ eventData, setEventData }) => {
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6">Revisión de actividades</Typography>
      <ActivitySummaryTable eventData={eventData} markerRefs={null} setEventData={setEventData} />
    </Box>
  )
}

export default ConfirmationStep
