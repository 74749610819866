import { ruutsApi } from '../../../services/ruutsApi/index'

export async function getSelectedPaddocks(values, token) {
  if (!values?.length) {
    return []
  }

  const paddocksFromDb = await ruutsApi.paddocks.getPaddockById({ id: values, token })
  return paddocksFromDb
    .map(p => ({ id: p.id, label: p.name }))
    .sort((a, b) => a.label.localeCompare(b.label)) // sort alphabetically
}

export async function getSelectedRodeoPaddocks(values, token) {
  const paddockOutCircuit = { id: 'out-circuit-paddock-id', label: 'Fuera de circuito' }
  const paddocksDivider = { divider: true, label: 'Seleccionados en mapa' }
  const selectedPaddock = await getSelectedPaddocks(values, token)

  return [{ ...paddockOutCircuit }, { ...paddocksDivider }, ...selectedPaddock]
}
