import { Box, Checkbox, FormControlLabel, FormGroup, FormLabel, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import lfStore from '../../../lfstore/lfStore'
import refDataByKey from '../../../services/localRefData/refDataByKey'

const CheckBoxGroupWrapper = ({ control, formField, rules, groupIndex, readOnly }) => {
  const [options, setOptions] = useState()

  useEffect(() => {
    const getFieldOptions = async field => {
      const newOptions = await refDataByKey(field.refDataKey).catch(e => console.error(e))
      setOptions(newOptions)
    }
    if (formField.defaultOptions && formField.defaultOptions.length)
      setOptions(formField.defaultOptions)
    else getFieldOptions(formField)
  }, [formField])

  return (
    options && (
      <Box sx={{ display: 'flex', flexGrow: 0, flexDirection: 'column' }}>
        <FormLabel id={formField.name}>
          <Typography variant="label">{formField.placeholder}</Typography>
        </FormLabel>
        <Controller
          control={control}
          defaultValue={[0]}
          name={`${formField.name}${groupIndex ? `-${groupIndex}` : ''}`}
          render={({ field }) => (
            <FormGroup row={formField.direction === 'row'}>
              {options.map(option => (
                <FormControlLabel
                  key={option.id}
                  control={
                    <Checkbox
                      checked={field.value.includes(option.id)}
                      onChange={(evt, value) => {
                        if (!readOnly) lfStore.setItem('dirtyForm', true)
                        if (!value.length) field.onChange([option.id])
                        if (value) {
                          const fieldValue = field.value
                          fieldValue.push(option.id)
                          field.onChange(fieldValue)
                        } else {
                          const fieldValue = field.value.filter(v => v !== option.id)
                          field.onChange(fieldValue)
                        }
                      }}
                    />
                  }
                  disabled={readOnly}
                  label={option.es_AR}
                />
              ))}
            </FormGroup>
          )}
          rules={rules}
        />
      </Box>
    )
  )
}

export default CheckBoxGroupWrapper
