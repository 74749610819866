/* eslint-disable react/jsx-pascal-case */
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import EditTwoTone from '@mui/icons-material/EditTwoTone'
import SearchIcon from '@mui/icons-material/Search'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Button, Stack, Typography } from '@mui/material'
import { MRT_TableInstance, type MRT_Row } from 'material-react-table'
import { FC, ReactNode, useMemo } from 'react'
import { formModes } from '../../utils/constants'
import { FormMode, MetricEventRow } from '../ExpandableDataTable/dataTableHelpers'

type CreateTableActionsProps = {
  table: MRT_TableInstance<MetricEventRow>
  formMode: FormMode
  handleSelect: (selected: MRT_Row<MetricEventRow>[]) => void
  handleDelete: (selected: MRT_Row<MetricEventRow>[]) => void
  title: string
}

type TableActionsProps = Omit<CreateTableActionsProps, 'table'> & {
  selectedRows: MRT_Row<MetricEventRow>[]
}

const TableActions: FC<TableActionsProps> = ({
  selectedRows,
  formMode,
  handleSelect,
  handleDelete,
  title,
}) => {
  const renderButtons = useMemo(() => {
    switch (formMode) {
      case formModes.View:
        return (
          <Button
            startIcon={<VisibilityIcon sx={{ color: 'blue' }} />}
            onClick={() => handleSelect(selectedRows)}
          >
            Ver
          </Button>
        )
      case formModes.Review:
        return (
          <Button
            startIcon={<SearchIcon sx={{ color: 'blue' }} />}
            onClick={() => handleSelect(selectedRows)}
          >
            Revisar
          </Button>
        )
      case formModes.Edit:
        return (
          <>
            <Button
              startIcon={<EditTwoTone sx={{ color: 'blue' }} />}
              onClick={() => handleSelect(selectedRows)}
            >
              Editar
            </Button>
            <Button
              startIcon={<DeleteTwoTone sx={{ color: 'red' }} />}
              onClick={() => handleDelete(selectedRows)}
            >
              Borrar
            </Button>
          </>
        )
      default: // No buttons
        return null
    }
  }, [formMode, handleSelect, handleDelete, selectedRows])

  return (
    <Stack
      key="data-table-dc-actions"
      alignItems="center"
      direction="row"
      display="flex"
      flexGrow={1}
      justifyContent="space-between"
    >
      <Typography variant="h6">{title}</Typography>

      <Stack alignItems="center" direction="row" display="flex" justifyContent="flex-end">
        {selectedRows.length > 0 && renderButtons}
      </Stack>
    </Stack>
  )
}

export const createTableActions = ({
  table,
  formMode,
  handleSelect,
  handleDelete,
  title,
}: CreateTableActionsProps): ReactNode => {
  return (
    <TableActions
      formMode={formMode}
      handleDelete={handleDelete}
      handleSelect={handleSelect}
      selectedRows={table.getSelectedRowModel().rows}
      title={title}
    />
  )
}
