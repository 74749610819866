import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import { useCustomSnackbarError } from '../../utils/Snackbar/useCustomSnackbarError'
import { ruutsApi } from '../../services/ruutsApi'

export const useMonitoringSites = ({ farmId }) => {
  const [monitoringSites, setMonitoringSites] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const { user, getAccessTokenSilently } = useAuth0()
  const { handleError } = useCustomSnackbarError()

  useEffect(() => {
    async function loadMonitoringSites() {
      try {
        setIsLoading(true)
        const token = await getAccessTokenSilently()
        const result = await ruutsApi.monitoringSites.getByFarm(farmId, token)

        if (result) {
          setMonitoringSites(result)
        }
      } catch (error) {
        handleError(error)
      } finally {
        setIsLoading(false)
      }
    }

    if (farmId) {
      loadMonitoringSites()
    }
  }, [getAccessTokenSilently, handleError, farmId, user])

  return { monitoringSites, isLoading }
}
