import { dataCollectionStatementService } from '../farmMapping/datacollectionStatement'
import { documents } from '../farmMapping/documents'
import getFarms, { getFarm, getFarmsNameList } from '../farmMapping/getFarms'
import { monitoringSites } from '../farmMapping/monitoringSites'
import { samplingAreas } from '../farmMapping/samplingAreas/index'
import { socSamples } from '../farmMapping/socSamples/index'
import { paddocks } from '../farmMapping/savePaddocks'
import { addMonitoringEvent } from './addMonitoringEvent'
import compareGpsData from './compareGpsData'
import deleteApiData from './deleteApiData'
import deleteMetric from './deleteMetric'
import endpoints from './endpoints'
import saveFinding from './findings/saveFinding'
import getDataCollectionFormDefinitions from './formDefinitions/getFormDefinitions'
import getApiData from './getApiData'
import { getApiFile } from './getApiFile'
import getFormDefs from './getFormDefs'
import getMetricEvents from './getMetricEvents'
import getProgram from './getProgram'
import getProgramConfig from './getProgramConfig'
import { getRawDataCollection } from './getRawDataCollection'
import getSoilsIntersections from './getSoils'
import { metricsProviderService } from './metricsProvider'
import postApiData, { postApiDataWithPayloadResponse } from './postApiData'
import saveMetrics, { saveMetricEventStatus, saveVerificationMetric } from './saveMetrics'
import updateApiData from './updateApiData'
import { monitoring } from '../farmMapping/monitoring/index'

export const ruutsApi = {
  getApiData,
  updateApiData,
  getFormDefs,
  postApiData,
  postApiDataWithPayloadResponse,
  getMetricEvents,
  getDataCollectionFormDefinitions,
  dataCollectionStatementService,
  deleteApiData,
  saveMetrics,
  saveVerificationMetric,
  saveMetricEventStatus,
  deleteMetric,
  getProgramConfig,
  getProgram,
  getSoilsIntersections,
  saveFinding,
  getFarms,
  getFarm,
  getFarmsNameList,
  paddocks,
  documents,
  samplingAreas,
  socSamples,
  monitoring,
  monitoringSites,
  compareGpsData,
  endpoints,
  addMonitoringEvent,
  getRawDataCollection,
  metricsProviderService,
  getApiFile,
}
