import EditIcon from '@mui/icons-material/Edit'
import ButtonMui from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import { useContext } from 'react'
import AppContext from '../../context/appContext'
import { openSupportForm } from '../../utils/Errors/errors'
import { messages } from '../../utils/messages/index'
import { helpColor } from '../GlobalConfirmationModal/GlobalConfirmationModal'

const fullWidth = {
  width: '100%',
}

const BaseButton = ({ children, ...buttonProps }) => {
  return (
    <ButtonMui color="primary" sx={{ width: '100%' }} variant="contained" {...buttonProps}>
      {children}
    </ButtonMui>
  )
}

export const EditionButton = ({ showTooltip, tooltipTitle, children, ...buttonProps }) => {
  return showTooltip ? (
    <Tooltip title={tooltipTitle}>
      <div style={fullWidth}>
        {/* This div is needed because a child of a Material-UI Tooltip must be able to hold a ref. 
        Otherwise the tooltip is not showed */}
        <BaseButton endIcon={<EditIcon />} variant="outlined" {...buttonProps}>
          {children}
        </BaseButton>
      </div>
    </Tooltip>
  ) : (
    <BaseButton endIcon={<EditIcon />} variant="outlined" {...buttonProps}>
      {children}
    </BaseButton>
  )
}

export const SupportButton = ({ contactReasonSupportType, reason, children, ...buttonProps }) => {
  const { user, currentFarm } = useContext(AppContext)

  return (
    <BaseButton
      sx={{
        backgroundColor: helpColor,
        color: 'white',
        margin: 2,
        px: 2,
        fontWeight: 600,
        opacity: 0.6,
        textTransform: 'none',
        '&:hover': {
          backgroundColor: helpColor,
          color: 'white',
          opacity: 1,
        },
      }}
      variant="outlined"
      onClick={() => {
        openSupportForm({
          user,
          farm: currentFarm,
          title: contactReasonSupportType.title,
          reason,
          error: null,
        })
      }}
      {...buttonProps}
    >
      {children}
      {messages.getMessage(messages.keys.FILL_FORM_SUPPORT_CONTACT_BUTTON)}
    </BaseButton>
  )
}

export const Button = ({ showTooltip, tooltipTitle, children, ...buttonProps }) => {
  return showTooltip ? (
    <Tooltip title={tooltipTitle}>
      <div>
        {/* This div is needed because a child of a Material-UI Tooltip must be able to hold a ref. 
        Otherwise the tooltip is not showed */}
        <BaseButton variant="contained" {...buttonProps}>
          {children}
        </BaseButton>
      </div>
    </Tooltip>
  ) : (
    <BaseButton variant="contained" {...buttonProps}>
      {children}
    </BaseButton>
  )
}
