import { type MRT_Row, type MRT_TableOptions } from 'material-react-table'
import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import lfStore from '../../lfstore/lfStore'
import { FarmSubdivision, FormDefinition, MetricEvent } from '../../types/apiModels'
import { formModes } from '../../utils/constants'
import { useCustomSnackbarError } from '../../utils/Snackbar/useCustomSnackbarError'
import {
  ColumnOption,
  createColumns,
  FormMode,
  MetricEventRow,
  processRecordData,
} from '../ExpandableDataTable/dataTableHelpers'
import { ExpandableDataTable } from '../ExpandableDataTable/ExpandableDataTable'
import { createTableActions } from './TableActions'

type DataTableProps = {
  metricEvents: MetricEvent[]
  formDefinition: FormDefinition
  farmSubdivision: FarmSubdivision
  formMode: FormMode
  handleDelete: (selected: MRT_Row<MetricEventRow>[]) => void
  handleMetricReset: () => void
  handleSelect: (selected: MRT_Row<MetricEventRow>[]) => void
}

export const DataTable: FC<DataTableProps> = ({
  farmSubdivision,
  formDefinition,
  formMode,
  handleDelete,
  handleMetricReset,
  handleSelect,
  metricEvents,
}) => {
  const [columns, setColumns] = useState<ColumnOption[] | null>(null)
  const [rows, setRows] = useState<MetricEventRow[]>([])
  const [globalFilter, setGlobalFilter] = useState('')
  const { handleError } = useCustomSnackbarError()

  const customOptions: Partial<MRT_TableOptions<MetricEventRow>> = useMemo(
    () => ({
      enableBatchRowSelection: false,
      enableFullScreenToggle: true,
      enableGlobalFilter: true,
      enableRowNumbers: true,
      enableRowSelection: formMode !== formModes.Disable,
      enableSelectAll: false,
      enableTopToolbar: true,
      getRowId: row => String(row.id),
      positionToolbarAlertBanner: 'none',
      rowNumberDisplayMode: 'original',
      state: {
        globalFilter,
      },
      onGlobalFilterChange: setGlobalFilter,
      renderTopToolbarCustomActions: ({ table }): ReactNode =>
        createTableActions({
          table,
          formMode,
          handleSelect,
          handleDelete,
          title: formDefinition.label,
        }),
    }),
    [formDefinition.label, formMode, handleDelete, handleSelect, globalFilter],
  )

  const columnNames = useMemo(
    () => [
      'isDraft',
      'metricYear',
      'paddocks',
      'isVerified',
      'findings',
      'notApplicable',
      'metricStatusId',
    ],
    [],
  )

  useEffect(() => {
    if (!formDefinition || !farmSubdivision) {
      setColumns(null)
      setRows([])
      return (): void => {}
    }

    let isMounted = true

    const processData = async (): Promise<void> => {
      try {
        const refDataKeys = await lfStore.keys()

        const [newColumns, newRows] = await Promise.all([
          createColumns(formDefinition, columnNames, [
            { name: 'paddocks', options: { sort: true } },
          ]),
          Promise.all(
            metricEvents.map(record =>
              processRecordData({
                record,
                formDefinition,
                refDataKeys,
                farmSubdivision,
                formMode,
                handleMetricReset,
              }),
            ),
          ) || [],
        ])

        if (isMounted) {
          setColumns(newColumns)
          setRows(newRows)
        }
      } catch (error) {
        if (error instanceof Error && isMounted) {
          handleError(error.message, error)
        }
      }
    }

    processData()

    return (): void => {
      isMounted = false
    }
  }, [
    formDefinition,
    metricEvents,
    farmSubdivision,
    formMode,
    handleMetricReset,
    handleError,
    columnNames,
  ])

  if (!columns || !rows) return null

  return (
    <ExpandableDataTable
      columns={columns}
      customOptions={customOptions}
      data={rows}
      formDefinition={formDefinition}
    />
  )
}
