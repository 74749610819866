/* eslint-disable no-continue */
import * as turf from '@turf/turf'
import seedrandom from 'seedrandom'

export type Strata = GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon>

export interface PointProperties {
  name?: string
  strata?: string
  seed?: string
  randomIteration?: number
  timestamp?: string
}

export type PointFeature = GeoJSON.Feature<GeoJSON.Point, PointProperties>

interface Polygon {
  feature: GeoJSON.Feature<GeoJSON.Polygon>
  area: number
  index: number
}

class RNGCounter {
  count: number

  constructor() {
    this.count = 0
  }

  increment(): void {
    this.count++
  }

  get(): number {
    return this.count
  }

  reset(): void {
    this.count = 0
  }
}

/**
 * Crea y devuelve una función generadora de números pseudoaleatorios usando seedrandom,
 * basándose en el id de la strata.
 */
const generateRNG = (strata: Strata): (() => number) => {
  return seedrandom(`${strata.properties.name}`)
}

/**
 * Separa una strata en polígonos (o subpolígonos) válidos, aplicando criterios mínimos de área
 * y porcentaje de la strata total.
 */
const splitStrataIntoPolygons = (strata: Strata): Polygon[] => {
  const strataArea = turf.area(strata)
  if (strataArea === 0) {
    return []
  }
  const minimumPercentageOfStrata = 0
  const minimumAreaOfStrata = 0

  const stratasSplitIntoPolygons: Polygon[] = []

  if (strata.geometry.type.toLowerCase() === 'polygon') {
    const polygon: Polygon = {
      feature: strata as GeoJSON.Feature<GeoJSON.Polygon>,
      area: turf.area(strata),
      index: 0,
    }
    stratasSplitIntoPolygons.push(polygon)
  }

  if (strata.geometry.type.toLowerCase() === 'multipolygon') {
    strata.geometry.coordinates.forEach((coordinates, index) => {
      const polygon: Polygon = {
        feature: turf.polygon(coordinates),
        area: turf.area(turf.polygon(coordinates)),
        index,
      }
      const percentageOfStrata = (polygon.area / strataArea) * 100

      if (percentageOfStrata >= minimumPercentageOfStrata && polygon.area >= minimumAreaOfStrata) {
        stratasSplitIntoPolygons.push(polygon)
      }
    })
  }

  return stratasSplitIntoPolygons
}

const randomize = (
  samples: number,
  strata: Strata,
  stratasSplitIntoPolygons: Polygon[],
  points: PointFeature[],
  rng: () => number,
  auditReport: any,
  index?: number,
): PointFeature[] => {
  if (!stratasSplitIntoPolygons?.length) {
    return points
  }

  const iterationCounter = new RNGCounter()

  while (points.length < samples) {
    iterationCounter.increment()
    const currentIteration = iterationCounter.get()

    // Selección del subpolígono basado en un valor aleatorio
    const randomValueForSubpolygon = rng()
    const subpolygonStrataIndex =
      stratasSplitIntoPolygons.length > 1
        ? Math.floor(randomValueForSubpolygon * stratasSplitIntoPolygons.length)
        : 0
    const subpolygonStrata = stratasSplitIntoPolygons[subpolygonStrataIndex].feature

    const [minLng, minLat, maxLng, maxLat] = turf.bbox(subpolygonStrata)

    // Generar coordenadas aleatorias dentro del bounding box
    const randomValueForLat = rng()
    const randomValueForLon = rng()
    const lat = minLat + randomValueForLat * (maxLat - minLat)
    const lng = minLng + randomValueForLon * (maxLng - minLng)
    const point = turf.point([lng, lat])

    // Preparar un evento para el informe de auditoría con toda la metadata
    const event = {
      timestamp: new Date().toISOString(),
      seed: strata.properties.name,
      randomValues: {
        subpolygon: randomValueForSubpolygon,
        lat: randomValueForLat,
        lon: randomValueForLon,
      },
      chosenSubpolygonIndex: subpolygonStrataIndex,
      bbox: { minLng, minLat, maxLng, maxLat },
      generatedPoint: [lng, lat],
      outcome: '',
      details: '',
      randomizeCounter: currentIteration,
    }

    // Verificar si el punto cae dentro del polígono usando rejection sampling
    if (!turf.booleanPointInPolygon(point, subpolygonStrata)) {
      event.outcome = 'rejected'
      event.details = 'Point out of bounds'
      auditReport.events.push(event)
      continue
    } else {
      event.outcome = 'accepted'
      event.details = 'Point within polygon'
      auditReport.events.push(event)
    }

    point.properties = {
      seed: strata.properties.name,
      timestamp: event.timestamp,
      name: `${strata.properties.name}-${points.length}`,
      strata: strata.properties.name,
      randomizeCounter: currentIteration,
    }

    if (index === null || index === undefined) {
      points.push(point)
    } else {
      points.splice(index, 0, point)
    }
  }
  return points
}

export default function randomPointsInPolygonV3(
  samples: number,
  strata: Strata,
  points: PointFeature[],
  index?: number,
): PointFeature[] {
  let generatedPoints = 0
  let randomPoints: PointFeature[] = points
  const stratasSplitIntoPolygons: Polygon[] = splitStrataIntoPolygons(strata)

  const rng = generateRNG(strata)

  const auditReport: {
    seed: string
    strataName: string
    startTime: string
    events: any[]
    endTime: string
    totalPointsGenerated: number
  } = {
    seed: strata.properties.name,
    strataName: strata.properties.name,
    startTime: new Date().toISOString(),
    events: [],
    endTime: '',
    totalPointsGenerated: 0,
  }

  console.log('auditReport', auditReport) // TODO: Eliminar en versiones estables

  randomPoints = randomize(
    samples,
    strata,
    stratasSplitIntoPolygons,
    randomPoints,
    rng,
    auditReport,
    index,
  )

  generatedPoints = randomPoints.length

  auditReport.endTime = new Date().toISOString()
  auditReport.totalPointsGenerated = generatedPoints
  ;(randomPoints as PointFeature[] & { auditReport?: any }).auditReport = auditReport

  return randomPoints
}
