import { Close } from '@mui/icons-material'
import { Alert, Box, Button, Grid, Modal, Snackbar, Stack, Typography } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import DynamicFieldSwitcher from '../DynamicForm/DynamicFieldSwitcher'
import { checkDependencies, parseErrors } from '../DynamicForm/utils'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  bgcolor: 'background.paper',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
}

export const FormDefinitionDialog = ({
  title,
  readOnly,
  year,
  formDefinition,
  open,
  saveFormData,
  handleClose,
  data,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    setError,
    clearErrors,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
    shouldUseNativeValidation: false,
    defaultValues: data,
  })

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Grid container>
          {title && (
            <Grid item sx={{ textAlign: 'left' }} xs={11}>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                }}
              >
                {title}
              </Typography>
            </Grid>
          )}

          <Grid item xs={1}>
            <Close
              sx={{
                float: 'right',
                margin: 1,
                cursor: 'pointer',
                '&:hover': {
                  color: 'red',
                },
              }}
              onClick={handleClose}
            />
          </Grid>

          <Grid item sx={{ textAlign: 'left' }} xs={12}>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
              }}
            >
              {formDefinition.label}
            </Typography>
          </Grid>

          <Grid item sx={{ maxHeight: '80vh', overflowY: 'scroll' }} xs={12}>
            <FormProvider {...{ watch, control, reset, setError }}>
              <Box
                component="form"
                pb={2}
                pl={2}
                pr={2}
                pt={1}
                onSubmit={handleSubmit(saveFormData)}
              >
                {!watch('notApplicable') && (
                  <fieldset style={{ color: 'inherit' }}>
                    <Grid container spacing={1}>
                      {formDefinition.fields.map((formField, index) => (
                        <DynamicFieldSwitcher
                          // eslint-disable-next-line react/no-array-index-key
                          key={index}
                          checkDependencies={checkDependencies}
                          control={control}
                          formField={formField}
                          readOnly={readOnly}
                          year={year}
                        />
                      ))}
                    </Grid>
                  </fieldset>
                )}
              </Box>
              <Stack direction="column" display="flex" position="sticky" spacing={1}>
                {errors && Object.keys(errors).length > 0 && (
                  <Snackbar
                    key="error-snackbar"
                    autoHideDuration={5000}
                    open={!!errors}
                    onClose={() => clearErrors()}
                  >
                    <Alert
                      severity="error"
                      onClose={() => {
                        clearErrors()
                      }}
                    >
                      {parseErrors(errors)[0]}
                    </Alert>
                  </Snackbar>
                )}
              </Stack>

              <Stack direction="column" display="flex" position="sticky" spacing={1}>
                <Button
                  disabled={readOnly}
                  size="small"
                  variant="contained"
                  onClick={handleSubmit(saveFormData)}
                >
                  GUARDAR
                </Button>
              </Stack>
            </FormProvider>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}
