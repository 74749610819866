import { useToCanvas } from '@hugocxl/react-to-image'
import { Box, Card, CardContent, Stack, Typography } from '@mui/material'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { cloneElement, useEffect, useState } from 'react'
import { FeatureGroup, GeoJSON, LayerGroup, MapContainer, TileLayer } from 'react-leaflet'
import { pickChildrenByType } from '../../../utils/childrenPickers'
import { getRandomColor } from '../../../utils/mapDrawHandle'
import { useCustomSnackbarError } from '../../../utils/Snackbar/useCustomSnackbarError'

const getColor = feature => {
  if (feature?.properties?.type === 'perimeter') {
    return 'black'
  }

  return feature?.properties?.color ?? getRandomColor()
}

const Legend = ({ layers, direction = 'row', maxWidth = '500px' }) => (
  <Card elevation={2} sx={{ maxWidth, backgroundColor: 'white' }}>
    <CardContent sx={{ backgroundColor: 'white' }}>
      <Stack direction={direction} spacing={1} sx={{ flexWrap: 'wrap' }}>
        {layers.flat().map(layer => {
          const { properties } = layer
          const layerType = {
            strata: 'Estrato',
            exclusion: 'Áreas de exclusión',
            site: 'Sitio de monitoreo',
            perimeter: 'Perímetro',
            paddock: 'Lote',
            default: 'Otro',
          }

          const typeLabel = layerType[properties.type] || layerType.default

          return (
            <Box
              key={`${properties.type}-${properties.name}`}
              sx={{ display: 'flex', gap: '4px', flexDirection: 'row', alignItems: 'center' }}
            >
              <Box
                sx={{
                  backgroundColor: getColor(layer),
                  width: '15px',
                  height: '15px',
                  minWidth: '15px',
                }}
              />
              <Typography variant="subtitle1">
                <b>{typeLabel}</b>: {properties.name}
              </Typography>
            </Box>
          )
        })}
      </Stack>
    </CardContent>
  </Card>
)

const Layer = ({ layer, layerStyles = {} }) => {
  return (
    <FeatureGroup>
      <LayerGroup>
        <GeoJSON
          data={layer}
          style={feature => {
            return {
              color: getColor(feature),
              weight: 1,
              opacity: 1,
              fillOpacity: 0.2,
              ...layerStyles,
            }
          }}
          onEachFeature={(feature, geoLayer) => {
            const popupContent = Object.entries(feature.properties)
              .map(([key, value]) => {
                return `
              <div style="display: flex; align-items: center; height: 15px">
                <p style="font-size: 11px; font-weight: bold">${key}:</p>
                <p style="font-size: 11px;">&nbsp;${value} </p>
              </div>`
              })
              .join('')
            geoLayer.bindPopup(popupContent)
          }}
        />
      </LayerGroup>
    </FeatureGroup>
  )
}

const DEFAULT_CENTER = [-35.329970415341656, -65.12262446073629]

export const Map = ({
  perimeter,
  mapWidth,
  mapHeight,
  flexDirection = 'row',
  scrollWheelZoom = false,
  isImage = false,
  children,
}) => {
  const [map, setMap] = useState(null)
  const { handleError } = useCustomSnackbarError()
  const [baseTileLoaded, setBaseTileLoaded] = useState(false)
  const [imageState, convert, ref] = useToCanvas()
  const legendDirection = flexDirection === 'column' ? 'row' : 'column'

  useEffect(() => {
    if (isImage && map && baseTileLoaded) {
      convert()
    }
  }, [isImage, map, baseTileLoaded])

  useEffect(() => {
    try {
      if (map && perimeter?.geometry?.coordinates?.length > 0) {
        const bounds = L.geoJSON(perimeter).getBounds()
        const center = bounds.getCenter()
        const zoom = map.getBoundsZoom(bounds)
        map.setView(center, zoom)
      }
    } catch (error) {
      handleError(error)
    }
  }, [handleError, perimeter, map])

  if (!perimeter?.geometry?.coordinates?.length > 0) return null

  return (
    <Box sx={{ display: 'flex', gap: '16px', flexDirection }}>
      <div ref={ref} style={{ height: mapHeight, width: mapWidth }}>
        {imageState.data ? (
          <img
            alt="map"
            src={imageState.data.toDataURL('image/png')}
            style={{ height: mapHeight, width: mapWidth }}
          />
        ) : (
          <MapContainer
            ref={setMap}
            zoomControl
            center={DEFAULT_CENTER}
            scrollWheelZoom={scrollWheelZoom}
            style={{ height: mapHeight, width: mapWidth }}
            wheelPxPerZoomLevel={60}
            zoom={5}
            zoomDelta={1}
            zoomSnap={0.1}
          >
            <TileLayer
              eventHandlers={{
                load: () => {
                  setTimeout(() => {
                    setBaseTileLoaded(true)
                  }, 1000)
                },
              }}
              maxNativeZoom={19}
              maxZoom={22}
              subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
              url="http://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
            />
            <LayerGroup>
              <GeoJSON
                data={perimeter}
                style={_ => {
                  return {
                    color: getColor(perimeter),
                    weight: 3,
                    opacity: 1,
                    fillOpacity: 0,
                  }
                }}
              />
            </LayerGroup>
            {pickChildrenByType(children, Layer)}
          </MapContainer>
        )}
      </div>
      {pickChildrenByType(children, Legend).map(child =>
        cloneElement(child, {
          direction: legendDirection,
          maxWidth: mapWidth,
          layers: [...child.props.layers, perimeter],
        }),
      )}
    </Box>
  )
}

Map.Legend = Legend
Map.Layer = Layer
