import * as turf from '@turf/turf'
import { truncate, lessPrecision, minimumPrecisionAllowed, defaultOptions } from './truncate'

export function intersect(poly1, poly2, options = defaultOptions, depth = 0) {
  try {
    return turf.intersect(truncate(poly1, options), truncate(poly2, options))
  } catch (e) {
    console.error(
      {
        reason: `"Could not calculate intersection between polygons. Retrying depth ${depth} : ${e}`,
        poly1,
        poly2,
        options,
        depth,
      },
      e,
    )
    const newOptions = lessPrecision(options)
    if (newOptions.precision < minimumPrecisionAllowed || depth > 5) {
      console.error(
        {
          reason: `"Could not calculate intersection between polygons: ${e}`,
          poly1,
          poly2,
          options,
          depth,
        },
        e,
      )
      throw e
    }
    return intersect(poly1, poly2, newOptions, depth + 1)
  }
}
