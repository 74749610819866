import { useAuth0 } from '@auth0/auth0-react'
import {
  Audiotrack,
  Description,
  InsertDriveFile,
  InsertPhoto,
  PictureAsPdf,
  Theaters,
  CloudUpload,
  Close,
} from '@mui/icons-material'
import { Box, Chip, CircularProgress, Grid, Paper, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import ReactMarkdown from 'react-markdown'
import AppContext from '../../context/appContext'
import uploadFiles from '../../services/objectStore/uploadFiles'
import { customSnackbarError } from '../../utils/Snackbar/Snackbar'
import './FileInput.css'

const maxFilesLimit = import.meta.env.VITE_FILE_INPUT_MAX_FILES_LIMIT
  ? Number(import.meta.env.VITE_FILE_INPUT_MAX_FILES_LIMIT)
  : 30
const maxFileSizeLimit = import.meta.env.VITE_FILE_INPUT_MAX_FILE_SIZE_LIMIT
  ? Number(import.meta.env.VITE_FILE_INPUT_MAX_FILE_SIZE_LIMIT)
  : 10000000

const FileInput = ({ definition, year, onAdd, onDelete, files, readOnly }) => {
  const [fileObjects, setFileObjects] = useState([])
  const [isUploading, setIsUploading] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { getAccessTokenSilently } = useAuth0()
  const { currentFarm, setLoadingModalConfig } = useContext(AppContext)

  const getFileIcon = fileType => {
    const iconProps = {
      sx: { fontSize: 24, mr: 1 },
    }

    if (fileType.startsWith('video/')) return <Theaters {...iconProps} />
    if (fileType.startsWith('audio/')) return <Audiotrack {...iconProps} />

    switch (fileType) {
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return <Description {...iconProps} />
      case 'application/pdf':
        return <PictureAsPdf {...iconProps} />
      case 'image/jpeg':
      case 'image/jpg':
      case 'image/png':
        return <InsertPhoto {...iconProps} />
      default:
        return <InsertDriveFile {...iconProps} />
    }
  }

  const onDrop = useCallback(
    acceptedFiles => {
      if (readOnly) return

      const newFileObjects = acceptedFiles.map(file => ({
        file,
        data: URL.createObjectURL(file),
        id: `${file.name}-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
      }))

      async function uploadFile() {
        try {
          setLoadingModalConfig({
            open: true,
            title: 'Subiendo archivo...',
          })
          setIsUploading(true)
          const token = await getAccessTokenSilently()
          const directoryName = `farms/${currentFarm.id}/${year}/${definition.name}`
          const fileLinks = await uploadFiles(directoryName, newFileObjects, token)

          const uploadedFiles = newFileObjects.map((fileObj, index) => ({
            ...fileObj,
            url: fileLinks[index],
          }))

          onAdd(fileLinks)
          setFileObjects(prevFiles => [...prevFiles, ...uploadedFiles])
        } catch (e) {
          console.error('Error uploading files:', e)
          customSnackbarError(
            'Error al subir el archivo, por favor intente nuevamente',
            e,
            enqueueSnackbar,
            closeSnackbar,
          )
        } finally {
          setLoadingModalConfig({
            open: false,
          })
          setIsUploading(false)
        }
      }

      if (newFileObjects.length > 0) {
        uploadFile()
      }
    },
    [
      readOnly,
      getAccessTokenSilently,
      currentFarm,
      year,
      definition.name,
      onAdd,
      setLoadingModalConfig,
      enqueueSnackbar,
      closeSnackbar,
    ],
  )

  const handleRemoveFile = index => {
    if (readOnly) return
    onDelete(index)

    setFileObjects(prevFiles => {
      const newFiles = prevFiles.filter((_, i) => i !== index)
      return newFiles
    })
  }

  const getFileTypeFromName = fileName => {
    const extension = fileName.split('.').pop().toLowerCase()

    switch (extension) {
      case 'pdf':
        return 'application/pdf'
      case 'doc':
        return 'application/msword'
      case 'docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg'
      case 'png':
        return 'image/png'
      case 'mp4':
      case 'mov':
        return 'video/mp4'
      case 'mp3':
      case 'wav':
        return 'audio/mpeg'
      default:
        return 'application/octet-stream'
    }
  }

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    onDrop,
    disabled: readOnly || isUploading,
    maxFiles: maxFilesLimit,
    maxSize: maxFileSizeLimit,
    multiple: true,
  })

  useEffect(() => {
    if (fileRejections.length > 0) {
      fileRejections.forEach(rejection => {
        const { errors } = rejection
        errors.forEach(({ message, code }) => {
          let errorMessage = message
          if (code === 'file-too-large') {
            const sizeMB = Math.round(maxFileSizeLimit / 1024 / 1024)
            errorMessage = `El archivo excede el tamaño máximo permitido (${sizeMB}MB)`
          } else if (code === 'too-many-files') {
            errorMessage = `No se pueden subir más de ${maxFilesLimit} archivos`
          }
          customSnackbarError(errorMessage, null, enqueueSnackbar, closeSnackbar)
        })
      })
    }
  }, [fileRejections, enqueueSnackbar, closeSnackbar])

  useEffect(() => {
    async function loadFilesFromUrls() {
      try {
        const loadedFiles = await Promise.all(
          files.map(async file => {
            if (typeof file !== 'string') {
              return file
            }

            const fileName = file.split('/').pop()

            return {
              file: {
                name: fileName,
                type: getFileTypeFromName(fileName),
              },
              data: null,
              url: file,
              id: `${fileName}-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
            }
          }),
        )
        setFileObjects(loadedFiles)
      } catch (e) {
        customSnackbarError('Error al cargar la imagen', e, enqueueSnackbar, closeSnackbar)
      }
    }

    if (files && files.length > 0) {
      loadFilesFromUrls()
    } else {
      setFileObjects([])
    }
  }, [files, enqueueSnackbar, closeSnackbar])

  const renderFilePreview = () => {
    if (definition.showPreviews === false) return null

    if (fileObjects.length === 0) {
      return null
    }

    return (
      <Box sx={{ mt: 1, p: 1, border: '1px solid #eee', borderRadius: 1 }}>
        <Grid container spacing={1}>
          {fileObjects.map(fileObj => {
            const { file, data, url, id } = fileObj
            const fileUrl = url || data
            const fileId = id || `${file.name}-${Math.random().toString(36).substr(2, 9)}`

            const useChips = definition.useChipsForPreview !== false

            return useChips ? (
              <Grid key={`file-chip-${fileId}`} item>
                <Chip
                  icon={getFileIcon(file.type)}
                  label={file.name}
                  sx={{
                    maxWidth: '230px',
                    mb: 1,
                    '& .MuiChip-label': {
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                  }}
                  onClick={() => {
                    if (fileUrl && typeof fileUrl === 'string') {
                      window.open(fileUrl, '_blank')
                    }
                  }}
                  onDelete={
                    readOnly ? undefined : () => handleRemoveFile(fileObjects.indexOf(fileObj))
                  }
                />
              </Grid>
            ) : (
              <Grid key={`file-preview-${fileId}`} item lg={3} md={4} sm={6} xs={12}>
                <Paper
                  elevation={2}
                  sx={{
                    p: 1,
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    mb: 1,
                    '&:hover': {
                      backgroundColor: '#f5f5f5',
                    },
                  }}
                >
                  {getFileIcon(file.type)}
                  <Typography
                    sx={{
                      flexGrow: 1,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      cursor: 'pointer',
                    }}
                    variant="body2"
                    onClick={() => {
                      if (fileUrl && typeof fileUrl === 'string') {
                        window.open(fileUrl, '_blank')
                      }
                    }}
                  >
                    {file.name}
                  </Typography>
                  {!readOnly && (
                    <Close
                      fontSize="small"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => handleRemoveFile(fileObjects.indexOf(fileObj))}
                    />
                  )}
                </Paper>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    )
  }

  if (!currentFarm) return null

  return (
    <div>
      {isUploading ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '26vh',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Paper
            {...getRootProps()}
            sx={{
              p: 2,
              border: '2px dashed #cccccc',
              borderColor: isDragActive ? 'primary.main' : '#cccccc',
              backgroundColor: isDragActive ? 'rgba(25, 118, 210, 0.04)' : 'transparent',
              cursor: readOnly ? 'default' : 'pointer',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '100px',
              transition: 'all 0.2s ease',
            }}
          >
            <input {...getInputProps()} />
            <CloudUpload sx={{ fontSize: 40, mb: 1, color: 'primary.main' }} />
            <Box className="fileInputDropzoneParagraph" sx={{ textAlign: 'center' }}>
              <ReactMarkdown>{definition.placeholder}</ReactMarkdown>
            </Box>
          </Paper>

          {renderFilePreview()}
        </Box>
      )}
    </div>
  )
}

export default FileInput
