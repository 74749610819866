import { deleteMonitoringSite } from './deleteMonitoringSite'
import getMonitoringSites from './getMonitoringSites'
import { updateMonitoringSite } from './updateMonitoringSite'
import getMonitoringOnly from './getMonitoringOnly'

export const monitoringSites = {
  getMonitoringOnly,
  getByFarm: getMonitoringSites,
  delete: deleteMonitoringSite,
  update: updateMonitoringSite,
}
