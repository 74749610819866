import { area, hectareArea, readableArea } from './area'
import { getGeoJSONFromBounds } from './bounds'
import { intersect } from './intersect'
import { difference } from './difference'
import { truncate } from './truncate'
import { booleanContains } from './boolean-contains'
import { distance } from './distance'
import { geoJSONFromGeometry, getFeatureCollection } from './geometry'

export const GeoJSON = {
  area,
  hectareArea,
  readableArea,
  getGeoJSONFromBounds,
  intersect,
  difference,
  truncate,
  booleanContains,
  distance,
  geoJSONFromGeometry,
  getFeatureCollection,
}
