import { Edit } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { localRefData } from '../../services/localRefData/index'

const SOCSamplesList = ({ socSamples, handleEdit, handleDelete }) => {
  const [samplingNumbers, setSamplingNumbers] = useState(null)

  useEffect(() => {
    if (samplingNumbers) return

    const fetchSamplingNumbers = async () => {
      const newSamplingNumbersValues = await localRefData.getDataByKey(
        localRefData.keys.samplingNumber,
      )
      const newSamplingNumbers = newSamplingNumbersValues.reduce((acc, element) => {
        acc[element.id] = element.es_AR || ''
        return acc
      }, {})
      setSamplingNumbers(newSamplingNumbers)
    }

    fetchSamplingNumbers()
  }, [samplingNumbers, socSamples])

  return (
    <Box sx={{ padding: '20px' }}>
      {samplingNumbers && (
        <Box sx={{ width: '100%' }}>
          <Stack spacing={2}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table" sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <Typography variant="p">
                        <b>Número de muestreo</b>
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="p">
                        <b>Fecha de informe</b>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {socSamples &&
                    socSamples.map(socSample => (
                      <TableRow
                        key={socSample.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="center">
                          {samplingNumbers[socSample.samplingNumberId]}
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(socSample.sampleDate).format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip arrow title="Editar">
                            <Button
                              sx={{ margin: '5px' }}
                              variant="contained"
                              onClick={() => {
                                handleEdit(socSample.id)
                              }}
                            >
                              <Edit sx={{ fontSize: 20 }} />
                            </Button>
                          </Tooltip>
                          <Tooltip arrow title="Eliminar resultado">
                            <span>
                              <Button
                                color="error"
                                sx={{ margin: '5px', minWidth: 0 }}
                                variant="outlined"
                                onClick={() => {
                                  handleDelete(socSample.id)
                                }}
                              >
                                <DeleteIcon sx={{ fontSize: 20 }} />
                              </Button>
                            </span>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Box>
      )}
    </Box>
  )
}

export default SOCSamplesList
