import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { GridEditInputCell, GridEditSingleSelectCell } from '@mui/x-data-grid'

export const ERROR_MESSAGES = {
  REQUIRED: 'Requerido',
  INVALID_NUMBER: 'Debe ser un número válido',
  POSITIVE_NUMBER: 'Valor debe ser mayor a 0',
  PERCENTAGE_RANGE: 'Valor debe estar entre 1 y 99',
  REQUIRED_CARBON_REPLICA: 'Se requiere un valor replica de carbono',
}

export const validateSingleSelectRequired = (value, error) => (value === undefined ? error : null)

export const validateInputStringRequired = (value, error) =>
  !value || value.trim() === '' ? error : null

export const validatePercentageNumberRequired = value => {
  if (value === null || value === undefined) {
    return ERROR_MESSAGES.REQUIRED
  }
  const num = Number(value)
  if (Number.isNaN(num)) {
    return ERROR_MESSAGES.INVALID_NUMBER
  }
  if (num < 0 || num > 100) {
    return ERROR_MESSAGES.PERCENTAGE_RANGE
  }
  return null
}

export const validatePositiveNumberRequired = value => {
  if (value === null || value === undefined) {
    return ERROR_MESSAGES.REQUIRED
  }
  const num = Number(value)
  if (Number.isNaN(num)) {
    return ERROR_MESSAGES.INVALID_NUMBER
  }
  if (num <= 0) {
    return ERROR_MESSAGES.POSITIVE_NUMBER
  }
  return null
}

export const validatePercentageNumberOptional = value => {
  if ([null, undefined].includes(value)) return null
  return validatePercentageNumberRequired(value)
}

export const validateCarbonReplicaRequired = ({ value, row }) => {
  const hasError = validatePercentageNumberOptional(value)
  if (hasError) return hasError

  const requiredCarbonoReplicaIsNOTLoaded = [
    row.wbFirstReplicaPercentage,
    row.wbSecondReplicaPercentage,
    row.caFirstReplicaPercentage,
    row.caSecondReplicaPercentage,
    row.lecoFirstReplicaPercentage,
    row.lecoSecondReplicaPercentage,
  ].every(replicaValue => [null, undefined].includes(replicaValue))

  return requiredCarbonoReplicaIsNOTLoaded ? ERROR_MESSAGES.REQUIRED_CARBON_REPLICA : null
}

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -12] } }] } }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}))

const CellWrapper = styled('span')`
  display: flex;
  width: 100%;
  height: 100%;
`

const EditInputCell = ({ params, props }) => {
  const errors = params?.row?.errors?.[params?.field] ?? null

  return (
    <StyledTooltip open={!!errors} title={errors} {...props?.tooltip}>
      <CellWrapper>
        <GridEditInputCell {...params} />
      </CellWrapper>
    </StyledTooltip>
  )
}

const EditSingleSelectCell = ({ params, props }) => {
  const errors = params?.row?.errors?.[params?.field] ?? null
  const value = params.value ?? ''

  return (
    <StyledTooltip open={!!errors} title={errors} {...props?.tooltip}>
      <CellWrapper>
        <GridEditSingleSelectCell defaultValue="" {...params} value={value} />
      </CellWrapper>
    </StyledTooltip>
  )
}

const TextCell = ({ params, props }) => {
  const errors = params?.row?.errors?.[params?.field] ?? null

  return (
    <StyledTooltip open={!!errors} title={errors} {...props?.tooltip}>
      <CellWrapper>{params.value || ''}</CellWrapper>
    </StyledTooltip>
  )
}

const SelectCell = ({ params, props }) => {
  const errors = params?.row?.errors?.[params?.field] ?? null

  return (
    <StyledTooltip open={!!errors} title={errors} {...props?.tooltip}>
      <CellWrapper>
        <div>{params.formattedValue ?? ''}</div>
      </CellWrapper>
    </StyledTooltip>
  )
}
export function renderInput({ params, props }) {
  return <EditInputCell params={params} props={props} />
}

export function renderEditSingleSelect({ params, props }) {
  return <EditSingleSelectCell params={params} props={props} />
}

export function renderText({ params, props }) {
  return <TextCell params={params} props={props} />
}

export function renderSelect({ params, props }) {
  return <SelectCell params={params} props={props} />
}
