import * as turf from '@turf/turf'
import L from 'leaflet'
import { GeoJSON } from '../GeoJSON/index'
import { getFeatureGroup, getPopupContent } from '../perimeterHandler'

export const addPerimeter = (newLayerParam, map, currentFarm) => {
  const newLayer = structuredClone(newLayerParam)
  const content = getPopupContent(newLayer)
  // newLayer.bindTooltip(`<p>${content}</p>`, { permanent: true, direction: 'center'})
  // newLayer.on('click', (e) => {perimeterSelected(e, areasPicked, setAreasPicked)})
  newLayer.bindPopup(`<h4>Perímetro</h4><p>${content.label}</p>`)

  const perimeterFG = getFeatureGroup(map, 'FG_perimeter')
  perimeterFG.eachLayer(layer => {
    if (layer._leaflet_id !== newLayer._leaflet_id) {
      perimeterFG.removeLayer(layer)
    }
  })

  newLayer.properties = {
    name: currentFarm.name,
    area: content.value,
    featureGroup: 'perimeter',
  }

  newLayer.setStyle({
    color: '#000000',
    fillColor: '#000000',
    weight: 2,
    opacity: 1,
    fillOpacity: 0.2,
  })

  return newLayer
}

export const updatePerimeter = (map, localMapState, setLocalMapState) => {
  let perimeter = null
  map.eachLayer(layer => {
    if (layer.options.selectedFarmLayer === 'perimeter') {
      perimeter = layer
    }
  })
  setLocalMapState({ ...localMapState, perimeter })
}

export const deleteOutsiderFeatures = (
  perimeter,
  paddocks,
  handlePaddocks,
  samplingAreas,
  handleSamplingAreas,
  monitoringSites,
  handleMonitoringSites,
  exclusionAreas,
  handleExclusionAreas,
  otherPolygons,
  handleOtherPolygons,
  otherSites,
  handleOtherSites,
) => {
  const perimeterPolygon = perimeter

  const filteredLayers = {}

  // Paddocks
  if (paddocks && paddocks.length > 0) {
    const newPaddocks = []
    paddocks.forEach(f => {
      if (f.geometry.type === 'Polygon' || f.geometry.type === 'MultiPolygon') {
        const intersection = GeoJSON.intersect(f, perimeterPolygon)
        if (intersection) {
          newPaddocks.push(f)
        }
      } else if (f.geometry.type === 'Point') {
        const inside = turf.inside(f, perimeterPolygon)
        if (inside) {
          newPaddocks.push(f)
        }
      }
    })
    handlePaddocks(newPaddocks)
    filteredLayers.paddocks = newPaddocks
  }

  // Sampling Areas
  if (samplingAreas && samplingAreas.length > 0) {
    const newSamplingAreas = []
    samplingAreas.forEach(f => {
      if (['Polygon', 'MultiPolygon'].includes(f.geometry.type)) {
        const intersection = GeoJSON.intersect(f, perimeterPolygon)
        if (intersection) {
          newSamplingAreas.push(f)
        }
      }
    })
    handleSamplingAreas(newSamplingAreas)
    filteredLayers.samplingAreas = newSamplingAreas
  }

  // Exclusion Areas
  if (exclusionAreas && exclusionAreas.length > 0) {
    const newExclusionAreas = []
    exclusionAreas.forEach(f => {
      if (['Polygon', 'MultiPolygon'].includes(f.geometry.type)) {
        const intersection = GeoJSON.intersect(f, perimeterPolygon)
        if (intersection) {
          newExclusionAreas.push(f)
        }
      }
    })
    handleExclusionAreas(newExclusionAreas)
    filteredLayers.exclusionAreas = newExclusionAreas
  }

  // Other Polygons
  if (otherPolygons && otherPolygons.length > 0) {
    const newOtherPolygons = []
    otherPolygons.forEach(f => {
      if (['Polygon', 'MultiPolygon'].includes(f.geometry.type)) {
        const intersection = GeoJSON.intersect(f, perimeterPolygon)
        if (intersection) {
          newOtherPolygons.push(f)
        }
      }
    })
    handleOtherPolygons(newOtherPolygons)
    filteredLayers.otherPolygons = newOtherPolygons
  }

  // Monitoring Sites
  if (monitoringSites && monitoringSites.length > 0) {
    handleMonitoringSites(monitoringSites)
    filteredLayers.monitoringSites = monitoringSites
  }

  // Other Sites
  if (otherSites && otherSites?.length > 0) {
    const newOtherSites = []
    otherSites.forEach(f => {
      if (f.geometry.type === 'Point') {
        const inside = turf.inside(f.geometry.coordinates, perimeterPolygon)
        if (inside) {
          newOtherSites.push(f)
        }
      }
    })
    handleOtherSites(newOtherSites)
    filteredLayers.otherSites = newOtherSites
  }

  return filteredLayers
}

export const getPerimeterFromKML = data => {
  /* let perimeter = {
        type: "Feature",
        properties: {
            name: data.properties.name,
            area: null,
            featureGroup: "perimeter"
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                //INVCOORDS UNIQUE
                data?.geometry?.coordinates?.map( (coord) => {
                    return [coord[1], coord[0], 0]
                })
            ]
        }
    } */

  /* let newLayer = L.geoJSON({
        type: "FeatureCollection",
        features: [perimeter]
    }).getLayers()[0] */

  // let content = getPopupContent(newLayer);
  const perimeter = data
  perimeter.properties.area = L.GeometryUtil.readableArea(GeoJSON.area(perimeter), true)

  return [perimeter]
}

export const perimetersToMultiPolygon = perimeters => {
  if (perimeters[0].geometry.type === 'MultiPolygon') {
    return perimeters[0]
  }
  let multiPolygon = turf.multiPolygon([])
  perimeters.forEach(p => {
    const perimeterPolygon = turf.polygon(p.geometry.coordinates)
    multiPolygon = turf.union(multiPolygon, perimeterPolygon)
  })
  multiPolygon.properties.area = `${GeoJSON.hectareArea(multiPolygon)} ha`
  return multiPolygon
}
