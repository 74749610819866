import axios from 'axios'

export default async function saveFinding(payload, token) {
  try {
    const options = {
      url: `${import.meta.env.VITE_RUUTS_API_URL}/v1/findings/`,
      method: 'POST',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await axios.request(options)
    return response.data
  } catch (error) {
    console.error(error)
    throw new Error(`Unable to save finding: ${error.message}`)
  }
}
