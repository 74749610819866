import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'
import { FC } from 'react'

export const BooleanRowData: FC<{ value: boolean | null | undefined }> = ({ value }) => {
  return (
    <>
      {value ? (
        <CheckCircleIcon style={{ color: 'green' }} />
      ) : (
        <CloseIcon style={{ color: 'red' }} />
      )}
    </>
  )
}
