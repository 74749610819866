/* eslint-disable react/no-array-index-key */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
import { useAuth0 } from '@auth0/auth0-react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Box, Button, Grid, IconButton, Snackbar, Stack } from '@mui/material'
import Alert from '@mui/material/Alert'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import FormControlLabel from '@mui/material/FormControlLabel'
import Tooltip from '@mui/material/Tooltip'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import AppContext from '../../context/appContext'
import lfStore from '../../lfstore/lfStore'
import { dataCollectionStatementService } from '../../services/farmMapping/datacollectionStatement'
import { ruutsApi } from '../../services/ruutsApi'
import { errors as ruutsErrors } from '../../services/ruutsApi/errors'
import { findingsTypes, formModes } from '../../utils/constants'
import { namespacesPostValidations } from '../../utils/dataCollection/namespacesPostValidations/index'
import { processLoading } from '../../utils/Loading/processLoading'
import FindingModal from '../Finding/FindingModal'
import DynamicFieldSwitcher from './DynamicFieldSwitcher'
import { useDraftFormStorage } from './useDraftFormStorage'
import { checkDependencies, parseErrors } from './utils'

const DynamicForm = ({
  selectedMetricEvent,
  dataCollectionStatementId,
  formMode,
  year,
  selectedPaddockIds,
  formDefinition,
  handleMetricReset,
  handleResetForm,
  handleDisableMap,
  allowMoreRecords,
  children,
}) => {
  const tableHeaderRef = useRef(null)
  const [observationDialogOpen, setObservationDialogOpen] = useState(false)

  const { setConfirmationModalConfig, userRoleSelected, setLoadingModalConfig, currentFarm } =
    useContext(AppContext)
  const { getAccessTokenSilently, user } = useAuth0()
  const [canUpdateIsMetricEventVerified, setCanUpdateIsMetricEventVerified] = useState(false)
  const [isMetricEventVerified, setIsMetricEventVerified] = useState(false)
  const [lastFarmSubdivisionYear, setLastFarmSubdivisionYear] = useState(null)

  const { upsertDraft, removeFromDrafts } = useDraftFormStorage({
    namespace: formDefinition?.namespace,
    farmId: currentFarm.id,
    year,
  })

  const getDataCollectionStatements = useCallback(
    async token => {
      if (userRoleSelected) {
        const rawDataCollectionStatements = await dataCollectionStatementService.getByFarmId(
          currentFarm.id,
          userRoleSelected,
          token,
        )
        const realYears =
          rawDataCollectionStatements
            .map(item => item.FarmSubdivision.year)
            .filter(y => Number.isInteger(Number.parseInt(y, 10))) || []
        if (realYears.length) {
          const newLastFarmSubdivisionYear = Math.max(...realYears)
          setLastFarmSubdivisionYear(newLastFarmSubdivisionYear)
        }
      }
    },
    [currentFarm.id, userRoleSelected],
  )

  useEffect(() => {
    async function reloadDataCollectionStatements() {
      const token = await getAccessTokenSilently()
      getDataCollectionStatements(token)
    }
    reloadDataCollectionStatements()
  }, [getDataCollectionStatements, getAccessTokenSilently])

  const getDefaultValues = () => {
    if (selectedMetricEvent !== null) {
      return selectedMetricEvent.data ? selectedMetricEvent.data : selectedMetricEvent
    }
    return formDefinition.fields.reduce(
      (acc, curr) => {
        if (curr.component_type === 'FieldArray') {
          acc[curr.name] = curr.defaultValues
          return acc
        }
        acc[curr.name] = curr.defaultValue
        return acc
      },
      { notApplicable: false, metricYear: year, selectedPaddocks: selectedPaddockIds },
    )
  }

  const {
    handleSubmit,
    formState: { errors, isDirty, touchedFields, dirtyFields },
    control,
    watch,
    reset,
    setValue,
    setError,
    clearErrors,
    getValues,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
    shouldUseNativeValidation: false,
    defaultValues: getDefaultValues(),
  })

  const notApplicableValue = watch('notApplicable')

  const resetForm = useCallback(
    async (values = null) => {
      reset(
        values ||
          formDefinition.fields.reduce(
            (acc, curr) => {
              if (curr.component_type === 'FieldArray') {
                acc[curr.name] = curr.defaultValues
                return acc
              }
              acc[curr.name] = curr.defaultValue
              return acc
            },
            {
              notApplicable: false,
              metricYear: year,
              selectedPaddocks: [],
            },
          ),
      )
      clearErrors()
      setIsMetricEventVerified(false)
      await lfStore.setItem('dirtyForm', false)
    },
    [year, formDefinition.fields, reset, clearErrors, setIsMetricEventVerified],
  )

  const resetAndReloadForm = () => {
    resetForm()
    /* Set formSubmitCount * random number, to force metricEvents refresh on parent component. */
    // Refactor con un eventEmit / listener?
    handleMetricReset()
  }

  const handleTabChangeConfirm = () => {
    resetForm()
    handleResetForm()
  }

  const handleReset = async () => {
    if (isDirty) {
      setConfirmationModalConfig({
        open: true,
        title: 'Cambios sin guardar',
        message: 'Si cambia de pantalla, se perderán los cambios. ¿Desea continuar?',
        confirmLabel: 'CONTINUAR',
        cancelLabel: 'CANCELAR',
        confirmAction: () => handleTabChangeConfirm(),
      })
    } else {
      resetForm()
      handleResetForm()
    }
  }

  const saveFormData = async data => {
    await processLoading({
      getToken: getAccessTokenSilently,
      setLoadingModalConfig,
      loadingMessage: 'Guardando métrica...',
      successfulMessage: 'Datos guardados correctamente',
      errorMessage: 'Error al guardar la métrica, por favor intente nuevamente',
      doAction: async ({ token }) => {
        if (formDefinition?.dependencies?.validationsOnSave?.length) {
          for (const validation of formDefinition.dependencies.validationsOnSave) {
            const validationFunction = namespacesPostValidations[validation]
            if (!validationFunction) continue

            const validationResponse = await validationFunction(currentFarm, year, data, token)
            if (validationResponse?.error) {
              const messageError = validationResponse.error
              setError(validationResponse.name, { message: messageError })
              throw new ruutsErrors.BusinessEntityError({ message: messageError })
            }
          }
        }

        const metricEventId = !selectedMetricEvent?.isDraft ? selectedMetricEvent?.id : null

        await ruutsApi.saveMetrics(
          {
            namespace: formDefinition.namespace,
            farmId: currentFarm.id,
            createdBy: user.name,
            metricEventId,
            formDefinitionVersion: formDefinition.version,
            data,
            dataCollectionStatementId,
            userRole: userRoleSelected,
          },
          token,
        )
        removeFromDrafts(selectedMetricEvent?.id)
        resetAndReloadForm()
        setLoadingModalConfig({
          open: false,
          successfulMessage: 'Datos guardados',
        })
      },
    })
  }

  const saveDraft = () => {
    const draft = getValues()
    draft.id = selectedMetricEvent?.id || null
    upsertDraft(draft)
    resetAndReloadForm()
  }

  const handleSaveVerificationFormData = async value => {
    try {
      const token = await getAccessTokenSilently()
      setLoadingModalConfig({
        open: true,
        dialogMessage: 'Guardando verificación',
      })

      await ruutsApi.saveVerificationMetric(
        {
          metricEventId: selectedMetricEvent?.id || null,
          isVerified: value,
          dataCollectionStatementId,
          userRole: userRoleSelected,
        },
        { token },
      )

      resetAndReloadForm()
      setLoadingModalConfig({
        open: false,
        successfulMessage: 'Datos guardados',
      })
    } catch (e) {
      setLoadingModalConfig({
        open: false,
        error: e,
        errorMessage: 'Error al guardar la métrica, por favor intente nuevamente',
      })
    }
  }

  const openObservationDialog = () => {
    setObservationDialogOpen(true)
  }

  const closeObservationDialog = () => {
    setObservationDialogOpen(false)
    resetAndReloadForm()
  }

  const checkDisableSubmit = useCallback(() => {
    const editingRecord = !!selectedMetricEvent
    const formChanged = Object.keys(touchedFields).length > 0 || Object.keys(dirtyFields).length > 0

    if (editingRecord) {
      return false
    }
    if (formChanged && allowMoreRecords) {
      return false
    }
    return true
  }, [selectedMetricEvent, dirtyFields, touchedFields, allowMoreRecords])

  const checkDisableDraft = () => selectedMetricEvent && !selectedMetricEvent?.isDraft

  const handleSubmitFinding = async data => {
    try {
      const token = await getAccessTokenSilently()

      setLoadingModalConfig({
        open: true,
        dialogMessage: 'Guardando observación',
      })

      await ruutsApi.saveFinding(
        {
          namespace: formDefinition.namespace,
          farmId: currentFarm.id,
          createdBy: user.name,
          metricEventId: selectedMetricEvent?.id || null,
          formDefinitionVersion: formDefinition.version,
          type: data.type,
          metricEventsFieldsObserved: data.metricEventsFieldsObserved,
          comment: data.comment,
          dataCollectionStatementId,
          userRole: userRoleSelected,
        },
        token,
      )
      const successfulMessage = 'Observación guardada exitosamente'
      setLoadingModalConfig({
        open: false,
        successfulMessage,
      })
      closeObservationDialog()
    } catch (error) {
      setLoadingModalConfig({
        open: false,
        error,
        errorMessage: 'Error al guardar la observación, por favor intente nuevamente',
      })
    }
  }

  useEffect(() => {
    handleDisableMap(notApplicableValue)
  }, [notApplicableValue, handleDisableMap])

  /* Set selectedPaddocks field in form */
  useEffect(() => {
    setValue('selectedPaddocks', selectedPaddockIds)
  }, [selectedPaddockIds, setValue])

  /* Set Year value in form coming from year prop */
  useEffect(() => {
    setValue('metricYear', year)
  }, [year, setValue])

  /* When selecting, will set the data to the form */
  useEffect(() => {
    let newIsMetricEventVerified = false
    let hasUnresolvedNonComplaintFindings = false
    if (selectedMetricEvent !== null) {
      hasUnresolvedNonComplaintFindings = selectedMetricEvent.Findings?.some(
        finding =>
          !finding.resolved &&
          [findingsTypes.NonComplaintToFix, findingsTypes.NonComplaintSevere].includes(
            finding.type,
          ),
      )
      resetForm(selectedMetricEvent.data || selectedMetricEvent)
      newIsMetricEventVerified = selectedMetricEvent.isVerified
    } else {
      resetForm()
    }
    setIsMetricEventVerified(newIsMetricEventVerified)
    setCanUpdateIsMetricEventVerified(
      !hasUnresolvedNonComplaintFindings &&
        formMode === formModes.Review &&
        selectedMetricEvent !== null,
    )
  }, [formMode, selectedMetricEvent, resetForm, setIsMetricEventVerified])

  const isMetricEventVerifiedTooltip = () => {
    if (selectedMetricEvent === null) return ''

    return canUpdateIsMetricEventVerified
      ? isMetricEventVerified
        ? 'No verificar'
        : 'Verificar'
      : 'No se puede verificar. Se necesita permisos de revisión y que la métrica no posea observaciones de tipo NO Conformidad sin resolver'
  }

  const handleConfirmVerify = () => {
    setConfirmationModalConfig({
      open: true,
      title: 'Confirmar modificación sobre la métrica',
      message: isMetricEventVerified ? '¿Marcar como NO verificada?' : '¿Marcar como verificada?',
      confirmLabel: 'CONTINUAR',
      cancelLabel: 'CANCELAR',
      confirmAction: () => handleSaveVerificationFormData(!isMetricEventVerified),
    })
  }

  return (
    formDefinition.fields &&
    formDefinition.fields.length > 0 && (
      <FormProvider
        {...{
          watch,
          control,
          reset,
          setError,
          getValues,
          setValue,
          clearErrors,
        }}
      >
        <Box
          component="form"
          pb={2}
          pl={2}
          pr={2}
          pt={1}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            height: '75vh',
            justifyContent: 'flex-start',
            overflowY: 'scroll',
            overflowX: 'hidden',
            scrollbarWidth: '10px',
          }}
          onSubmit={handleSubmit(saveFormData)}
        >
          <Stack alignItems="center" direction="row" display="flex" justifyContent="space-between">
            {children}
            {!formDefinition.required &&
              checkDependencies(
                formDefinition?.dependencies?.requiredMessage?.display,
                'display',
                -1,
                watch,
              ) && (
                <Box sx={{ padding: 0.5, backgroundColor: '#eceff1' }}>
                  <Controller
                    control={control}
                    defaultValue={false}
                    name="notApplicable"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={field.value}
                            disabled={formDefinition.requiredForm || formMode.readOnly}
                            onChange={(evt, value) => {
                              field.onChange(value)
                              lfStore.setItem('dirtyForm', true)
                            }}
                          />
                        }
                        label={
                          formDefinition.requiredMessage
                            ? formDefinition.requiredMessage
                            : 'No aplica'
                        }
                      />
                    )}
                  />
                </Box>
              )}
            {year && (
              <Controller
                control={control}
                defaultValue={year}
                name="metricYear"
                render={({ field }) => (
                  <Box>
                    <input type="hidden" {...field} id="metricYear" />
                  </Box>
                )}
              />
            )}
            {lastFarmSubdivisionYear && (
              <Controller
                control={control}
                defaultValue={lastFarmSubdivisionYear}
                name="lastFarmSubdivisionYear"
                render={({ field }) => (
                  <Box>
                    <input type="hidden" {...field} id="lastFarmSubdivisionYear" />
                  </Box>
                )}
              />
            )}
          </Stack>
          {!watch('notApplicable') && (
            <fieldset style={{ color: 'inherit' }}>
              <Grid container spacing={2}>
                <Controller
                  control={control}
                  defaultValue={[]}
                  name="selectedPaddocks"
                  render={({ field }) => (
                    <Box>
                      <input type="hidden" {...field} id="selectedPaddocks" />
                    </Box>
                  )}
                  rules={{
                    required: formDefinition.layout.showMap
                      ? 'Debe seleccionar al menos un lote'
                      : false,
                    disabled: watch('notApplicable'),
                  }}
                />
                {!!selectedMetricEvent && (
                  <Grid item xs={12}>
                    <Box>
                      <Chip
                        color="secondary"
                        label={`${formMode.label} #${selectedMetricEvent.dataIndex}`}
                        size="small"
                        variant="contained"
                      />
                    </Box>
                  </Grid>
                )}
                {formDefinition.fields.map((formField, index) => (
                  <DynamicFieldSwitcher
                    key={index}
                    checkDependencies={checkDependencies}
                    control={control}
                    formField={formField}
                    readOnly={formMode.readOnly}
                    year={year}
                  />
                ))}
              </Grid>
            </fieldset>
          )}
        </Box>
        <Stack
          backgroundColor="#E3E9EF"
          bottom={0}
          direction="column"
          display="flex"
          position="sticky"
          spacing={1}
          width="100vw"
          zIndex={1000}
        >
          {errors && Object.keys(errors).length > 0 && (
            <Snackbar key="error-snackbar" open={!!errors}>
              <Alert
                severity="error"
                onClose={() => {
                  clearErrors()
                }}
              >
                {parseErrors(errors)[0]}
              </Alert>
            </Snackbar>
          )}
        </Stack>

        <Stack
          ref={tableHeaderRef}
          backgroundColor="#E3E9EF"
          bottom={0}
          direction="column"
          display="flex"
          id="anual-data-table-header"
          position="sticky"
          spacing={1}
          width="100vw"
          zIndex={900}
        >
          <Stack direction="row" display="flex" p={1} spacing={1}>
            {formMode === formModes.Review && (
              <Tooltip title={isMetricEventVerifiedTooltip()}>
                <Box>
                  <IconButton
                    color={isMetricEventVerified ? 'success' : 'error'}
                    disabled={!canUpdateIsMetricEventVerified}
                    onClick={handleConfirmVerify}
                  >
                    <CheckCircleIcon />
                  </IconButton>
                </Box>
              </Tooltip>
            )}
            {!formMode.readOnly && (
              <Button
                disabled={checkDisableSubmit()}
                size="small"
                variant="contained"
                onClick={handleSubmit(saveFormData)}
              >
                GUARDAR
              </Button>
            )}
            {formMode === formModes.Review && !!selectedMetricEvent && (
              <>
                <Button
                  size="small"
                  sx={{ backgroundColor: '#D6B714', '&:hover': { backgroundColor: '#fbc02d' } }}
                  variant="contained"
                  onClick={openObservationDialog}
                >
                  OBSERVAR
                </Button>
                <FindingModal
                  buttonValue="confirm"
                  cancelLabel="Cancelar"
                  formDefinition={formDefinition}
                  handleClose={closeObservationDialog}
                  handleConfirm={handleSubmitFinding}
                  open={observationDialogOpen}
                  saveLabel="Guardar"
                />
              </>
            )}
            {!formMode.readOnly && (
              <Button
                disabled={checkDisableDraft()}
                size="small"
                variant="outlined"
                onClick={() => saveDraft()}
              >
                BORRADOR
              </Button>
            )}
            <Button
              color="error"
              disabled={checkDisableSubmit()}
              size="small"
              variant="outlined"
              onClick={handleReset}
            >
              {formMode === formModes.Review ? 'CANCELAR' : 'LIMPIAR'}
            </Button>
          </Stack>
        </Stack>
      </FormProvider>
    )
  )
}

export default DynamicForm
