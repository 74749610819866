import React from 'react'
import { Box } from '@mui/material'
import EventViewerMap from './EventViewerMap'

const ActivitiesStep = ({
  assignedTo,
  currentFarm,
  eventData,
  file,
  monitoringSitesWithTrackPoints,
  setEventData,
  setMonitoringSites,
  setWaypoints,
  waypoints,
}) => {
  return (
    <Box sx={{ mt: 2, height: '100%' }}>
      <EventViewerMap
        assignedTo={assignedTo}
        currentFarm={currentFarm}
        eventData={eventData}
        gpx={file}
        monitoringSitesWithTrackPoints={monitoringSitesWithTrackPoints}
        setEventData={setEventData}
        setMonitoringSites={setMonitoringSites}
        setWaypoints={setWaypoints}
        waypoints={waypoints}
      />
    </Box>
  )
}

export default ActivitiesStep
