import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import { styled } from '@mui/material/styles'
import {
  DataGrid as DataGridMUI,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowModes,
  useGridApiContext,
} from '@mui/x-data-grid'
import { v4 as uuidv4 } from 'uuid'
import AppContext from '../../../context/appContext'

const StyledBox = styled('div')(({ theme }) => ({
  width: '100%',
  '& .MuiDataGrid-cell--editing': {
    backgroundColor: 'rgb(255,215,115, 0.19)',
    color: '#1a3e72',
    '& .MuiInputBase-root': { height: '100%' },
  },
  '& .Mui-error': {
    backgroundColor: 'rgb(126,10,15, 0.1)',
    color: theme.palette.error.main,
    ...theme.applyStyles('dark', { backgroundColor: 'rgb(126,10,15, 0)' }),
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    textAlign: 'center', // Center align the text
    whiteSpace: 'pre-line', // Ensure line breaks are respected
  },
  '& .error-cell': {
    backgroundColor: 'rgba(255, 0, 0, 0.1) !important',
    border: '1px solid red !important',
  },
  marginBottom: '2vh',
}))

const EditToolbar = ({ addButtonCaption, fieldToFocus, setRows, setPartialChanges }) => {
  const apiRef = useGridApiContext()

  const handleClick = () => {
    const id = uuidv4()
    const newRow = { id, isNew: true }

    setRows(oldRows => [...oldRows, newRow])
    setPartialChanges(true)

    apiRef.current.startRowEditMode({ id, fieldToFocus })
  }

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        {addButtonCaption}
      </Button>
    </GridToolbarContainer>
  )
}

const DataGrid = ({
  title,
  addButtonCaption,
  setIsEditingMode,
  columns,
  rows,
  setRows,
  ...props
}) => {
  const { setPartialChanges } = useContext(AppContext)
  const [rowModesModel, setRowModesModel] = useState({})

  const handleDeleteClick = useCallback(
    id => async () => {
      setPartialChanges(true)
      const newRows = rows
        .filter(row => row.id !== id || (row.id === id && !row.isNew))
        .map(row => (!row.isNew && row.id === id ? { ...row, isDeleted: true } : { ...row }))
      setRows(newRows)
    },
    [rows, setPartialChanges, setRows],
  )

  useEffect(() => {
    const isARowInEditMode = Object.values(rowModesModel).some(
      model => model.mode === GridRowModes.Edit,
    )
    setIsEditingMode(isARowInEditMode)
  }, [rowModesModel, setIsEditingMode])

  const columnsWithActions = useMemo(
    () => [
      ...columns.map(column =>
        column.required ? { ...column, headerName: `${column.headerName} *` } : { ...column },
      ),
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Acciones',
        width: 100,
        cellClassName: 'actions',
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              key={`delete-${id}`}
              color="inherit"
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
            />,
          ]
        },
      },
    ],
    [columns, handleDeleteClick],
  )

  const processRowUpdate = newRow => {
    setPartialChanges(true)
    const { errors, ...updateRowWithoutErrors } = newRow
    setRows(oldRows => oldRows.map(row => (row.id === newRow.id ? updateRowWithoutErrors : row)))
    return updateRowWithoutErrors
  }

  const handleRowClick = useCallback(params => {
    setRowModesModel(prevModel => ({
      ...Object.keys(prevModel).reduce(
        (acc, id) => ({ ...acc, [id]: { mode: GridRowModes.View } }),
        {},
      ),
      [params.id]: { mode: GridRowModes.Edit },
    }))
  }, [])

  const handleRowModesModelChange = useCallback(newModel => {
    setRowModesModel(newModel)
  }, [])

  const filteredRows = useMemo(() => rows?.filter(row => !row.isDeleted), [rows])

  return (
    <StyledBox>
      {title && (
        <Typography sx={{ fontSize: 20, fontWeight: 600, textAlign: 'left', marginBottom: '10px' }}>
          {title}
        </Typography>
      )}
      <DataGridMUI
        disableColumnMenu
        columns={columnsWithActions}
        editMode="row"
        getCellClassName={params => (params.row?.errors?.[params?.field] ? 'error-cell' : '')}
        getRowId={row => row.id}
        localeText={{ noRowsLabel: '' }}
        processRowUpdate={processRowUpdate}
        rowModesModel={rowModesModel}
        rows={filteredRows}
        slotProps={{
          toolbar: {
            addButtonCaption,
            fieldToFocus: columns?.length ? columns[0].field : '',
            setPartialChanges,
            setRows,
          },
        }}
        slots={{ toolbar: EditToolbar }}
        onRowClick={handleRowClick}
        onRowModesModelChange={handleRowModesModelChange}
        {...props}
      />
    </StyledBox>
  )
}

export default DataGrid
