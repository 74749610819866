import { type AppState, Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react'
import { createBrowserHistory } from 'history'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { getConfig } from './configAuth'
import './index.css'

const config = getConfig(import.meta.env.VITE_ENV || import.meta.env.NODE_ENV)

const providerConfig: Auth0ProviderOptions = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  authorizationParams: {
    redirect_uri: window.location.origin + window.location.pathname,
  },
  cacheLocation: 'localstorage' as const,
  useRefreshTokens: true,
  useRefreshTokensFallback: true,
}

const history = createBrowserHistory()
const onRedirectCallback = (appState?: AppState): void => {
  history.replace(appState?.returnTo || window.location.pathname)
}

const rootElement = document.getElementById('root')
if (!rootElement) throw new Error('Failed to find the root element')
const root = ReactDOM.createRoot(rootElement)
root.render(
  <React.StrictMode>
    <Auth0Provider {...providerConfig} onRedirectCallback={onRedirectCallback}>
      <BrowserRouter basename={import.meta.env.PUBLIC_URL}>
        <App />
      </BrowserRouter>
    </Auth0Provider>
  </React.StrictMode>,
)
