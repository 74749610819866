import { Box, List, ListItem, ListItemText, Skeleton, styled, Typography } from '@mui/material'
import { Fragment, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { AttestationReportDetail } from './AttestationReportDetail'
import { useSamplingAreas } from '../../../hooks/data/useSamplingAreas'
import { Map } from '../Map/Map'
import { REPORT_BACKGROUND_COLOR } from '../constants'

const MemoizedAttestationReportDetail = memo(AttestationReportDetail)

const NoBulletList = styled(List)({
  listStyleType: 'none',
  padding: 0,
})

const TextWithLineBreaks = ({ text, textAlign = 'justify' }) => {
  return (
    <Typography sx={{ textAlign }} variant="body2">
      {text.split('\n').map((line, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>
          {line}
          <br />
        </Fragment>
      ))}
    </Typography>
  )
}

const formatDate = date => {
  return new Date(date).getFullYear()
}

const getYearsBetweenDates = (startDate, endDate) => {
  const startYear = new Date(startDate).getFullYear()
  const endYear = new Date(endDate).getFullYear()
  const years = []

  for (let year = startYear; year <= endYear; year++) {
    years.push(year)
  }

  return years
}

export const AttestationReport = ({ report, setIsReportLoading }) => {
  const {
    date,
    attestationContent,
    city,
    province,
    country,
    responsible,
    periodStart,
    periodEnd,
    includedMetrics,
    farm,
  } = report

  const years = useMemo(
    () => getYearsBetweenDates(periodStart, periodEnd),
    [periodStart, periodEnd],
  )

  const { samplingAreas, isLoading } = useSamplingAreas({ farmId: farm.id })
  const [loadedYears, setLoadedYears] = useState(new Set())

  useEffect(() => {
    setIsReportLoading(true)
    return () => setIsReportLoading(false)
  }, [setIsReportLoading])

  useEffect(() => {
    if (loadedYears.size === years.length) {
      setIsReportLoading(false)
    }
  }, [loadedYears, setIsReportLoading, years.length])

  const handleYearLoaded = useCallback(year => {
    setLoadedYears(prev => {
      const updated = new Set(prev)
      updated.add(year)
      return updated
    })
  }, [])

  const perimeter = useMemo(() => farm?.toGeoJSON, [farm])

  if (isLoading) return <Skeleton />

  return (
    <Box sx={{ background: REPORT_BACKGROUND_COLOR.Attestation }}>
      <img
        alt="attestation-Header"
        height="200px"
        src="/reports/attestation-sara/attestation-header.png"
        width="100%"
      />
      <Box sx={{ display: 'flex', gap: '16px', flexDirection: 'column', p: '20px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Typography typography="body2">Fecha: {date.format('DD/MM/YYYY')}</Typography>
          <Typography sx={{ fontWeight: 'bold' }} typography="h6">
            INFORME DE CONSENTIMIENTO - ESTABLECIMIENTO {farm?.name}
          </Typography>
          <TextWithLineBreaks text={attestationContent} textAlign="justify" />
          <Typography sx={{ fontWeight: 'bold' }} typography="h6">
            ESTABLECIMIENTO {farm?.name} - {city} - {province} - {country}
          </Typography>
          <NoBulletList>
            <ListItem>
              <ListItemText>
                <Typography typography="body2">
                  {formatDate(periodStart) === formatDate(periodEnd)
                    ? `Periodo: ${formatDate(periodStart)}`
                    : `Periodo: ${formatDate(periodStart)} - ${formatDate(periodEnd)}`}
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Typography typography="body2">Responsable del proyecto: {responsible}</Typography>
              </ListItemText>
            </ListItem>
          </NoBulletList>
        </Box>

        <div className="html2pdf__page-break" />

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Typography sx={{ fontWeight: 'bold' }} typography="h5">
            Perímetro del establecimiento y estratificación
          </Typography>
          <Map isImage flexDirection="column" mapHeight={600} mapWidth={600} perimeter={perimeter}>
            <Map.Layer layer={samplingAreas} />
            <Map.Legend layers={[samplingAreas]} />
          </Map>
        </Box>
        {years.map(year => (
          <Fragment key={year}>
            <MemoizedAttestationReportDetail
              farm={farm}
              includedMetrics={includedMetrics}
              year={year}
              onLoadComplete={handleYearLoaded}
            />
          </Fragment>
        ))}
      </Box>
    </Box>
  )
}
