/* eslint-disable react/no-array-index-key */
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import lfStore from '../../../lfstore/lfStore'
import refDataByKey from '../../../services/localRefData/refDataByKey'

const RadioGroupWrapper = ({ control, formField, rules, readOnly }) => {
  const [options, setOptions] = useState()

  useEffect(() => {
    const getFieldOptions = async field => {
      const newOptions = await refDataByKey(field.refDataKey).catch(e => console.error(e))
      setOptions(newOptions)
    }
    if (formField.defaultOptions && formField.defaultOptions.length)
      setOptions(formField.defaultOptions)
    else getFieldOptions(formField)
  }, [formField])

  useEffect(() => {
    const getFieldOptions = async field => {
      const newOptions = await refDataByKey(field.refDataKey).catch(e => console.error(e))
      setOptions(newOptions)
    }
    if (formField.defaultOptions && formField.defaultOptions.length)
      setOptions(formField.defaultOptions)
    else getFieldOptions(formField)
  }, [formField])

  return (
    options && (
      <FormControl disabled={readOnly} variant="outlined">
        <FormLabel id={formField.name}>
          <Typography variant="label">{formField.placeholder}</Typography>
        </FormLabel>
        <Controller
          control={control}
          defaultValue={formField.defaultValue}
          name={formField.name}
          render={({ field }) => (
            <RadioGroup
              aria-labelledby={formField.name}
              defaultValue={formField.defaultValue}
              name={formField.name}
              row={formField.direction === 'row'}
              value={field.value}
              onChange={(evt, value) => {
                evt.preventDefault()
                field.onChange(value)
                if (!readOnly) lfStore.setItem('dirtyForm', true)
              }}
            >
              {options?.map((option, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    control={<Radio />}
                    label={option.es_AR ?? option.label}
                    value={option.id ?? option.value}
                  />
                )
              })}
            </RadioGroup>
          )}
          rules={rules}
        />
      </FormControl>
    )
  )
}

export default RadioGroupWrapper
