import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'

const YearSelector = ({
  years,
  savedYears,
  baseFarmSubdivisionYear,
  setBaseFarmSubdivisionYear,
  baseFileName,
}) => {
  return (
    <Stack
      direction="column"
      sx={{
        borderRadius: 4,
        backgroundColor: '#f3f7f8',
        padding: 2,
        margin: 2,
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      <Typography
        sx={{
          fontSize: 14,
          margin: 1,
        }}
      >
        Seleccione un loteo previamente cargado para utilizar de base <i>(opcional)</i>
      </Typography>
      {years.length > 0 ? (
        <Stack
          direction="column"
          sx={{
            justifyContent: 'space-evenly',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <FormControl variant="filled">
            <InputLabel id="base-farm-label">Loteo de base</InputLabel>
            <Select
              color="primary"
              labelId="base-farm-label"
              value={baseFarmSubdivisionYear || ''}
              onChange={e => {
                setBaseFarmSubdivisionYear(e.target.value)
              }}
            >
              <MenuItem value="">
                <em>Ninguno</em>
              </MenuItem>
              {years.map(year => {
                return (
                  <MenuItem key={year} disabled={savedYears.indexOf(year) === -1} value={year}>
                    {year}
                  </MenuItem>
                )
              })}
            </Select>
            <FormHelperText>Elija un año o comience desde cero</FormHelperText>
          </FormControl>
          {baseFileName && (
            <Typography sx={{ textAlign: 'left', padding: '10px 10px' }} variant="subtitle2">
              <b>Archivo base:</b> {baseFileName}
            </Typography>
          )}
        </Stack>
      ) : (
        <CircularProgress sx={{ margin: 2 }} />
      )}
    </Stack>
  )
}

export default YearSelector
