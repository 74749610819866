import { useState, useCallback, useContext } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { ruutsApi } from '../services/ruutsApi'
import AppContext from '../context/appContext'
import { useProcessLoadingWithConfirmation } from '../utils/Loading/useProcessLoadingWithConfirmation'
import { useCustomSnackbarError } from '../utils/Snackbar/useCustomSnackbarError'
import { allHubsId } from '../services/farmMapping/getFarms'

export const useEventCreation = onSuccess => {
  const { currentFarm } = useContext(AppContext)
  const { getAccessTokenSilently, user } = useAuth0()
  const { processLoadingWithConfirmation } = useProcessLoadingWithConfirmation()
  const { handleError } = useCustomSnackbarError()

  const [loading, setLoading] = useState(false)
  const [uploadError, setUploadError] = useState(null)

  const [eventData, setEventData] = useState({})
  const [eventStartDate, setEventStartDate] = useState('')
  const [eventEndDate, setEventEndDate] = useState('')
  const [file, setFile] = useState(null)
  const [hasGpxFile, setHasGpxFile] = useState(true)

  const [monitoringSitesWithTrackPoints, setMonitoringSitesWithTrackPoints] = useState([])
  const [waypoints, setWaypoints] = useState([])

  const [hubUsers, setHubUsers] = useState([])
  const [assignedTo, setAssignedTo] = useState(null)

  const resetForm = () => {
    setFile(null)
    setLoading(false)
    setUploadError(null)
    setEventData({})
    setEventStartDate('')
    setEventEndDate('')
    setAssignedTo(hubUsers[0]?.email)
    setHasGpxFile(true)
    setMonitoringSitesWithTrackPoints([])
    setWaypoints([])
  }

  const fetchHubUsers = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently()
      const query = {
        query: `user_metadata.hubId:${currentFarm.hubId} OR user_metadata.hubId:${allHubsId}`,
      }
      const users = await ruutsApi.getApiData(ruutsApi.endpoints.users, query, token)

      users.sort((a, b) => {
        if (a.email === user.email) return -1
        if (b.email === user.email) return 1
        return 0
      })

      setHubUsers(users)
      setAssignedTo(users[0]?.email)
    } catch (error) {
      handleError(error, 'Error al obtener usuarios del hub', false)
    }
  }, [currentFarm?.hubId, getAccessTokenSilently, user?.email, handleError])

  const getMonitoringSites = useCallback(async () => {
    try {
      if (!currentFarm?.id) return []

      const token = await getAccessTokenSilently()
      const sites = await ruutsApi.monitoringSites.getMonitoringOnly(currentFarm.id, token)
      return sites
    } catch (error) {
      setUploadError(error)
      return []
    }
  }, [currentFarm?.id, getAccessTokenSilently])

  const validateGpxFile = async () => {
    try {
      if (!file) return false

      setLoading(true)
      const token = await getAccessTokenSilently()
      const { monitoringSitesWithResults, waypoints: waypointsResult } =
        await ruutsApi.compareGpsData({
          farm: currentFarm,
          token,
          file,
          eventStartDate,
          eventEndDate,
        })

      setMonitoringSitesWithTrackPoints(monitoringSitesWithResults)
      setWaypoints(waypointsResult)
      setUploadError(null)
      return true
    } catch (error) {
      setUploadError(error)
      return false
    } finally {
      setLoading(false)
      setEventData({})
    }
  }

  const createEvent = async () => {
    setLoading(true)
    try {
      await processLoadingWithConfirmation({
        confirmationTitle: 'Crear evento',
        confirmationMessage:
          'Se creará el evento y se confirmarán los sitios con las ubicaciones proporcionadas. ¿Desea continuar?',
        loadingMessage: 'Creando evento...',
        errorMessage: 'Error al crear el evento.',
        doAction: async ({ token, dryRun }) => {
          if (dryRun) return

          await ruutsApi.addMonitoringEvent({
            farmId: currentFarm.id,
            eventData,
            eventStartDate,
            eventEndDate,
            isBackdatedEvent: true,
            gpsData: file,
            siteConfirmationRequired: true,
            token,
          })

          resetForm()
          if (onSuccess) onSuccess()
        },
      })
    } catch (error) {
      setUploadError(error)
      handleError(error, 'Error al crear el evento', false)
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    uploadError,
    eventData,
    eventStartDate,
    eventEndDate,
    file,
    hasGpxFile,
    monitoringSitesWithTrackPoints,
    waypoints,
    hubUsers,
    assignedTo,
    currentFarm,

    setEventData,
    setEventStartDate,
    setEventEndDate,
    setFile,
    setAssignedTo,
    setHasGpxFile,
    setUploadError,
    setMonitoringSitesWithTrackPoints,
    setWaypoints,
    setLoading,

    fetchHubUsers,
    getMonitoringSites,
    validateGpxFile,
    createEvent,
    resetForm,
  }
}
