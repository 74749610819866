/* eslint-disable no-param-reassign */
import { errors } from '../../services/ruutsApi/errors'
import { PROGRAMS } from '../constants'

export function displayMessageFromError(errorMessage, error) {
  if (error instanceof errors.RuutsError) {
    const { unexpected } = error
    console.warn(error)
    return {
      unexpected,
      displayMessage: unexpected ? errorMessage : error.message,
    }
  }

  console.error(error)
  return {
    unexpected: false,
    displayMessage: errorMessage,
  }
}

export function openSupportForm({ user, farm, title, reason, error }) {
  const program = PROGRAMS[farm?.programId] || farm?.program?.name
  title = `${title} [${program} - ${farm?.hub?.name} - ${farm?.name}]`
  let category = 'Otro'
  if (error && error.unexpected) {
    category = 'Error'
    reason = `${reason}\n\nError:\n`
    reason = `${reason}name:  ${error.name}\nmessage: ${error.message}\nat:${error.at}\ntext:${error.text}\nstack:${error.stack}`
  }

  window.open(
    `https://share.hsforms.com/1JLOc-3huQviHqJDhNl7XjQd7qz7?producto=RPD&hs_ticket_category=${category}&asunto=${title}&content=${encodeURIComponent(reason)}&email=${user.email}`,
    '_blank',
  )
}
