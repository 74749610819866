import { useContext, useEffect, useState, useCallback } from 'react'
import { Stack } from '@mui/material'
import AppContext from '../../context/appContext'
import { getMonitoringSOCSampleFormDefinition } from '../../services/ruutsApi/formDefinitions/getFormDefinitions'
import { ruutsApi } from '../../services/ruutsApi/index'
import { useProcessLoading } from '../../utils/Loading/useProcessLoading'
import FormDefinitionDialog from './FormDefinitionDialog'
import {
  renderInput,
  renderEditSingleSelect,
  validateInputStringRequired,
  validateSingleSelectRequired,
  validateCarbonReplicaRequired,
  validatePositiveNumberRequired,
  validatePercentageNumberRequired,
  validatePercentageNumberOptional,
  renderText,
  renderSelect,
} from './DataGrid/DataGridControls'
import { monitoringWorkflowsTypes, monitoringTaskStatusTypes } from '../../utils/constants'
import { localRefData } from '../../services/localRefData/index'
import DataGrid from './DataGrid/DataGrid'

const monitoringSOCColumn = {
  field: 'monitoringSOCActivityId',
  headerName: 'Actividad SOC completa',
  headerAlign: 'center',
  validate: ({ value, _ }) => validateSingleSelectRequired(value, 'Requerida'),
  renderCell: params => renderSelect({ params, props: { tooltip: { placement: 'top' } } }),
  renderEditCell: params =>
    renderEditSingleSelect({ params, props: { tooltip: { placement: 'top' } } }),
  required: true,
  editable: true,
  sortable: true,
  width: 220,
  type: 'singleSelect',
}

const dapMethodColumn = {
  field: 'dapMethodId',
  headerName: 'Método DAP',
  headerAlign: 'center',
  validate: ({ value, _ }) => validateSingleSelectRequired(value, 'Requerido'),
  renderCell: params => renderSelect({ params, props: { tooltip: { placement: 'top' } } }),
  renderEditCell: params =>
    renderEditSingleSelect({ params, props: { tooltip: { placement: 'top' } } }),
  required: true,
  editable: true,
  sortable: true,
  width: 220,
  type: 'singleSelect',
}

const monitoringSOCSitesFixedColumns = [
  {
    field: 'name',
    headerName: 'Muestra',
    headerAlign: 'center',
    validate: ({ value, _ }) => validateInputStringRequired(value, 'Requerido'),
    renderCell: params => renderText({ params, props: { tooltip: { placement: 'bottom' } } }),
    renderEditCell: params => renderInput({ params, props: { tooltip: { placement: 'bottom' } } }),
    required: true,
    editable: true,
    sortable: true,
    width: 180,
  },
  {
    field: 'wbFirstReplicaPercentage',
    headerName: 'Rep 1\nWB [%]',
    headerAlign: 'center',
    type: 'number',
    validate: ({ value, row }) => validateCarbonReplicaRequired({ value, row }),
    renderCell: params => renderText({ params, props: { tooltip: { placement: 'bottom' } } }),
    renderEditCell: params => renderInput({ params, props: { tooltip: { placement: 'bottom' } } }),
    required: false,
    editable: true,
    sortable: false,
    width: 100,
    align: 'left',
  },
  {
    field: 'wbSecondReplicaPercentage',
    headerName: 'Rep 2\nWB [%]',
    headerAlign: 'center',
    type: 'number',
    validate: ({ value, _ }) => validatePercentageNumberOptional(value),
    renderCell: params => renderText({ params, props: { tooltip: { placement: 'bottom' } } }),
    renderEditCell: params => renderInput({ params, props: { tooltip: { placement: 'bottom' } } }),
    editable: true,
    sortable: false,
    width: 100,
    align: 'left',
  },
  {
    field: 'caFirstReplicaPercentage',
    headerName: 'Rep 1\nCA [%]',
    headerAlign: 'center',
    type: 'number',
    validate: ({ value, _ }) => validatePercentageNumberOptional(value),
    renderCell: params => renderText({ params, props: { tooltip: { placement: 'bottom' } } }),
    renderEditCell: params => renderInput({ params, props: { tooltip: { placement: 'bottom' } } }),
    editable: true,
    sortable: false,
    width: 100,
    align: 'left',
  },
  {
    field: 'caSecondReplicaPercentage',
    headerName: 'Rep 2\nCA [%]',
    headerAlign: 'center',
    type: 'number',
    validate: ({ value, _ }) => validatePercentageNumberOptional(value),
    renderCell: params => renderText({ params, props: { tooltip: { placement: 'bottom' } } }),
    renderEditCell: params => renderInput({ params, props: { tooltip: { placement: 'bottom' } } }),
    editable: true,
    sortable: false,
    width: 100,
    align: 'left',
  },
  {
    field: 'lecoFirstReplicaPercentage',
    headerName: 'LECO 1\nCA [%]',
    headerAlign: 'center',
    type: 'number',
    validate: ({ value, _ }) => validatePercentageNumberOptional(value),
    renderCell: params => renderText({ params, props: { tooltip: { placement: 'bottom' } } }),
    renderEditCell: params => renderInput({ params, props: { tooltip: { placement: 'bottom' } } }),
    width: 100,
    editable: true,
    sortable: false,
    align: 'left',
  },
  {
    field: 'lecoSecondReplicaPercentage',
    headerName: 'LECO 2\nCA [%]',
    headerAlign: 'center',
    type: 'number',
    validate: ({ value, _ }) => validatePercentageNumberOptional(value),
    renderCell: params => renderText({ params, props: { tooltip: { placement: 'bottom' } } }),
    renderEditCell: params => renderInput({ params, props: { tooltip: { placement: 'bottom' } } }),
    required: false,
    editable: true,
    sortable: false,
    width: 100,
    align: 'left',
  },
  {
    field: 'dryWeight',
    headerName: 'Peso seco',
    headerAlign: 'center',
    type: 'number',
    validate: ({ value, _ }) => validatePositiveNumberRequired(value),
    renderCell: params => renderText({ params, props: { tooltip: { placement: 'bottom' } } }),
    renderEditCell: params => renderInput({ params, props: { tooltip: { placement: 'bottom' } } }),
    required: true,
    editable: true,
    sortable: false,
    width: 100,
    align: 'left',
  },
  {
    field: 'gravelWeight',
    headerName: 'Peso grava',
    headerAlign: 'center',
    type: 'number',
    validate: ({ value, _ }) => validatePositiveNumberRequired(value),
    renderCell: params => renderText({ params, props: { tooltip: { placement: 'bottom' } } }),
    renderEditCell: params => renderInput({ params, props: { tooltip: { placement: 'bottom' } } }),
    required: true,
    editable: true,
    sortable: false,
    width: 100,
    align: 'left',
  },
  {
    field: 'gravelVolume',
    headerName: 'Volumen de grava',
    headerAlign: 'center',
    type: 'number',
    validate: ({ value, _ }) => validatePositiveNumberRequired(value),
    renderCell: params => renderText({ params, props: { tooltip: { placement: 'bottom' } } }),
    renderEditCell: params => renderInput({ params, props: { tooltip: { placement: 'bottom' } } }),
    required: true,
    editable: true,
    sortable: false,
    width: 100,
    align: 'left',
  },
]

const samplingAreasColumn = {
  field: 'samplingAreaId',
  headerName: 'Estrato',
  headerAlign: 'center',
  validate: ({ value, _ }) => validateSingleSelectRequired(value, 'Requerido'),
  renderCell: params => renderSelect({ params, props: { tooltip: { placement: 'top' } } }),
  renderEditCell: params =>
    renderEditSingleSelect({ params, props: { tooltip: { placement: 'top' } } }),
  required: true,
  editable: true,
  sortable: true,
  width: 160,
  type: 'singleSelect',
}

const soilTexturesColumn = {
  field: 'soilTextureTypeId',
  headerName: 'Textura de suelo',
  headerAlign: 'center',
  validate: ({ value, _ }) => validateSingleSelectRequired(value, 'Requerida'),
  renderCell: params => renderSelect({ params, props: { tooltip: { placement: 'top' } } }),
  renderEditCell: params =>
    renderEditSingleSelect({ params, props: { tooltip: { placement: 'top' } } }),
  required: true,
  editable: true,
  sortable: true,
  width: 200,
  type: 'singleSelect',
}

const monitoringSOCSamplingAreasFixedColumns = [
  {
    field: 'stationsName',
    headerName: 'Estaciones',
    headerAlign: 'center',
    validate: ({ value, _ }) => validateInputStringRequired(value, 'Requerido'),
    renderCell: params => renderText({ params, props: { tooltip: { placement: 'bottom' } } }),
    renderEditCell: params => renderInput({ params, props: { tooltip: { placement: 'bottom' } } }),
    required: true,
    editable: true,
    sortable: true,
    width: 170,
  },
  {
    field: 'samplingAreaName',
    headerName: 'Muestra compuesta',
    headerAlign: 'center',
    validate: ({ value, _ }) => validateInputStringRequired(value, 'Requerida'),
    renderCell: params => renderText({ params, props: { tooltip: { placement: 'bottom' } } }),
    renderEditCell: params => renderInput({ params, props: { tooltip: { placement: 'bottom' } } }),
    required: true,
    editable: true,
    sortable: true,
    width: 170,
  },
  {
    field: 'ph',
    headerName: 'pH',
    headerAlign: 'center',
    type: 'number',
    validate: ({ value, _ }) => validatePositiveNumberRequired(value),
    renderCell: params => renderText({ params, props: { tooltip: { placement: 'bottom' } } }),
    renderEditCell: params => renderInput({ params, props: { tooltip: { placement: 'bottom' } } }),
    required: true,
    editable: true,
    sortable: false,
    width: 100,
    align: 'left',
  },
  {
    field: 'nitrogenPercentage',
    headerName: 'Nitrógeno [%]',
    headerAlign: 'center',
    type: 'number',
    validate: ({ value, _ }) => validatePercentageNumberRequired(value),
    renderCell: params => renderText({ params, props: { tooltip: { placement: 'bottom' } } }),
    renderEditCell: params => renderInput({ params, props: { tooltip: { placement: 'bottom' } } }),
    required: true,
    editable: true,
    sortable: false,
    width: 100,
    align: 'left',
  },
  {
    field: 'phosphorusPercentage',
    headerName: 'P [ppm]',
    headerAlign: 'center',
    type: 'number',
    validate: ({ value, _ }) => validatePercentageNumberRequired(value),
    renderCell: params => renderText({ params, props: { tooltip: { placement: 'bottom' } } }),
    renderEditCell: params => renderInput({ params, props: { tooltip: { placement: 'bottom' } } }),
    required: true,
    editable: true,
    sortable: false,
    width: 100,
    align: 'left',
  },
  {
    field: 'clayPercentage',
    headerName: 'Arcilla [%]',
    headerAlign: 'center',
    type: 'number',
    validate: ({ value, _ }) => validatePercentageNumberRequired(value),
    renderCell: params => renderText({ params, props: { tooltip: { placement: 'bottom' } } }),
    renderEditCell: params => renderInput({ params, props: { tooltip: { placement: 'bottom' } } }),
    required: true,
    editable: true,
    sortable: false,
    width: 100,
    align: 'left',
  },
  {
    field: 'fineSandPercentage',
    headerName: 'Arena\nfina [%]',
    headerAlign: 'center',
    type: 'number',
    validate: ({ value, _ }) => validatePercentageNumberRequired(value),
    renderCell: params => renderText({ params, props: { tooltip: { placement: 'bottom' } } }),
    renderEditCell: params => renderInput({ params, props: { tooltip: { placement: 'bottom' } } }),
    required: true,
    editable: true,
    sortable: false,
    width: 100,
    align: 'left',
  },
  {
    field: 'coarseSandPercentage',
    headerName: 'Arena\ngruesa [%]',
    headerAlign: 'center',
    type: 'number',
    validate: ({ value, _ }) => validatePercentageNumberRequired(value),
    renderCell: params => renderText({ params, props: { tooltip: { placement: 'bottom' } } }),
    renderEditCell: params => renderInput({ params, props: { tooltip: { placement: 'bottom' } } }),
    required: true,
    editable: true,
    sortable: false,
    width: 100,
    align: 'left',
  },
  {
    field: 'sandPercentage',
    headerName: 'Arena [%]',
    headerAlign: 'center',
    type: 'number',
    validate: ({ value, _ }) => validatePercentageNumberRequired(value),
    renderCell: params => renderText({ params, props: { tooltip: { placement: 'bottom' } } }),
    renderEditCell: params => renderInput({ params, props: { tooltip: { placement: 'bottom' } } }),
    required: true,
    editable: true,
    sortable: false,
    width: 100,
    align: 'left',
  },
  {
    field: 'siltPercentage',
    headerName: 'Limo [%]',
    headerAlign: 'center',
    type: 'number',
    validate: ({ value, _ }) => validatePercentageNumberRequired(value),
    renderCell: params => renderText({ params, props: { tooltip: { placement: 'bottom' } } }),
    renderEditCell: params => renderInput({ params, props: { tooltip: { placement: 'bottom' } } }),
    required: true,
    editable: true,
    sortable: false,
    width: 100,
    align: 'left',
  },
]

const SOCSampleDialog = ({ farm, socSample, open, handleClose, readOnly }) => {
  const { processLoading } = useProcessLoading()
  const [isEditingMode, setIsEditingMode] = useState(false)
  const { programConfig, partialChanges, setPartialChanges, setConfirmationModalConfig } =
    useContext(AppContext)
  const [formData, setFormData] = useState(null)
  const [formDefinition, setFormDefinition] = useState(null)
  const [fieldInputsFromFormDefinition, setFieldInputsFromFormDefinition] = useState(null)
  const [monitoringSOCSitesColumns, setMonitoringSOCSitesColumns] = useState(null)
  const [monitoringSOCSitesSamples, setMonitoringSOCSitesSamples] = useState(null)
  const [monitoringSOCSamplingAreasSamples, setMonitoringSOCSamplingAreasSamples] = useState(null)
  const [monitoringSOCSamplingAreasSamplesColumns, setMonitoringSOCSamplingAreasSamplesColumns] =
    useState(null)

  const loadMonitoringSOCSitesColumns = useCallback(
    async ({ token }) => {
      const completedSOCActivities = await ruutsApi.monitoring.activities.getMonitoringActivities({
        farmId: farm.id,
        taskStatusId: monitoringTaskStatusTypes.Finished,
        monitoringWorkflowId: [monitoringWorkflowsTypes.SOC, monitoringWorkflowsTypes.SOCPOA],
        token,
      })
      const dapMethods = await localRefData.getDataByKey(localRefData.keys.dapMethod)

      return [
        {
          ...monitoringSOCColumn,
          valueOptions: completedSOCActivities?.map(completedSOCActivity => ({
            value: completedSOCActivity.id,
            label: completedSOCActivity.key,
          })) || [''],
        },
        {
          ...dapMethodColumn,
          valueOptions: dapMethods?.map(dapMethod => {
            return { value: dapMethod.id, label: dapMethod?.es_AR || '' }
          }) || [''],
        },
        ...monitoringSOCSitesFixedColumns,
      ]
    },
    [farm?.id],
  )

  const loadMonitoringSOCSamplingAreasSamplesColumns = useCallback(
    async ({ token }) => {
      const samplingAreas = await ruutsApi.samplingAreas.getUncroppedByFarm({
        farmId: farm.id,
        token,
      })

      const soilTextures = await localRefData.getDataByKey(localRefData.keys.soilTexture)

      return [
        {
          ...samplingAreasColumn,
          valueOptions: samplingAreas?.map(samplingArea => {
            return { value: samplingArea.id, label: samplingArea.name }
          }) || [''],
        },
        ...monitoringSOCSamplingAreasFixedColumns,
        {
          ...soilTexturesColumn,
          valueOptions: soilTextures?.map(soilTexture => {
            return { value: soilTexture.id, label: soilTexture?.es_AR_long || '' }
          }) || [''],
        },
      ]
    },
    [farm.id],
  )

  useEffect(() => {
    async function getFormDefinitions() {
      await processLoading({
        loadingMessage: 'Cargando información de monitoreo suelos...',
        successfulMessage: '',
        doAction: async ({ token }) => {
          const monitoringSOCSampleFD = await getMonitoringSOCSampleFormDefinition(
            programConfig,
            token,
          )
          // Get documents types from file input types
          const newFieldInputsFromFormDefinition = monitoringSOCSampleFD.fields.filter(
            field => field.component_type === 'FileInput',
          )

          let formDataFromDocumentsEntity = {}
          if (socSample) {
            formDataFromDocumentsEntity = { ...socSample }

            // Generate form data from documents entities
            if (socSample.documents) {
              newFieldInputsFromFormDefinition.forEach(fieldInputFromFormDefinition => {
                const documentFromEntityInFieldDefinition = socSample.documents
                  .filter(documentFromEntity => {
                    return (
                      fieldInputFromFormDefinition.fileType === documentFromEntity.documentTypeId
                    )
                  })
                  .map(documentFromEntity => documentFromEntity.url)
                formDataFromDocumentsEntity[fieldInputFromFormDefinition.name] =
                  documentFromEntityInFieldDefinition
              })
            }
          }

          setMonitoringSOCSitesColumns(await loadMonitoringSOCSitesColumns({ token }))
          setMonitoringSOCSitesSamples(socSample?.monitoringSOCSitesSamples || [])

          setMonitoringSOCSamplingAreasSamplesColumns(
            await loadMonitoringSOCSamplingAreasSamplesColumns({ token }),
          )
          setMonitoringSOCSamplingAreasSamples(socSample?.monitoringSOCSamplingAreaSamples || [])

          setFormData(formDataFromDocumentsEntity)
          setFormDefinition(monitoringSOCSampleFD)
          setFieldInputsFromFormDefinition(newFieldInputsFromFormDefinition)
        },
      })
    }

    if (open) {
      getFormDefinitions()
    }
  }, [
    open,
    socSample,
    programConfig,
    farm,
    processLoading,
    loadMonitoringSOCSitesColumns,
    loadMonitoringSOCSamplingAreasSamplesColumns,
  ])

  const handlePartialChangesClose = () => {
    handleClose()
    setPartialChanges(false)
  }

  const validateRows = ({ rows, columns }) => {
    const validatedRows = rows.map(row => {
      const errors = {}
      columns
        .filter(column => column.validate)
        .forEach(column => {
          const { field } = column
          const error = column.validate({ value: row[field], row })
          if (error) errors[field] = error
        })
      return { ...row, errors }
    })
    return { hasErrors: !validatedRows.find(row => Object.keys(row?.errors).length), validatedRows }
  }

  const handleSaveFormData = async data => {
    const {
      hasErrors: hasErrorMonitoringSOCSitesSamples,
      validatedRows: validatedMonitoringSOCSitesSamples,
    } = validateRows({ rows: monitoringSOCSitesSamples, columns: monitoringSOCSitesColumns })

    const {
      hasErrors: hasErrorMonitoringSOCSamplingAreasSamples,
      validatedRows: validatedMonitoringSOCSamplingAreasSamples,
    } = validateRows({
      rows: monitoringSOCSamplingAreasSamples,
      columns: monitoringSOCSamplingAreasSamplesColumns,
    })

    if (!hasErrorMonitoringSOCSitesSamples || !hasErrorMonitoringSOCSamplingAreasSamples) {
      setMonitoringSOCSitesSamples(validatedMonitoringSOCSitesSamples)
      setMonitoringSOCSamplingAreasSamples(validatedMonitoringSOCSamplingAreasSamples)
      return
    }

    const convertRowToEntity = ({ rows }) => {
      return rows.map(row => {
        const { isNew, id, ...rest } = row
        return isNew ? rest : row
      })
    }

    await processLoading({
      loadingMessage: 'Guardando resultados...',
      successfulMessage: 'Resultados guardados',
      doAction: async ({ token }) => {
        const socSampleToSave = {
          ...data,
          farmId: farm.id,
          monitoringSOCSitesSamples: convertRowToEntity({
            rows: validatedMonitoringSOCSitesSamples,
          }),
          monitoringSOCSamplingAreasSamples: convertRowToEntity({
            rows: validatedMonitoringSOCSamplingAreasSamples,
          }),
        }
        if (!socSample?.id) {
          await ruutsApi.socSamples.createSOCSample({ socSample: socSampleToSave, token })
        } else {
          await ruutsApi.socSamples.updateSOCSample({
            socSample: { id: socSample.id, ...socSampleToSave },
            token,
          })
        }
        handlePartialChangesClose()
      },
    })
  }

  const handleCloseAndReset = () => {
    if (partialChanges) {
      setConfirmationModalConfig({
        open: true,
        confirmAction: () => {
          setFormData(null)
          handlePartialChangesClose()
        },
      })
    } else {
      setFormData(null)
      handleClose()
    }
  }

  return (
    formData &&
    formDefinition?.fields?.length > 0 &&
    fieldInputsFromFormDefinition && (
      <FormDefinitionDialog
        data={formData}
        formDefinition={formDefinition}
        handleClose={handleCloseAndReset}
        isEditingMode={isEditingMode}
        open={open}
        readOnly={readOnly}
        saveFormData={handleSaveFormData}
      >
        <Stack direction="column" sx={{ alignItems: 'center', justifyContent: 'center' }}>
          <DataGrid
            key="soc-sites-grid"
            hideFooter
            addButtonCaption="Agregar sitio"
            columns={monitoringSOCSitesColumns}
            rows={monitoringSOCSitesSamples}
            setIsEditingMode={setIsEditingMode}
            setRows={setMonitoringSOCSitesSamples}
            title="Actividades SOC completas por sitio de monitoreo"
          />
          <DataGrid
            key="soc-sampling-grid"
            hideFooter
            addButtonCaption="Agregar estrato"
            columns={monitoringSOCSamplingAreasSamplesColumns}
            rows={monitoringSOCSamplingAreasSamples}
            setIsEditingMode={setIsEditingMode}
            setRows={setMonitoringSOCSamplingAreasSamples}
            title="Actividades SOC completas compuestas por estrato"
          />
        </Stack>
      </FormDefinitionDialog>
    )
  )
}

export default SOCSampleDialog
