import { useState, useEffect, useCallback } from 'react'
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  Box,
  Chip,
  ButtonGroup,
} from '@mui/material'
import { Check, Edit, Warning } from '@mui/icons-material'
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form'
import SelectHubUser from '../SelectHubUser'
import { localRefData } from '../../../services/localRefData/index'
import CoordinatesInput from '../../CoordinatesInputField/CoordinatesInputField'
import LocationMovedModal from './LocationMovedModal'
import getDistanceUtm from '../../../services/geoLocations/getDistanceUtm'
import validateInputStringCoordinates from '../../../services/geoLocations/validateInputStringCoordinates'

const SOC_WORKFLOWS = [0, 3]

const ActivityAssignmentForm = ({
  monitoringSite,
  hubUsers,
  setEventData,
  eventData,
  markerRef,
  setWaypoints,
  waypoints,
  assignedTo,
}) => {
  const [_picture, setPicture] = useState(null)
  const [activityWorkflows, setActivityWorkflows] = useState([])
  const [selectedConfirmationSite, setSelectedConfirmationSite] = useState(
    eventData[monitoringSite.id]?.confirmationSite?.id || '',
  )
  const [showLocationMovedReason, setShowLocationMovedReason] = useState(null)
  const [modalWasShown, setModalWasShown] = useState(false)
  const [allowActivityAssignment, setAllowActivityAssignment] = useState(false)
  const [distanceToPlannedLocation, setDistanceToPlannedLocation] = useState(null)
  const { control, setValue, getValues, handleSubmit, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    defaultValues:
      monitoringSite.id in eventData
        ? eventData[monitoringSite.id]
        : {
            id: monitoringSite.id,
            lat: monitoringSite.actualLocation?.[0] ?? '',
            lng: monitoringSite.actualLocation?.[1] ?? '',
            actualLocation: monitoringSite.actualLocation ?? '',
            name: monitoringSite.name,
            locationConfirmed: monitoringSite.locationConfirmed ?? false,
            locationMoved: monitoringSite.locationMoved ?? false,
            locationMovedReasonId: monitoringSite.locationMovedReasonId ?? '',
            locationMovedComments: monitoringSite.locationMovedComments ?? '',
            monitoringActivities: [],
            confirmationSiteId: '',
          },
  })

  const siteWatch = useWatch({ control })
  const getSiteSelectOptions = site => {
    const options = site.possibleConfirmationSites
    return options
  }

  const getActivityWorkflows = useCallback(async () => {
    const programConfig = await localRefData.getDataByKey('programConfig')
    const workflows = await localRefData.getDataByKey('workflows')
    const allowedWorkflows = workflows.filter(workflow => {
      return programConfig.monitoringWorkflowIds.includes(workflow.id) && workflow.siteRequired
    })
    setActivityWorkflows(allowedWorkflows)
  }, [])

  const saveForm = data => {
    if (data.monitoringActivities.some(activity => activity.enabled)) {
      setEventData(s => ({ ...s, [monitoringSite.id]: data }))
      const usedWaypoint = waypoints.find(wp => wp.id === data.confirmationSite.id)
      if (usedWaypoint) {
        usedWaypoint.used = true
        setWaypoints(prev => [
          ...prev.filter(prevWaypoint => prevWaypoint.id !== usedWaypoint.id),
          usedWaypoint,
        ])
      }
    } else {
      setEventData(s => {
        const update = { ...s }
        delete update[monitoringSite.id]
        return update
      })
      const usedWaypoint = waypoints.find(wp => wp.id === data.confirmationSite.id)
      if (usedWaypoint) {
        usedWaypoint.used = false
        setWaypoints(prev => [
          ...prev.filter(prevWaypoint => prevWaypoint.id !== usedWaypoint.id),
          usedWaypoint,
        ])
      }
    }
    markerRef.closePopup()
  }

  const handleResetActivityForm = () => {
    setEventData(s => {
      const update = { ...s }
      delete update[monitoringSite.id]
      return update
    })
    if (siteWatch.confirmationSite && siteWatch.confirmationSite.id !== 'manual') {
      const usedWaypoint = waypoints.find(wp => wp.id === siteWatch.confirmationSite.id)
      if (usedWaypoint) {
        usedWaypoint.used = false
        setWaypoints(prev => [
          ...prev.filter(prevWaypoint => prevWaypoint.id !== usedWaypoint.id),
          usedWaypoint,
        ])
      }
    }
    reset()
  }

  const handleSiteUpdateInput = async ({ site }) => {
    if (site.id === 'manual' || !site.withinRange) {
      setValue('locationMoved', true)
      setValue('locationConfirmationTypeId', 2) // Manual
      setValue('fieldRelocationMethodId', 1) // Manual
    } else {
      setValue('locationMoved', false)
      setValue('locationMovedReasonId', '')
      setValue('locationMovedComments', '')
      setValue('locationMovedReasonName', '')
      setValue('locationConfirmationTypeId', 1) // External GPS
      setValue('fieldRelocationMethodId', 1) // Manual
    }
    setValue('lat', site.lat, { shouldValidate: true })
    setValue('lng', site.lng, { shouldValidate: true })
    setValue('actualLocation', [site.lat, site.lng])
    setValue('confirmationSite', site)
  }

  const handleConfirmationSiteChange = event => {
    const site =
      event.target.value === 'manual'
        ? { type: 'manual', id: 'manual' }
        : getSiteSelectOptions(monitoringSite)?.find(options => options.id === event.target.value)
    setSelectedConfirmationSite(site)
    setModalWasShown(false)

    if (site.id !== 'manual') {
      setShowLocationMovedReason(!site.withinRange)
    }
    handleSiteUpdateInput({ site })
  }

  const checkAllowActivityAssignment = useCallback(() => {
    if (siteWatch.locationConfirmed) {
      return setAllowActivityAssignment(false)
    }
    if (!siteWatch.confirmationSiteId) {
      return setAllowActivityAssignment(false)
    }
    if (siteWatch.confirmationSiteId && !siteWatch?.actualLocation) {
      return setAllowActivityAssignment(false)
    }
    if (
      siteWatch.confirmationSiteId &&
      ((selectedConfirmationSite.id === 'manual' && distanceToPlannedLocation > 10) ||
        !selectedConfirmationSite.withinRange) &&
      [null, undefined, ''].includes(siteWatch?.locationMovedReasonId)
    ) {
      return setAllowActivityAssignment(false)
    }
    return setAllowActivityAssignment(true)
  }, [selectedConfirmationSite, siteWatch, distanceToPlannedLocation])

  useEffect(() => {
    if (
      siteWatch.confirmationSiteId === 'manual' &&
      siteWatch.actualLocation &&
      Array.isArray(siteWatch.actualLocation) &&
      validateInputStringCoordinates(siteWatch.actualLocation.join(','))
    ) {
      const { distance } = getDistanceUtm(
        { lat: monitoringSite.plannedLocation[0], lng: monitoringSite.plannedLocation[1] },
        { lat: siteWatch.actualLocation[0], lng: siteWatch.actualLocation[1] },
      )
      setDistanceToPlannedLocation(distance)

      if (
        distance > 10 &&
        !modalWasShown &&
        [null, undefined, ''].includes(siteWatch.locationMovedReasonId)
      ) {
        setShowLocationMovedReason(true)
        setModalWasShown(true)
      }
    }
  }, [siteWatch, monitoringSite, modalWasShown])

  useEffect(() => {
    checkAllowActivityAssignment()
  }, [checkAllowActivityAssignment])

  useEffect(() => {
    getActivityWorkflows()
  }, [getActivityWorkflows])

  return (
    <Stack
      flex
      alignContent="center"
      component="form"
      direction="column"
      spacing={2}
      width="300px"
      onSubmit={handleSubmit(saveForm)}
    >
      <FormProvider {...{ control, getValues, setValue }}>
        <Stack alignItems="start" direction="row" justifyContent="space-between" spacing={2}>
          <Stack>
            <Typography variant="h6">{monitoringSite.name}</Typography>
            <Typography textOverflow="ellipsis" variant="bodySmall" whiteSpace="nowrap">
              <b>LAT</b>: {monitoringSite.actualLocation?.[0] || monitoringSite.plannedLocation[0]}
            </Typography>
            <Typography textOverflow="ellipsis" variant="bodySmall" whiteSpace="nowrap">
              <b>LNG</b>: {monitoringSite.actualLocation?.[1] || monitoringSite.plannedLocation[1]}
            </Typography>
          </Stack>
          <Stack alignItems="center" direction="row" spacing={1}>
            <Chip
              color="primary"
              label={monitoringSite.isRandomSite ? 'Aleatorio' : 'Manual'}
              size="small"
              variant="outlined"
            />

            <Tooltip
              arrow
              title={monitoringSite.locationConfirmed ? 'Sitio confirmado' : 'Sitio sin confirmar'}
            >
              <IconButton>
                {monitoringSite.locationConfirmed ? (
                  <Check color="success" />
                ) : (
                  <Warning color="warning" />
                )}
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
        <Divider />
        <Box>
          <Box display="flex" flexDirection="column" rowGap={2}>
            <Controller
              control={control}
              name="confirmationSiteId"
              render={({ field }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="site-select-option">Sitio ejecutado</InputLabel>
                  <Select
                    {...field}
                    label={<Typography variant="caption">Sitio ejecutado</Typography>}
                    labelId="site-select-option"
                    onChange={event => {
                      field.onChange(event)
                      handleConfirmationSiteChange(event)
                    }}
                  >
                    <MenuItem disabled value="">
                      Seleccionar un sitio...
                    </MenuItem>
                    {getSiteSelectOptions(monitoringSite)?.map(option => (
                      <MenuItem
                        key={option.id}
                        disabled={waypoints.some(wp => wp.id === option.id && wp.used)}
                        value={option.id}
                      >{`${option.type.toUpperCase()} - ${option.name || 'Sin nombre'} - ${option.distance.toFixed(2)} mts`}</MenuItem>
                    ))}
                    <MenuItem key="manual" value="manual">
                      Ingresar manualmente...
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />

            <CoordinatesInput
              readOnly={selectedConfirmationSite?.id !== 'manual'}
              selectedConfirmationSite={selectedConfirmationSite}
              size="small"
            />
            {distanceToPlannedLocation !== null && (
              <Typography variant="bodySmall">
                <b>Distancia a la ubicación planificada:</b> {distanceToPlannedLocation.toFixed(2)}
                mts
              </Typography>
            )}
          </Box>
        </Box>
        {showLocationMovedReason && (
          <LocationMovedModal
            handleClose={() => setShowLocationMovedReason(null)}
            open={!!(showLocationMovedReason && selectedConfirmationSite)}
            setPicture={setPicture}
          />
        )}
        {siteWatch.confirmationSiteId && (
          <Stack alignItems="left" mt={1} spacing={0.5}>
            {(!selectedConfirmationSite.withinRange || selectedConfirmationSite.id === 'manual') &&
              distanceToPlannedLocation > 10 &&
              siteWatch && (
                <Grid container alignItems="center" justifyContent="space-around" width="100%">
                  <Grid item xs={11}>
                    <Stack>
                      <Typography
                        color={
                          [null, undefined, ''].includes(siteWatch?.locationMovedReasonId)
                            ? 'error'
                            : ''
                        }
                        textOverflow="ellipsis"
                        variant="bodySmall"
                        whiteSpace="nowrap"
                      >
                        <b>Motivo de relocalización:</b> {siteWatch.locationMovedReasonName}
                      </Typography>
                      <Typography
                        color={
                          [null, undefined, ''].includes(siteWatch?.locationMovedReasonId)
                            ? 'error'
                            : ''
                        }
                        overflow="hidden"
                        textOverflow="ellipsis"
                        variant="bodySmall"
                        whiteSpace="nowrap"
                      >
                        <b>Comentarios:</b> {siteWatch.locationMovedComments}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton onClick={() => setShowLocationMovedReason(true)}>
                      <Edit
                        color={
                          [null, undefined, ''].includes(siteWatch?.locationMovedReasonId)
                            ? 'error'
                            : ''
                        }
                        fontSize="small"
                      />
                    </IconButton>
                  </Grid>

                  <Divider />
                </Grid>
              )}
            {selectedConfirmationSite.id !== 'manual' && (
              <Typography variant="bodySmall">
                <b>Fecha</b> {selectedConfirmationSite.timestamp || 'N/D'}
              </Typography>
            )}
          </Stack>
        )}
        <FormControl disabled={!allowActivityAssignment} size="small">
          <Stack flex spacing={1} width="100%">
            {hubUsers &&
              activityWorkflows &&
              activityWorkflows.length > 0 &&
              activityWorkflows.map((workflow, index) => (
                <FormControl
                  key={workflow.id}
                  disabled={SOC_WORKFLOWS.includes(workflow.id) && !monitoringSite.isRandomSite}
                  size="small"
                >
                  <Grid
                    key={workflow.id}
                    container
                    direction="row"
                    justifyContent="space-between"
                    width="300px"
                  >
                    <Controller
                      control={control}
                      defaultValue={workflow.id}
                      name={`monitoringActivities[${index}].monitoringWorkflowId`}
                      render={({ field }) => <input type="hidden" {...field} value={field.value} />}
                    />
                    <Controller
                      control={control}
                      defaultValue={workflow.name}
                      name={`monitoringActivities[${index}].monitoringWorkflowName`}
                      render={({ field }) => <input type="hidden" {...field} value={field.value} />}
                    />
                    <Grid item xs={3}>
                      <Controller
                        control={control}
                        name={`monitoringActivities[${index}].enabled`}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...field}
                                checked={field.value ?? false}
                                onChange={(event, checked) => {
                                  field.onChange(checked)
                                }}
                              />
                            }
                            label={workflow.name}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <Controller
                        control={control}
                        defaultValue={assignedTo}
                        name={`monitoringActivities[${index}].assignedTo`}
                        render={({ field, fieldState }) => (
                          <SelectHubUser
                            readOnly
                            disabled={!allowActivityAssignment}
                            field={field}
                            fieldState={fieldState}
                            hubUsers={hubUsers}
                            monitoringSite={monitoringSite}
                            monitoringWorkflowId={workflow.id}
                          />
                        )}
                        rules={{
                          required: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              ))}
          </Stack>
          <ButtonGroup fullWidth>
            <Button
              color="error"
              sx={{ marginTop: '20px' }}
              variant="outlined"
              onClick={() => handleResetActivityForm()}
            >
              Limpiar
            </Button>
            <Button
              color="primary"
              disabled={
                !siteWatch.monitoringActivities ||
                !siteWatch.monitoringActivities.some(activity => activity.enabled) ||
                (((selectedConfirmationSite.id === 'manual' && distanceToPlannedLocation > 10) ||
                  !selectedConfirmationSite.withinRange) &&
                  [null, undefined, ''].includes(siteWatch?.locationMovedReasonId))
              }
              sx={{ marginTop: '20px' }}
              type="submit"
              variant="contained"
            >
              GUARDAR
            </Button>
          </ButtonGroup>
        </FormControl>
      </FormProvider>
    </Stack>
  )
}

export default ActivityAssignmentForm
