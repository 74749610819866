import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material'
import moment from 'moment'
import { useEventCreation } from '../../../hooks/useEventCreation'
import { useCustomSnackbarError } from '../../../utils/Snackbar/useCustomSnackbarError'
import FileUploadStep from './FileUploadStep'
import ActivitiesStep from './ActivitiesStep'
import ConfirmationStep from './ConfirmationStep'

const STEPS = [
  {
    id: 0,
    label: 'Datos del evento',
    isFirstStep: true,
    isLastStep: false,
  },
  {
    id: 1,
    label: 'Asignar actividades',
    isFirstStep: false,
    isLastStep: false,
  },
  {
    id: 2,
    label: 'Confirmar',
    isFirstStep: false,
    isLastStep: true,
  },
]

const CreateEventDialog = ({ open, handleClose, getFarmData }) => {
  const [activeStep, setActiveStep] = useState(0)
  const { handleError } = useCustomSnackbarError()

  const eventCreation = useEventCreation(() => {
    handleClose()
    getFarmData()
  })

  const {
    loading,
    uploadError,
    eventData,
    eventStartDate,
    eventEndDate,
    file,
    hasGpxFile,
    monitoringSitesWithTrackPoints,
    waypoints,
    hubUsers,
    assignedTo,
    currentFarm,
    setEventData,
    setEventStartDate,
    setEventEndDate,
    setFile,
    setAssignedTo,
    setHasGpxFile,
    setUploadError,
    setMonitoringSitesWithTrackPoints,
    setWaypoints,
    setLoading,

    fetchHubUsers,
    getMonitoringSites,
    validateGpxFile,
    createEvent,
    resetForm,
  } = eventCreation

  useEffect(() => {
    if (open) {
      fetchHubUsers()
    }
  }, [open, fetchHubUsers])

  const handleNext = async () => {
    if (activeStep === STEPS[0].id) {
      if (file && hasGpxFile) {
        const isValid = await validateGpxFile()
        if (isValid) {
          setActiveStep(STEPS[1].id)
        }
      } else {
        try {
          setLoading(true)
          const sites = await getMonitoringSites()
          if (sites.length > 0) {
            setMonitoringSitesWithTrackPoints(
              sites.map(site => ({
                ...site,
                trackPoints: [],
              })),
            )
            setWaypoints([])
            setActiveStep(STEPS[1].id)
          } else {
            throw new Error('No hay sitios de monitoreo disponibles')
          }
        } catch (error) {
          setUploadError(error)
          handleError(error, error.message, false)
        } finally {
          setLoading(false)
        }
      }
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleCancel = () => {
    resetForm()
    setActiveStep(0)
    handleClose()
  }

  const renderStep = () => {
    switch (activeStep) {
      case 0:
        return (
          <FileUploadStep
            assignedTo={assignedTo}
            eventEndDate={eventEndDate}
            eventStartDate={eventStartDate}
            file={file}
            handleAssignedToChange={setAssignedTo}
            handleGpxOptionChange={event => {
              const value = event.target.value === 'true'
              setHasGpxFile(value)
              if (!value) {
                setFile(null)
                setUploadError(null)
              }
            }}
            hasGpxFile={hasGpxFile}
            hubUsers={hubUsers}
            loading={loading}
            setEventEndDate={setEventEndDate}
            setEventStartDate={setEventStartDate}
            setFile={setFile}
            setUploadError={setUploadError}
            uploadError={uploadError}
          />
        )
      case 1:
        return (
          <ActivitiesStep
            assignedTo={assignedTo}
            currentFarm={currentFarm}
            eventData={eventData}
            file={file}
            monitoringSitesWithTrackPoints={monitoringSitesWithTrackPoints}
            setEventData={setEventData}
            setMonitoringSites={setMonitoringSitesWithTrackPoints}
            setWaypoints={setWaypoints}
            waypoints={waypoints}
          />
        )
      case 2:
        return <ConfirmationStep eventData={eventData} setEventData={setEventData} />
      default:
        return null
    }
  }

  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      maxWidth="xl"
      open={open}
      PaperProps={{
        sx: {
          height: '97vh',
        },
      }}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose()
        }
      }}
    >
      <DialogTitle>
        Crear Evento - {eventStartDate ? moment(eventStartDate).format('MMM D, YYYY') : ''}
      </DialogTitle>
      <DialogContent sx={{ height: '95%', overflow: 'hidden' }}>
        <Stepper activeStep={activeStep}>
          {STEPS.map(step => (
            <Step key={step.id}>
              <StepLabel
                StepIconProps={{
                  sx: {
                    '&.Mui-completed': {
                      color: 'success.main',
                      '& .MuiStepIcon-completedIcon': {
                        color: 'white',
                      },
                    },
                  },
                }}
              >
                {step.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        {renderStep()}
      </DialogContent>

      <DialogActions>
        {!STEPS[activeStep].isFirstStep && <Button onClick={handleBack}>Atrás</Button>}
        <Button onClick={handleCancel}>Cancelar</Button>

        {!STEPS[activeStep].isLastStep && (
          <Button
            disabled={
              (activeStep === STEPS[0].id && hasGpxFile && !file) ||
              loading ||
              !assignedTo ||
              !eventStartDate ||
              !eventEndDate
            }
            onClick={handleNext}
          >
            Siguiente
          </Button>
        )}

        {STEPS[activeStep].isLastStep && (
          <Button
            disabled={
              eventData == null || eventData === undefined || Object.values(eventData).length === 0
            }
            variant="contained"
            onClick={createEvent}
          >
            Guardar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default CreateEventDialog
