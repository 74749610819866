import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import { Edit } from '@mui/icons-material'

const EditIconButtonBase = ({ children, ...iconProps }) => {
  return (
    <IconButton color="primary" size="small" {...iconProps}>
      <Edit />
    </IconButton>
  )
}

export const EditIconButton = ({ showTooltip, tooltipTitle, children, ...iconProps }) => {
  return showTooltip ? (
    <Tooltip title={tooltipTitle}>
      <div>
        {/* This div is needed because a child of a Material-UI Tooltip must be able to hold a ref. 
        Otherwise the tooltip is not showed */}
        <EditIconButtonBase {...iconProps}>{children}</EditIconButtonBase>
      </div>
    </Tooltip>
  ) : (
    <EditIconButtonBase {...iconProps}>{children}</EditIconButtonBase>
  )
}
