/* eslint-disable react/jsx-no-duplicate-props */
import { Box, FormHelperText, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import ReactMarkdown from 'react-markdown'
import lfStore from '../../../lfstore/lfStore'
import { helpersGetters } from '../../../utils/dataCollection/helpersGetters/index'
import { checkDependencies } from '../utils'

const TextFieldWrapper = ({ control, formField, rules, groupIndex, readOnly }) => {
  const { watch, setError, setValue, getValues } = useFormContext()
  const [helperText, setHelperText] = useState(formField.helperText)
  const value = formField.helperTextFromField?.sourceField ? watch(formField.name) : null
  const sourceValues = formField.helperTextFromField?.sourceField
    ? watch(formField.helperTextFromField?.sourceField)
    : null
  const sourceArrayValues = formField.helperTextFromField?.sourceFieldArray
    ? watch(formField.helperTextFromField?.sourceFieldArray)
    : null

  const dependenciesFieldNamesValues = formField.dependencies?.value?.length
    ? formField.dependencies?.value
    : null
  const fieldNamesToCheckChangeValues = dependenciesFieldNamesValues
    ? dependenciesFieldNamesValues.flatMap(dep => dep.dependencies).flatMap(dep => Object.keys(dep))
    : null
  const values = fieldNamesToCheckChangeValues?.length ? watch(fieldNamesToCheckChangeValues) : null

  useEffect(() => {
    if (
      !fieldNamesToCheckChangeValues?.length ||
      !dependenciesFieldNamesValues.length ||
      getValues(formField.name) !== formField.defaultValue
    ) {
      return
    }

    const dependencyWithValueToChange = dependenciesFieldNamesValues.find(
      dependencyFieldNamesValues =>
        checkDependencies(dependencyFieldNamesValues.dependencies, 'fieldValueToChange', -1, watch),
    )

    if (dependencyWithValueToChange) {
      setValue(formField.name, dependencyWithValueToChange.value)
    } else {
      setValue(formField.name, formField.defaultValue)
    }
  }, [
    dependenciesFieldNamesValues,
    fieldNamesToCheckChangeValues?.length,
    formField.defaultValue,
    formField.name,
    getValues,
    setValue,
    values,
    watch,
  ])

  function getErrorMsg(error) {
    if (['min', 'max'].includes(error.type)) {
      setError(formField.name, {
        type: 'range',
        message: `Fuera de rango (mín: ${formField.min} - máx: ${formField.max || 'n/a'})`,
      })
    }
    return true
  }

  useEffect(() => {
    if (!sourceValues || !value || !sourceArrayValues?.length) return
    const newHelperText = helpersGetters[formField.helperTextFromField.getterFunction](
      formField.name,
      sourceValues,
      sourceArrayValues,
      value,
    )
    setHelperText(newHelperText)
  }, [formField, value, sourceValues, sourceArrayValues])

  return (
    <Controller
      control={control}
      defaultValue={formField.defaultValue}
      name={`${formField.name}${groupIndex ? `-${groupIndex}` : ''}`}
      render={({ field, fieldState }) => (
        <Box>
          <TextField
            label={formField.placeholder}
            {...field}
            error={fieldState.error ? getErrorMsg(fieldState.error) : false}
            fullWidth={!formField.maxWidth}
            helperText={helperText}
            id={formField.name}
            inputProps={{
              readOnly,
            }}
            InputProps={{
              endAdornment: <Typography variant="body1">{formField.unit}</Typography>,
              type: formField.type,
              min: formField.min,
              max: formField.max || 'null',
            }}
            placeholder=""
            value={field.value}
            variant="outlined"
            onChange={e => {
              if (!readOnly) lfStore.setItem('dirtyForm', true)
              field.onChange(e)
            }}
          />
          <FormHelperText error={fieldState.error} sx={{ px: 1.5 }}>
            <ReactMarkdown>
              {fieldState.error ? fieldState.error.message : formField.helperText}
            </ReactMarkdown>
          </FormHelperText>
        </Box>
      )}
      rules={rules}
      valueAsNumber={formField.type === 'number'}
    />
  )
}

export default TextFieldWrapper
