import axios from 'axios'
import { catchHTTPErrors } from './postApiData'

export default async function getApiData(endpoint, query, token) {
  try {
    const options = {
      url: import.meta.env.VITE_RUUTS_API_URL + endpoint,
      method: 'GET',
      params: query,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await axios.request(options)
    return response.data.payload
  } catch (error) {
    catchHTTPErrors({ endpoint, request: query, error })
    return null
  }
}
