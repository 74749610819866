import { temporaryDisabled } from './temporaryDisabled'
import { unusedRequiredPaddocks, unusedWarningPaddocks } from './unusedPaddocks'
import { unresolvedFindings } from './unresolvedFindings'

export const namespacesValidations = {
  temporaryDisabled,
  unusedRequiredPaddocks,
  unusedWarningPaddocks,
  unresolvedFindings,
}
