import { useState, useEffect, useCallback, useMemo } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Box, IconButton, Typography } from '@mui/material'
import { Delete } from '@mui/icons-material'

const paginationModel = { page: 0, pageSize: 5 }

const ActivitySummaryTable = ({ eventData, setEventData }) => {
  const [rows, setRows] = useState([])

  const handleDelete = useCallback(
    id => {
      setRows(prevRows => prevRows.filter(r => r.id !== id))
      setEventData(prevData => {
        const update = { ...prevData }
        delete update[id]
        return update
      })
    },
    [setEventData],
  )

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        hide: true,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: params => {
          return (
            <Typography
              component="div"
              style={{
                whiteSpace: 'pre-line',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                height: '100%',
              }}
              variant="caption"
            >
              {params?.value ?? '-'}
            </Typography>
          )
        },
      },
      {
        field: 'name',
        headerName: 'Nombre',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: params => {
          return (
            <Typography
              component="div"
              style={{
                whiteSpace: 'pre-line',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                height: '100%',
              }}
              variant="caption"
            >
              {params?.value ?? '-'}
            </Typography>
          )
        },
      },
      {
        field: 'locationMoved',
        headerName: 'Relocalizado',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: params => {
          return (
            <Typography
              component="div"
              style={{
                whiteSpace: 'pre-line',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                height: '100%',
              }}
              variant="caption"
            >
              {params?.value ? 'Si' : 'No'}
            </Typography>
          )
        },
      },
      {
        field: 'actualLocation',
        headerName: 'Coordenadas ejecutadas',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: params => {
          return (
            <Typography
              component="div"
              style={{
                whiteSpace: 'pre-line',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                height: '100%',
              }}
              variant="caption"
            >
              {Array.isArray(params.value) ? params.value.join('\n') : params.value}
            </Typography>
          )
        },
      },
      {
        field: 'locationMovedReasonName',
        headerName: 'Motivo',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: params => {
          return (
            <Typography
              component="div"
              style={{
                whiteSpace: 'pre-line',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                height: '100%',
              }}
              variant="caption"
            >
              {params?.value ?? '-'}
            </Typography>
          )
        },
      },
      {
        field: 'locationMovedComments',
        headerName: 'Comentarios',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: params => {
          return (
            <Typography
              component="div"
              style={{
                whiteSpace: 'pre-line',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                wordWrap: 'pretty',
              }}
              variant="caption"
            >
              {params?.value ?? '-'}
            </Typography>
          )
        },
      },
      {
        field: 'monitoringActivities',
        headerName: 'Actividades',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: params => {
          return (
            <Typography
              component="div"
              style={{
                whiteSpace: 'pre-line',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
              variant="caption"
            >
              {params?.value
                ?.filter(activity => activity.enabled)
                .map(activity => {
                  return `${activity.monitoringWorkflowName} - ${activity.assignedTo}`
                })
                .join('\n')}
            </Typography>
          )
        },
      },
      {
        field: 'actions',
        headerName: '',
        width: 30,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        renderCell: params => {
          return (
            <Box
              sx={{
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <IconButton color="error" size="small" onClick={() => handleDelete(params.row.id)}>
                <Delete fontSize="12" />
              </IconButton>
            </Box>
          )
        },
      },
    ],
    [handleDelete],
  )

  useEffect(() => {
    setRows(Object.values(eventData))
  }, [eventData])

  return (
    <Box
      flex
      alignItems="center"
      flexDirection="column"
      height="600px"
      justifyContent="space-between"
      width="100%"
    >
      <DataGrid
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        checkboxSelection={false}
        columns={columns}
        initialState={{ pagination: { paginationModel } }}
        pageSizeOptions={[5, 10]}
        rowHeight={100}
        rows={rows}
        sx={{
          border: 0,
          '& .MuiDataGrid-cell': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        }}
      />
    </Box>
  )
}

export default ActivitySummaryTable
