import { Box } from '@mui/material'
import { useContext } from 'react'
import { Controller } from 'react-hook-form'
import AppContext from '../../../context/appContext'
import FileInput from '../../FileInput/FileInput'

const FileInputWrapper = ({ year, control, formField, rules, readOnly }) => {
  const { setPartialChanges } = useContext(AppContext)

  function onAdd(field, fileLinks) {
    const newFileLinks = [].concat(field.value, fileLinks)
    field.onChange(newFileLinks)
    setPartialChanges(true)
  }

  function onDelete(field, index) {
    const newFileLinks = [...field.value]
    newFileLinks.splice(index, 1)
    field.onChange(newFileLinks)
    setPartialChanges(true)
  }

  return (
    <Controller
      control={control}
      defaultValue={formField.defaultValue}
      name={formField.name}
      render={({ field }) => (
        <Box>
          <FileInput
            definition={formField}
            files={field.value}
            readOnly={readOnly}
            year={year}
            onAdd={fileLinks => onAdd(field, fileLinks)}
            onDelete={index => onDelete(field, index)}
          />
        </Box>
      )}
      rules={rules}
      valueAsNumber={formField.type === 'number'}
    />
  )
}

export default FileInputWrapper
