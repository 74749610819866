import BlockIcon from '@mui/icons-material/Block'
import EditIcon from '@mui/icons-material/Edit'
import { Tooltip } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { useUserRole } from '../../hooks/useUserRole'
import { localRefData } from '../../services/localRefData/index'
import { errors } from '../../services/ruutsApi/errors'
import { ruutsApi } from '../../services/ruutsApi/index'
import { metricEventStatus } from '../../utils/constants'
import { useProcessLoadingWithConfirmation } from '../../utils/Loading/useProcessLoadingWithConfirmation'
import { messages } from '../../utils/messages/index'

const statusesTransition = {
  [metricEventStatus.Open]: metricEventStatus.Close,
  [metricEventStatus.Close]: metricEventStatus.Open,
}

export const MetricEventStatusRowData: FC<{
  metricId: string
  statusId: number
  handleMetricReset: () => void
}> = ({ metricId, statusId, handleMetricReset }) => {
  const [statuses, setStatuses] = useState(null)
  const [tooltipTitle, setTooltipTitle] = useState(null)
  const { processLoadingWithConfirmation } = useProcessLoadingWithConfirmation()
  const { isAdmin } = useUserRole()

  useEffect(() => {
    async function fetchStatus(): Promise<void> {
      const newStatuses = await localRefData.getDataByKey(localRefData.keys.metricEventStatus)
      setStatuses(newStatuses)
    }
    fetchStatus()
  }, [])

  useEffect(() => {
    if (statusId === null || !statuses) return

    const status = statuses.find(s => s.id === statusId)
    setTooltipTitle(status?.[messages.getLangKey()] || '')
  }, [statuses, statusId])

  const { cursor, handleClick } = isAdmin
    ? {
        cursor: 'pointer',
        handleClick: async (id: string): Promise<void> => {
          await processLoadingWithConfirmation({
            confirmationTitle: 'Confirmar cambio de estado',
            confirmationMessage: `¿Está seguro que desea ${statusId === metricEventStatus.Open ? 'cerrar' : 'abrir'} la métrica?`,
            loadingMessage: 'Cambiando estado...',
            errorMessage: 'Error al cambiar el estado de la métrica.',
            doAction: async ({ token, dryRun }) => {
              if (dryRun) return

              const newMetricEventStatus = statusesTransition[statusId]
              if (newMetricEventStatus === undefined) {
                throw new errors.BusinessEntityError({
                  message: 'Estado de métrica desconocida',
                  request: {
                    statusId,
                    metricId,
                  },
                  needSupport: false,
                  response: null,
                })
              }

              await ruutsApi.saveMetricEventStatus({
                metricEventId: id,
                metricEventStatusId: newMetricEventStatus,
                token,
              })
              handleMetricReset()
            },
          })
        },
      }
    : { cursor: 'default', handleClick: (): void => null }

  return (
    <Tooltip title={tooltipTitle}>
      {statusId === metricEventStatus.Open ? (
        <EditIcon style={{ color: 'green', cursor }} onClick={() => handleClick(metricId)} />
      ) : (
        <BlockIcon style={{ color: 'red', cursor }} onClick={() => handleClick(metricId)} />
      )}
    </Tooltip>
  )
}
