import endpoints from '../ruutsApi/endpoints'
import { roles } from '../../utils/roleTraduction'
import { messages } from '../../utils/messages/index'
import { errors } from '../ruutsApi/errors'
import getApiData from '../ruutsApi/getApiData'

export const allHubsId = 'all'
const validUserRoles = Object.keys(roles)

async function getFarmsByEndpoint({ id, user, endpoint, token }) {
  let farms = []
  const userRoles = user['/roles']
  // Validates if all the user roles assigned are known in RPD,
  // otherwise returns an empty farms list to prevent a suspicious request
  if (userRoles.every(userRole => validUserRoles.includes(userRole))) {
    const query = {}
    const hubId = user['/hubId']
    // Check if the user does not have all hubs access, then filter by hubId
    if (hubId !== allHubsId && !hubId.includes(allHubsId)) {
      query.hubId = hubId
    }

    const endpointPath = id ? `${endpoint}/${id}` : endpoint
    farms = await getApiData(endpointPath, query, token)
  }
  return farms
}

export default async function getFarms({ user, token }) {
  return getFarmsByEndpoint({ user, endpoint: endpoints.farms, token })
}

export async function getFarmsNameList({ user, token }) {
  return getFarmsByEndpoint({ user, endpoint: endpoints.farmsNames, token })
}

export async function getFarm({ id, user, token }) {
  const farms = await getFarmsByEndpoint({
    id,
    user,
    endpoint: endpoints.farms,
    token,
  })
  if (!farms || !farms.length) {
    throw new errors.BusinessEntityError({
      response: farms,
      message: `No se encontró establecimiento con el id ${id}`,
    })
  }

  if (farms?.length !== 1) {
    throw new errors.BusinessEntityError({
      response: farms,
      message: `Error de configuración, se encontró mas de un negocio relacionado para el establecimiento ${farms[0].name}`,
    })
  }

  const rawFarm = farms[0]
  const farm = {
    ...rawFarm,
    valid: rawFarm?.error === undefined,
    hasPerimeter: rawFarm.geometry != null,
  }
  if (!farm.valid) {
    const keyError = farm.error.key
    throw new errors.BusinessEntityError({
      response: farm,
      message: messages.getMessage(messages.keys[keyError]),
    })
  }

  return farm
}

export async function getFarmProjectArea({ farmId, token }) {
  try {
    const response = await getApiData(`${endpoints.farms}/${farmId}/projectArea`, null, token)
    return response
  } catch (error) {
    console.error(error)
    throw new Error('Unable to get project area')
  }
}
