import * as React from 'react'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import { Box } from '@mui/material'

const CreateEventButton = ({ options, disabled }) => {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const buttonGroupRef = React.useRef(null)
  const [buttonWidth, setButtonWidth] = React.useState(0)

  // Actualizar el ancho del botón cuando se monta el componente
  React.useEffect(() => {
    if (buttonGroupRef.current) {
      setButtonWidth(buttonGroupRef.current.offsetWidth)
    }
  }, [])

  const handleClick = () => {
    options[selectedIndex]?.action()
  }

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index)
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
    // Actualizar el ancho cada vez que se abre el menú
    if (buttonGroupRef.current) {
      setButtonWidth(buttonGroupRef.current.offsetWidth)
    }
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <ButtonGroup
        ref={node => {
          buttonGroupRef.current = node
          anchorRef.current = node
        }}
        aria-label="create event button"
        disabled={disabled}
        variant="contained"
      >
        <Button onClick={handleClick}>{options[selectedIndex]?.label}</Button>
        <Button
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="menu"
          aria-label="select event creation type"
          size="small"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        disablePortal
        transition
        anchorEl={anchorRef.current}
        open={open}
        placement="bottom-start"
        role={undefined}
        sx={{
          zIndex: 999,
          width: `${buttonWidth}px`,
          marginTop: '-1px', // Elimina el espacio entre el botón y el menú
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'top center',
              width: '100%',
            }}
          >
            <Paper
              sx={{
                width: '100%',
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                borderTop: '0px',
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem
                  id="split-button-menu"
                  sx={{ width: '100%', padding: '4px 0' }}
                >
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.label}
                      disabled={index === 2}
                      selected={index === selectedIndex}
                      sx={{ justifyContent: 'center' }}
                      onClick={event => handleMenuItemClick(event, index)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  )
}

export default CreateEventButton
