import endpoints from '../../ruutsApi/endpoints'
import getApiData from '../../ruutsApi/getApiData'

export default async function getMonitoringSites(farmId, token) {
  try {
    const monitoringSites = await getApiData(
      endpoints.sites,
      {
        farmId,
      },
      token,
    )
    return monitoringSites
  } catch (error) {
    console.error(error)
    throw new Error('Unable to get monitoring sites')
  }
}
