import { Box, Grid, IconButton, Typography, Divider, CircularProgress, Stack } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { localRefData } from '../../../services/localRefData'

const ActivityList = ({ eventData, setEventData, markerRefs }) => {
  const [loadingRefData, setLoadingRefData] = useState(false)
  const [locationMovedReasons, setLocationMovedReasons] = useState([])
  const [confirmationTypes, setConfirmationTypes] = useState([])
  const [fieldRelocationMethods, setFieldRelocationMethods] = useState([])

  useEffect(() => {
    async function getLocationMovedOptions() {
      try {
        setLoadingRefData(true)
        const locationMovedReasonRefData = await localRefData.getDataByKey('RefLocationMovedReason')
        const confirmationTypesRefData = await localRefData.getDataByKey(
          'RefLocationConfirmationType',
        )
        const fieldRelocationMethodsRefData = await localRefData.getDataByKey(
          'RefFieldRelocationMethod',
        )

        setLocationMovedReasons(locationMovedReasonRefData)
        setConfirmationTypes(confirmationTypesRefData)
        setFieldRelocationMethods(fieldRelocationMethodsRefData)
      } catch (e) {
        console.error('Failed to load location moved options', e)
      } finally {
        setLoadingRefData(false)
      }
    }
    getLocationMovedOptions()
  }, [])

  return !loadingRefData ? (
    <Box>
      {Object.values(eventData)?.length > 0 ? (
        Object.values(eventData)
          ?.filter(site => site.monitoringActivities?.some(activity => activity.enabled))
          .map(site => (
            <Box
              key={site.id}
              alignItems="center"
              direction="column"
              gap={2}
              rowGap={2}
              width="100%"
            >
              <Grid container alignItems="center" direction="row" spacing={0.5} width="100%">
                <Grid item xs={11}>
                  <Typography variant="bodyBold">{site.name}</Typography>
                </Grid>
                <Grid item xs={1}>
                  {markerRefs ? (
                    <IconButton
                      size="small"
                      onClick={() => {
                        markerRefs.current[site.id].openPopup()
                      }}
                    >
                      <Edit fontSize="12" />
                    </IconButton>
                  ) : (
                    <IconButton
                      size="small"
                      onClick={() => {
                        setEventData(s => {
                          const update = { ...s }
                          delete update[site.id]
                          return update
                        })
                      }}
                    >
                      <Delete fontSize="12" />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
              <Grid container alignItems="center" direction="row" spacing={0.5} width="100%">
                <Grid item xs={12}>
                  <Typography variant="caption">
                    <b>Relocalizado: </b>
                    {site.locationMoved ? 'Si' : 'No'}
                  </Typography>
                </Grid>
                {site.locationMoved && (
                  <Grid item xs={12}>
                    <Stack>
                      {locationMovedReasons?.find(
                        option => option.id === site.locationMovedReasonId,
                      ) && (
                        <Typography variant="caption">
                          <b>Motivo: </b>{' '}
                          {
                            locationMovedReasons.find(
                              option => option.id === site.locationMovedReasonId,
                            ).es_AR
                          }
                        </Typography>
                      )}
                      {fieldRelocationMethods?.find(
                        option => option.id === site.fieldRelocationMethodId,
                      ) && (
                        <Typography variant="caption">
                          <b>Relocalización: </b>{' '}
                          {
                            fieldRelocationMethods?.find(
                              option => option.id === site.fieldRelocationMethodId,
                            )?.es_AR
                          }
                        </Typography>
                      )}
                      {confirmationTypes?.find(
                        option => option.id === site.locationConfirmationTypeId,
                      ) && (
                        <Typography variant="caption">
                          <b>Tipo de confirmación: </b>{' '}
                          {
                            confirmationTypes?.find(
                              option => option.id === site.locationConfirmationTypeId,
                            )?.es_AR
                          }
                        </Typography>
                      )}
                    </Stack>
                  </Grid>
                )}
              </Grid>

              {site.monitoringActivities.length > 0 &&
                site.monitoringActivities
                  ?.filter(activity => activity.enabled)
                  .map(activity => (
                    <Grid
                      key={`${site.id}-${activity.monitoringWorkflowId}`}
                      container
                      alignItems="center"
                      justifyContent="center"
                      pb={0.5}
                    >
                      <Grid item xs={12}>
                        <Typography variant="bodySmall">
                          {`${activity.monitoringWorkflowName} - ${activity.assignedTo}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
              <Divider />
            </Box>
          ))
      ) : (
        <Typography>
          Aún no asignaste actividades. Podés asignarlas haciendo click en los marcadores del mapa.
        </Typography>
      )}
    </Box>
  ) : (
    <Box display="flex" justifyContent="center">
      <CircularProgress />
    </Box>
  )
}

export default ActivityList
