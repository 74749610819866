import endpoints from '../../ruutsApi/endpoints'
import getApiData from '../../ruutsApi/getApiData'

export const getSOCSample = async ({ id, token }) => {
  const result = await getApiData(`${endpoints.monitoringSOCSamples}/${id}`, {}, token)
  return result[0] || null
}

export const getSOCSamples = async ({ farmId, token }) => {
  const socSamples = await getApiData(endpoints.monitoringSOCSamples, { farmId }, token)
  return socSamples.sort((a, b) => (a?.samplingNumberId ?? 0) - (b?.samplingNumberId ?? 0))
}

export const getSOCSitesSamples = async ({ monitoringSOCSampleId, token }) =>
  getApiData(endpoints.monitoringSOCSiteSamples, { monitoringSOCSampleId }, token)
