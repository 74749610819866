import { useState, useEffect, useContext, useCallback } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import AppContext from '../../context/appContext'
import SOCSamplesList from './SOCSamplesList'
import { ruutsApi } from '../../services/ruutsApi/index'
import SOCSampleDialog from './SOCSampleDialog'
import { useProcessLoading } from '../../utils/Loading/useProcessLoading'
import { useProcessLoadingWithConfirmation } from '../../utils/Loading/useProcessLoadingWithConfirmation'

const SOCSample = () => {
  const { currentFarm } = useContext(AppContext)
  const { processLoading } = useProcessLoading()
  const { processLoadingWithConfirmation } = useProcessLoadingWithConfirmation()
  const [socSamples, setSOCSamples] = useState(null)
  const [selectedSOCSample, setSelectedSOCSample] = useState(null)
  const [createSOCSampleView, setCreateSOCSampleView] = useState(false)

  const getFarmData = useCallback(
    async function getFarmData() {
      await processLoading({
        loadingMessage: 'Cargando información de monitoreo de suelos...',
        successfulMessage: '',
        doAction: async ({ token }) => {
          const socSamplesData = await ruutsApi.socSamples.getSOCSamples({
            farmId: currentFarm.id,
            token,
          })

          setSOCSamples(socSamplesData)
        },
      })
    },

    [currentFarm.id, processLoading],
  )

  const handleDeleteSOCSample = async monitorSOCSampleId => {
    await processLoadingWithConfirmation({
      confirmationTitle: 'Confirmar eliminación',
      confirmationMessage: '¿Estás seguro que deseas eliminar el resultado de laboratorio ?',
      loadingMessage: 'Eliminando resultado...',
      errorMessage: 'Error al eliminar el resultado',
      successfulMessage: 'Resultado eliminado',
      doAction: async ({ token, dryRun }) => {
        if (dryRun) return
        await ruutsApi.socSamples.removeSOCSample({ id: monitorSOCSampleId, token })
        await getFarmData()
      },
    })
  }

  const handleEditSOCSample = async socSampleId => {
    await processLoading({
      loadingMessage: 'Cargando informe de monitoreo de suelo...',
      successfulMessage: '',
      doAction: async ({ token }) => {
        const lastSOCSample = await ruutsApi.socSamples.getSOCSample({ id: socSampleId, token })
        setSelectedSOCSample(lastSOCSample)
        setCreateSOCSampleView(true)
      },
    })
  }

  useEffect(() => {
    getFarmData()
  }, [getFarmData])

  return (
    <>
      {currentFarm && (
        <Box>
          <Box sx={{ padding: '10px', textAlign: 'right' }}>
            <Button
              color="primary"
              component="label"
              variant="contained"
              onClick={() => {
                setCreateSOCSampleView(true)
              }}
            >
              Crear resultado
            </Button>
          </Box>
          {createSOCSampleView && (
            <SOCSampleDialog
              farm={currentFarm}
              handleClose={() => {
                setSelectedSOCSample(null)
                setCreateSOCSampleView(false)
                getFarmData()
              }}
              open={createSOCSampleView}
              socSample={selectedSOCSample}
            />
          )}
          {socSamples && (
            <SOCSamplesList
              farm={currentFarm}
              handleDelete={handleDeleteSOCSample}
              handleEdit={handleEditSOCSample}
              socSamples={socSamples}
            />
          )}
        </Box>
      )}
    </>
  )
}

export default SOCSample
