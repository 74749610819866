import React from 'react'

const AppContext = React.createContext({
  userRoles: {},
  setUserRoles: () => null,
  userRoleSelected: {},
  setUserRoleSelected: () => null,
  user: {},
  setUser: () => null,
  currentFarm: {},
  setCurrentFarm: () => null,
  programConfig: {},
  setProgramConfig: () => null,
  hideNavbar: false,
  setHideNavbar: () => null,
  confirmationModalConfig: {},
  setConfirmationModalConfig: () => null,
  loadingModalConfig: {},
  setLoadingModalConfig: () => null,
  partialChanges: {},
  setPartialChanges: () => null,
})
export const AppContextProvider = AppContext.Provider
export default AppContext
