import IconButton from '@mui/material/IconButton'
import { Close } from '@mui/icons-material'
import { displayMessageFromError } from '../Errors/errors'

export function customSnackbarError(message, error, enqueueSnackbar, closeSnackbar) {
  const { displayMessage, unexpected } = displayMessageFromError(message, error)

  enqueueSnackbar(displayMessage, {
    variant: unexpected ? 'error' : 'warning',
    autoHideDuration: 5000,
    action: key => (
      <IconButton
        aria-label="close"
        color="inherit"
        size="small"
        onClick={() => closeSnackbar(key)}
      >
        <Close fontSize="small" />
      </IconButton>
    ),
  })
}

export function customSnackbarSuccessful(message, enqueueSnackbar, closeSnackbar) {
  enqueueSnackbar(message, {
    variant: 'success',
    autoHideDuration: 5000,
    action: key => (
      <IconButton
        aria-label="close"
        color="inherit"
        size="small"
        onClick={() => closeSnackbar(key)}
      >
        <Close fontSize="small" />
      </IconButton>
    ),
  })
}

export function customSnackbarWarning(message, enqueueSnackbar, closeSnackbar) {
  enqueueSnackbar(message, {
    variant: 'warning',
    autoHideDuration: 5000,
    action: key => (
      <IconButton
        aria-label="close"
        color="inherit"
        size="small"
        onClick={() => closeSnackbar(key)}
      >
        <Close fontSize="small" />
      </IconButton>
    ),
  })
}
